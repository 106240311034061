/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useSelector } from "react-redux";
import Header from "../Header";
import styles from "./Map.module.css";
import MapService from "../../services/map/map";
import { useGetAllOperationForTechnicianQuery } from "../../services/operation/operationForTechnician";
import { useGetAllOrdersForTechnicianQuery } from "../../services/operation/operationForTechnician";
import { jwtDecode } from "jwt-decode";

const MapTechnician = () => {
  const { technicianToken } = useSelector((state) => state.auth);
  const decodedToken = jwtDecode(technicianToken);

  const {
    data: allOperationDataTechnician,
    isLoading: operationTechnicianLoading,
    error: operationTechnicianError,
    refetch,
  } = useGetAllOperationForTechnicianQuery(decodedToken.id, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Map"} />
          <div className="w-full h-full m-2 rounded-2xl">
            <MapService
              allOperationData={allOperationDataTechnician}
              refetch={refetch}
              role="technician"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapTechnician;
