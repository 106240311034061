import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";

import { useGetAdminQuery } from "../../services/admin/adminServices";
import { updateAdmin } from "../../features/admin/adminActions";

const EditAdmin = ({
  editAdminId,
  editAdminVisible,
  setEditAdminVisible,
  toast,
  refetch,
}) => {
  const { data, isLoading, error } = useGetAdminQuery(editAdminId, {
    refetchOnMountOrArgChange: true,
  });

  const { updateLoading } = useSelector((state) => state.admin);

  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (data) {
      reset({
        first_name: data?.data?.first_name || "",
        last_name: data?.data?.last_name || "",
        email: data?.data?.email || "",
        phone: data?.data?.phone || "",
        full_address: data?.data?.full_address || "",
        is_active: data?.data?.is_active,
        username: data?.data?.first_name + " " + data?.data?.last_name,
      });
    }
  }, [data, reset]);

  const onSubmit = (data) => {
    const obj = {
      id: editAdminId,
      ...data,
    };

    dispatch(updateAdmin(obj)).then((res) => {
      if (res?.payload?.status === 200) {
        refetch();
        setEditAdminVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <Dialog
      header="Edit Admin"
      visible={editAdminVisible}
      onHide={() => setEditAdminVisible(false)}
      style={{ width: "50vw" }}
    >
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="my-8">
          <div className="flex gap-2">
            <Controller
              name="first_name"
              control={control}
              rules={{
                required: "First Name is required.",
              }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    First Name
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="last_name"
              control={control}
              rules={{
                required: "Last Name is required.",
              }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Last Name
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </div>
          <div className="flex gap-2">
            <Controller
              name="email"
              control={control}
              rules={{
                required: "Email is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address. E.g. example@email.com",
                },
              }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Email
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="phone"
              control={control}
              rules={{
                required: "Phone is required.",
              }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Phone
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </div>
          <Controller
            name="full_address"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Full Address
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="is_active"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Active
                </label>
                <div className="flex gap-4 my-4 flex-wrap">
                  <div className="flex">
                    <RadioButton
                      inputId="Active"
                      name="isActive"
                      value={"active"}
                      checked={field.value === "active"}
                      onChange={(e) => field.onChange("active")}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} mr-1`}
                      inputRef={field.ref}
                    />
                    <label htmlFor="Active" className="ml-1 mr-3">
                      Yes
                    </label>
                  </div>
                  <div className="flex">
                    <RadioButton
                      inputId="No"
                      name="isActive"
                      value={"inactive"}
                      onChange={(e) => field.onChange("inactive")}
                      checked={field.value === "inactive"}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} mr-1`}
                    />
                    <label htmlFor="No" className="ml-1 mr-3">
                      No
                    </label>
                  </div>
                </div>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <div className="flex items-center justify-center">
            <Button
              type="submit"
              icon={updateLoading ? "pi pi-spin pi-spinner" : "pi pi-check"}
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              label={updateLoading ? "" : "Submit"}
            />
          </div>
        </form>
      )}
      {error && (
        <div className="flex items-center justify-center w-full h-full">
          <p>{error.message}</p>
        </div>
      )}
    </Dialog>
  );
};

export default EditAdmin;
