import { useDispatch } from "react-redux";
import { DataTable } from "primereact/datatable";
import { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { useGetAllProductsQuery } from "../../services/product/productServices";
import { deleteProduct } from "../../features/products/productActions";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import { formatCurrency } from "../../utils/formatCurrency";

const ProductTable = () => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [showAddOrderModal, setShowAddOrderModal] = useState(false);
  const [editProductId, setEditProductId] = useState(null);
  const [editProductVisible, setEditProductVisible] = useState(false);

  const toast = useRef(null);

  const dispatch = useDispatch();

  const { data, isLoading, error, refetch } = useGetAllProductsQuery(
    "productsDetails",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
  };

  const header = () => {
    return (
      <div className="flex items-center justify-between bg-none">
        <div className="flex">
          <span className="mr-2 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm rounded-xl"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
        <div className="flex">
          <div>
            <Button
              icon="pi pi-plus"
              label="Add Product"
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              size="small"
              onClick={() => setShowAddOrderModal(true)}
            />
          </div>
        </div>
      </div>
    );
  };

  const productNameBodyTemplate = (rowData) => {
    return <div className="py-2">{rowData.product_name}</div>;
  };

  // const categoryBodyTemplate = (rowData) => {
  //   return <div className="py-2">{rowData.category}</div>;
  // };

  // const productTypeBodyTemplate = (rowData) => {
  //   return <div className="py-2">{rowData.product_type}</div>;
  // };

  // const sizeBodyTemplate = (rowData) => {
  //   return <div className="py-2">{rowData.size_variation}</div>;
  // };

  const priceBodyTemplate = (rowData) => {
    return <div className="py-2">{formatCurrency(rowData.price)}</div>;
  };

  const onEdit = (id) => {
    setEditProductId(id);
    setEditProductVisible(true);
  };

  const removeProduct = (id) => {
    dispatch(deleteProduct({ product_id: id })).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const confirm2 = (id) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => removeProduct(id),
      reject,
    });
  };

  const actionBodyTemplate = (rowData) => {
    const { id } = rowData;
    return (
      <div className="flex items-center justify-around">
        <Button
          icon="pi pi-pencil"
          rounded
          text
          size="small"
          onClick={() => onEdit(id)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          size="small"
          onClick={() => confirm2(id)}
        />
      </div>
    );
  };

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p>{error?.data?.message}</p>
      </div>
    );
  }

  return (
    <div>
      {editProductId && (
        <EditProduct
          editProductId={editProductId}
          editProductVisible={editProductVisible}
          setEditProductVisible={setEditProductVisible}
          refetch={refetch}
          toast={toast}
        />
      )}
      <ConfirmDialog />
      <Toast ref={toast} />
      <AddProduct
        showAddOrderModal={showAddOrderModal}
        setShowAddOrderModal={setShowAddOrderModal}
        refetch={refetch}
        toast={toast}
      />
      <DataTable
        value={data?.data}
        loading={isLoading}
        scrollHeight="68vh"
        scrollable
        dataKey="id"
        header={header}
        globalFilter={globalFilterValue}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column
          header="Product Name"
          field="product_name"
          body={productNameBodyTemplate}
          style={{ minWidth: "20rem" }}
        />
        {/* <Column
          header="Category"
          field="category"
          body={categoryBodyTemplate}
          style={{ minWidth: "20rem" }}
        />
        <Column
          header="Product Type"
          field="product_type"
          body={productTypeBodyTemplate}
          style={{ minWidth: "20rem" }}
        />
        <Column
          header="Size / Variation"
          field="size_variation"
          body={sizeBodyTemplate}
          style={{ minWidth: "20rem" }}
        /> */}
        <Column
          header="Price"
          field="price"
          body={priceBodyTemplate}
          style={{ minWidth: "20rem" }}
        />
        <Column
          body={actionBodyTemplate}
          header="Actions"
          style={{ minWidth: "4rem" }}
        ></Column>
      </DataTable>
    </div>
  );
};

export default ProductTable;
