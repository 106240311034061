import { useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetOrderQuery } from "../../services/order/orderServices";

import OrderDetails from "./OrderDetailsData";
import OrderEdit from "./OrderEdit";
import AssignTechnician from "../Driver/AssignTechnician";
import { Toast } from "primereact/toast";

const OrderDetailsDialog = ({ visible, setVisible, id, setOrderId }) => {
  const [page, setPage] = useState("orderDetails");
  const [assignTechnicianVisible, setAssignTechnicianVisible] = useState(false);
  const [assignTechnicianId, setAssignTechnicianId] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const { data, isLoading, error, refetch } = useGetOrderQuery(id, {
    refetchOnMountOrArgChanged: true,
  });
  const navigate = useNavigate();
  const toast = useRef(null);

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p>{error?.error}</p>
      </div>
    );
  }

  const onShow = (id) => {
    setAssignTechnicianVisible(true);
    setAssignTechnicianId(id);
  };

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        setVisible(false);
        setOrderId(null);
      }}
      style={{ width: "80vw" }}
      header="Order Details"
    >
      <Toast ref={toast} />
      {assignTechnicianId && (
        <AssignTechnician
          assignTechnicianId={assignTechnicianId}
          setAssignTechnicianId={setAssignTechnicianId}
          setAssignTechnicianVisible={setAssignTechnicianVisible}
          toast={toast}
          refetch={refetch}
          assignTechnicianVisible={assignTechnicianVisible}
        />
      )}
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <>
          <div className="flex flex-row items-center justify-between">
            <div className="text-lg font-bold">
              {data?.data?.customer?.full_name} - {data?.data?.order_date}
            </div>
            <div>
              <div className="flex items-center justify-center">
                <Button
                  icon="pi pi-list"
                  size="small"
                  text
                  rounded
                  onClick={() => setPage("orderDetails")}
                />
                <Button
                  icon="pi pi-pencil"
                  size="small"
                  text
                  rounded
                  onClick={() => {
                    setPage("orderEdit");
                  }}
                />
                {token?<><Button
                  label="Create Receipt"
                  icon="pi pi-print"
                  size="small"
                  text
                  rounded
                  onClick={() => {
                    navigate(`/orders/${data?.data?.id}`);
                  }}
                />
                <Button
                  icon="pi pi-user-edit"
                  size="small"
                  text
                  rounded
                  label="Assign Technician"
                  onClick={() => onShow(id)}
                /></> : <></>}
                
              </div>
            </div>
          </div>
          <div>
            {page === "orderDetails" && <OrderDetails data={data?.data} />}
            {page === "orderEdit" && (
              <OrderEdit
                data={data?.data}
                refetch={refetch}
                toast={toast}
                setState={setPage}
              />
            )}
          </div>
        </>
      )}
    </Dialog>
  );
};

export default OrderDetailsDialog;
