"use client";
import { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addCustomer } from "../../features/customer/customerActions";
import { setAddressData } from "../../features/customer/customerSlice";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import MapAddCustomer from "./../Map/mapAddCustomer";
import { Dropdown } from "primereact/dropdown";

const AddCustomerDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useRef(null);

  const { addLoading, addressData } = useSelector((state) => state.customer);
  const defaultValues = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    postalCode: "",
    state: "",
    street: "",
    town: "",
    geocode: "",
    location: "",
  };

  const locationOptions = [
    { name: "Brooklyn / Queens", code: "Brooklyn / Queens" },
    { name: "Nassau or Suffolk County", code: "Nassau or Suffolk County" },
    { name: "Other", code: "Other" },
    { name: "unspecified", code: "unspecified" },
  ];

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const prevAddressDataRef = useRef();

  useEffect(() => {
    console.log(addressData);
    if (addressData && prevAddressDataRef.current !== addressData) {
      setValue("street", `${addressData?.street}`);
      setValue("town", `${addressData?.town}`);
      setValue("postalCode", `${addressData?.postalCode}`);
      setValue("state", `${addressData?.state}`);
      setValue("geocode", `${addressData?.geocode}`);
      prevAddressDataRef.current = addressData;
    }
  }, [addressData, setValue]);

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onSubmit = (data) => {
    const obj = {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone,
      postal_code: data.postalCode,
      state: data.state,
      street: data.street,
      town: data.town,
      geo_code: data.geocode,
      location: data.location,
    };

    if (data.secondaryFirstName && data.secondaryFirstName !== "") {
      obj.sec_first_name = data.secondaryFirstName;
    }

    if (data.secondaryLastName && data.secondaryLastName !== "") {
      obj.sec_last_name = data.secondaryLastName;
    }

    if (data.secondaryEmail && data.secondaryEmail !== "") {
      obj.sec_email = data.secondaryEmail;
    }

    if (data.secondaryPhone && data.secondaryPhone !== "") {
      obj.sec_phone = data.secondaryPhone;
    }

    dispatch(addCustomer(obj)).then((res) => {
      console.log(res);
      if (res?.payload?.data?.status === "Success") {
        reset();
        navigate("/customer");
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
    dispatch(setAddressData({}));
  };

  return (
    <div className="m-5 p-5 bg-[#ffeded] rounded-xl">
      <Toast ref={toast} />
      <form onSubmit={handleSubmit(onSubmit)} className="my-8">
        <div className="flex items-center justify-between w-full gap-2">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "First Name is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  First Name
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="First Name"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: "Last Name is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Last Name
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Last Name"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
        </div>
        <div className="flex items-center justify-between w-full gap-2">
          <Controller
            name="email"
            control={control}
            rules={{ required: "Email is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Email
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Email"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="phone"
            control={control}
            rules={{ required: "Phone is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Phone
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Phone"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
        </div>
        <Controller
          name="location"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col w-1/2">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Location
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  onChange={(e) => field.onChange(e.value)}
                  options={locationOptions}
                  optionLabel="name"
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <MapAddCustomer />
        <div className="flex items-center justify-center mt-5">
          <Button
            label={addLoading ? "" : "Submit"}
            className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
            type="submit"
            icon={addLoading ? "pi pi-spin pi-spinner" : "pi pi-plus"}
          />
        </div>
      </form>
    </div>
  );
};

export default AddCustomerDialog;
