import { createSlice } from "@reduxjs/toolkit";
import { addAdmin, updateAdmin } from "./adminActions";

const initialState = {
  addLoading: false,
  updateLoading: false,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addAdmin.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addAdmin.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addAdmin.rejected, (state) => {
        state.addLoading = false;
      })
      .addCase(updateAdmin.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateAdmin.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateAdmin.rejected, (state) => {
        state.updateLoading = false;
      });
  },
});

export default adminSlice.reducer;
