// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../../utils/apiURL";

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,

    keepUnusedDataFor: 0,
    refetchOnMountOrArgChange: true,

    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllAdmin: builder.query({ query: () => `admins` }),
    getAdmin: builder.query({ query: (id) => `admins/${id}` }),
  }),
});

export const { useGetAllAdminQuery, useGetAdminQuery } = adminApi;
