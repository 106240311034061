import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";

import AddOrderLineDialogReq from "../OrderLines/AddOrderLineDialogReq";
import { useGetOrderQuery } from "../../services/order/orderServices";
import { updateOrder } from "../../features/order/orderActions";
import {
  addDelivery,
  updateDelivery,
} from "../../features/delivery/deliveryActions";

const EditOrder = ({
  editOrderId,
  editOrderVisible,
  setEditOrderVisible,
  refetch,
  toast,
}) => {
  const [showOrderDialog, setShowOrderDialog] = useState(false);

  const dispatch = useDispatch();

  const {
    data: orderData,
    isLoading,
    error,
    refetch: refetchOrder,
  } = useGetOrderQuery(editOrderId, {
    refetchOnMountOrArgChange: true,
  });

  const { updateLoading } = useSelector((state) => state.orders);
  const { updateLoading: updateDeliveryLoading } = useSelector(
    (state) => state.delivery
  );

  const typeOfOrderOptions = [
    { name: "Walk In", code: "Walk In" },
    { name: "Phone In", code: "Phone In" },
    { name: "Website", code: "Website" },
    { name: "Home Show", code: "Home Show" },
  ];

  const deliveryStatusOptions = [
    {
      name: "Need to Deliver (Tropicals)",
      code: "Need to Deliver (Tropicals)",
    },
    { name: "Need to Deliver", code: "Need to Deliver" },
    { name: "Took Trees", code: "Took Trees" },
    { name: "Picking Up", code: "Picking Up" },
  ];

  const modeOfPaymentOptions = [
    { name: "Cash", code: "Cash" },
    { name: "Credit Card", code: "Credit Card" },
    { name: "Check/Zelle", code: "Check" },
    {
      name: "Cash Deposit / Credit Card Balance",
      code: "Cash Deposit / Credit Card Balance",
    },
    {
      name: "Credit Card Deposit / Cash Balance",
      code: "Credit Card Deposit / Cash Balance",
    },
  ];

  const paymentStatusOptions = [
    { name: "COD", code: "COD" },
    { name: "Paid in Full", code: "Paid in Full" },
    { name: "Deposit", code: "Deposit" },
    { name: "Send Invoice", code: "Send Invoice" },
    { name: "Cancel Order", code: "Cancel Order" },
    { name: "Send Check Invoice", code: "Send Check Invoice" },
    { name: "Owes Money", code: "Owes Money" },
  ];

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();

  const rental = watch("purchase");
  const modeOfPayment = watch("modeOfPayment");

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    if (orderData) {
      reset({
        purchase:
          {
            name: orderData?.data?.order_type,
            code: orderData?.data?.order_type,
          } || null,
        orderDate: orderData?.data?.order_date
          ? orderData?.data?.order_date
          : "",
        rentalStartDate: orderData?.data?.rental_start_date
          ? orderData?.data?.rental_start_date
          : "",
        rentalEndDate: orderData?.data?.rental_end_date
          ? orderData?.data?.rental_end_date
          : "",
        typeOfOrder:
          {
            name: orderData?.data?.customer_type,
            code: orderData?.data?.customer_type,
          } || null,
        deliveryStatus:
          {
            name: orderData?.data?.order_delivery?.delivery_status,
            code: orderData?.data?.order_delivery?.delivery_status,
          } || null,
        estimatedDeliveryDate: orderData?.data?.order_delivery
          ?.estimated_delivery_date
          ? new Date(orderData?.data?.order_delivery?.estimated_delivery_date)
          : "",
        deliveryNote: orderData?.data?.order_delivery?.delivery_notes,
        modeOfPayment:
          {
            name: orderData?.data?.mode_of_payment,
            code: orderData?.data?.mode_of_payment,
          } || null,
        paymentStatus:
          {
            name: orderData?.data?.status,
            orderData,
          } || null,
        deposit: orderData?.data?.deposit || 0.0,
        discount: orderData?.data?.discount || 0,
        customerNote: orderData?.data?.follow_up_special_notes || "",
        orderNote: orderData?.data?.order_notes || "",
      });
    }
  }, [orderData, reset]);

  function formatDateToAmerican(date) {
    // Get the month, day, and year from the date object
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const day = date.getDate();
    const year = date.getFullYear();

    // Pad the month and day with leading zeros if needed
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    // Format the date in MM/DD/YYYY format
    return `${formattedMonth}/${formattedDay}/${year}`;
  }

  const onSubmit = (data) => {
    const {
      purchase,
      typeOfOrder,
      modeOfPayment,
      deposit,
      discount,
      customerNote,
      orderNote,
      estimatedDeliveryDate,
      deliveryStatus,
      deliveryNote,
    } = data;

    const formattedEstimatedDeliveryDate =
      estimatedDeliveryDate !== ""
        ? formatDateToAmerican(new Date(estimatedDeliveryDate))
        : "";

    const obj = {
      id: editOrderId,
      customer_type: typeOfOrder.name,
      mode_of_payment: modeOfPayment.code,
      order_notes: orderNote,
      follow_up_special_notes: customerNote,
      order_type: purchase.name,
    };

    if (
      modeOfPayment.name === "Cash Deposit / Credit Card Balance" ||
      modeOfPayment.name === "Credit Card Deposit / Cash Balance"
    ) {
      obj.deposit = deposit;
    }

    if (deposit === "") {
      obj.deposit = 0;
    }

    if (discount === "") {
      obj.discount = 0;
    }

    const deliveryObj = {
      estimated_delivery_date: formattedEstimatedDeliveryDate,
      delivery_status: deliveryStatus.name || "",
      delivery_notes: deliveryNote || "",
    };

    dispatch(updateOrder(obj)).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        if (orderData?.data?.order_delivery_id) {
          dispatch(
            updateDelivery({
              ...deliveryObj,
              order_id: orderData?.data?.id,
              id: orderData?.data?.order_delivery_id,
            })
          ).then((res) => {
            if (res?.payload?.data?.status === "Success") {
              setEditOrderVisible(false);
              refetch();
              toast.current.show({
                severity: "success",
                summary: "Form Submitted",
                detail: res.payload.data.message,
                life: 3000,
              });
            }
          });
        } else {
          dispatch(
            addDelivery({ ...deliveryObj, order_id: orderData?.data?.id })
          ).then((res) => {
            if (res?.payload?.data?.status === "Success") {
              reset();
              refetch();
              setEditOrderVisible(false);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: res.payload,
                life: 3000,
              });
            }
          });
        }
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <Dialog
      header="Edit Order"
      visible={editOrderVisible}
      onHide={() => setEditOrderVisible(false)}
      style={{ width: "50vw" }}
    >
      <AddOrderLineDialogReq
        refetch={refetchOrder}
        setShowOrderDialog={setShowOrderDialog}
        editOrderId={editOrderId}
        showOrderDialog={showOrderDialog}
        toast={toast}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="my-8">
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
        ) : (
          <>
            <Controller
              name="purchase"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Purchase / Rental / Service
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value?.name}
                      readOnly
                      // optionLabel="name"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            {(rental?.name === "Palm Trees Rental" ||
              rental?.name === "Tiki Bar Rental") && (
              <>
                <Controller
                  name="rentalStartDate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <div className="flex flex-col">
                      <label
                        htmlFor={field.name}
                        className={`${classNames({
                          "p-error": errors.value,
                        })} text-base text-bold`}
                      >
                        Rental Start Date
                      </label>
                      <span>
                        <Calendar
                          id={field.name}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          dateFormat="mm/dd/yy"
                          className={`${classNames({
                            "p-invalid": fieldState.error,
                          })} w-full rounded-xl`}
                          inputClassName="rounded-xl"
                        />
                      </span>
                      {getFormErrorMessage(field.name)}
                    </div>
                  )}
                />
                <Controller
                  name="rentalEndDate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <div className="flex flex-col">
                      <label
                        htmlFor={field.name}
                        className={`${classNames({
                          "p-error": errors.value,
                        })} text-base text-bold`}
                      >
                        Rental End Date
                      </label>
                      <span>
                        <Calendar
                          id={field.name}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          dateFormat="mm/dd/yy"
                          className={`${classNames({
                            "p-invalid": fieldState.error,
                          })} w-full rounded-xl`}
                          inputClassName="rounded-xl"
                        />
                      </span>
                      {getFormErrorMessage(field.name)}
                    </div>
                  )}
                />
              </>
            )}
            <Controller
              name="orderDate"
              control={control}
              // rules={{
              //   required: "Order Date is required.",
              // }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Order Date
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      // onChange={(e) => field.onChange(e.value)}
                      readOnly={true}
                      // dateFormat="mm/dd/yy"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      // inputClassName="rounded-xl"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="typeOfOrder"
              control={control}
              rules={{
                required: "Type of Order is required",
              }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Type of Order
                  </label>
                  <span>
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={typeOfOrderOptions}
                      optionLabel="name"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <div className="flex flex-col">
              <p className="text-base text-bold">Products Ordered:</p>
              <Button
                label="Order Line"
                // onClick={(e) => onShowAddOrderLine(e)}
                onClick={(e) => e.preventDefault()}
                className="rounded-xl"
              />
            </div>
            <div className="flex flex-col">
              {orderData?.data?.order_line_items.map((item) => {
                return (
                  <div
                    className="flex items-center justify-between rounded-xl bg-[#FFFFFF] p-2 my-2 border-2 border-green-900"
                    key={item.id}
                  >
                    <p>
                      {item.line_item_quantity} X{" "}
                      {item.item_type === "fees"
                        ? item.item_type
                        : item.product_name}
                    </p>
                    <div></div>
                  </div>
                );
              })}
            </div>
            <Controller
              name="modeOfPayment"
              rules={{
                required: "Mode Of Payment is required.",
              }}
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Mode of Payment
                  </label>
                  <span>
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={modeOfPaymentOptions}
                      optionLabel="name"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="paymentStatus"
              control={control}
              // rules={{
              //   required: "Status is required.",
              // }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Payment status
                  </label>
                  <span>
                    <InputText
                      id={field.name}
                      value={field.value?.name}
                      // onChange={(e) => field.onChange(e.value)}
                      readOnly={true}
                      options={paymentStatusOptions}
                      // optionLabel="name"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            {(modeOfPayment?.name === "Cash Deposit / Credit Card Balance" ||
              modeOfPayment?.name === "Credit Card Deposit / Cash Balance") && (
              <Controller
                name="deposit"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="flex flex-col">
                    <label
                      htmlFor={field.name}
                      className={`${classNames({
                        "p-error": errors.value,
                      })} text-base text-bold`}
                    >
                      Deposit
                    </label>
                    <span>
                      <InputNumber
                        id={field.name}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        readOnly={orderData?.data?.deposit > 0 ? true : false}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} w-full rounded-xl`}
                        inputId="currency-us"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                      />
                    </span>
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
            )}
            <Controller
              name="discount"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Discount
                  </label>
                  <span>
                    <InputNumber
                      readOnly
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="customerNote"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Notes Customer Sees
                  </label>
                  <span>
                    <InputTextarea
                      id={field.name}
                      {...field}
                      rows={4}
                      cols={30}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      autoResize
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="orderNote"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Order Followup Notes
                  </label>
                  <span>
                    <InputTextarea
                      id={field.name}
                      {...field}
                      rows={4}
                      cols={30}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      autoResize
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="deliveryStatus"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Delivery status
                  </label>
                  <span>
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={deliveryStatusOptions}
                      optionLabel="name"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="estimatedDeliveryDate"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Estimated Delivery / Pickup Date
                  </label>
                  <span>
                    <Calendar
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      dateFormat="mm/dd/yy"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      inputClassName="rounded-xl"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="deliveryNote"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Delivery Notes
                  </label>
                  <span>
                    <InputTextarea
                      id={field.name}
                      {...field}
                      rows={4}
                      cols={30}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      autoResize
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <div className="flex items-center justify-center">
              <Button
                label={updateLoading || updateDeliveryLoading ? "" : "Submit"}
                className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
                type="submit"
                icon={
                  updateLoading || updateDeliveryLoading
                    ? "pi pi-spin pi-spinner"
                    : "pi pi-pencil"
                }
              />
            </div>
          </>
        )}
        {error && (
          <div className="flex items-center justify-center w-full h-full">
            <p>{error.message}</p>
          </div>
        )}
      </form>
    </Dialog>
  );
};

export default EditOrder;
