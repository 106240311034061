import { useState } from "react";
import { Tag } from "primereact/tag";
import { Image } from "primereact/image";
import { Button } from "primereact/button";

import styles from "./CustomerTable.module.css";

import VideoPlayerDialog from "../ClientTreatment/VideoPlayerDialog";

const CustomerDetails = ({ data }) => {
  const [videoVisible, setVideoVisible] = useState(false);
  const [file, setFile] = useState("");
  return (
    <div className="flex flex-col w-4/5 mx-auto">
      <VideoPlayerDialog
        file={file}
        videoVisible={videoVisible}
        setVideoVisible={setVideoVisible}
      />
      <div className="flex flex-row">
        <div className="w-1/2">
          <h4 className={styles.h4}>Full Name</h4>
          <p>{data?.full_name}</p>
        </div>
        <div className="w-1/2">
          <h4 className={styles.h4}>Full Address</h4>
          <p>{data?.full_address}</p>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="w-1/2 my-4">
          <h4 className={styles.h4}>Phone</h4>
          <p>{data?.phone}</p>
        </div>
        <div className="w-1/2 my-4">
          <h4 className={styles.h4}>Email</h4>
          <p>{data?.email}</p>
        </div>
      </div>
      <div className="flex flex-row">
        {data?.kit_location && (
          <div className="w-1/2 my-4">
            <h4 className={styles.h4}>Kit Location Text</h4>
            <p>{data?.kit_location}</p>
          </div>
        )}
        {data?.kit_location_imgs?.length > 0 && (
          <div className="w-1/2 my-4">
            <h4 className={styles.h4}>Kit Location Images</h4>
            <div className="flex flex-row flex-wrap gap-2">
              {data?.kit_location_imgs?.map((file) => {
                const extension = file.split(".").pop().toLowerCase();
                if (
                  extension === "png" ||
                  extension === "jpg" ||
                  extension === "jpeg" ||
                  extension === "gif"
                ) {
                  return (
                    <div className="relative" key={file}>
                      <Image
                        key={file}
                        src={file}
                        zoomSrc={file}
                        alt="Image"
                        width="80"
                        height="60"
                        preview
                        imageClassName="w-20 h-20"
                      />
                    </div>
                  );
                } else if (extension === "mp4" || extension === "mov") {
                  return (
                    <div className="relative" key={file}>
                      <Button
                        icon="pi pi-play"
                        className="w-20 h-20"
                        onClick={(e) => {
                          e.preventDefault();
                          setVideoVisible(true);
                          setFile(file);
                        }}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row">
        {data?.pole_location && (
          <div className="w-1/2 my-4">
            <h4 className={styles.h4}>Pole Location Text</h4>
            <p>{data?.pole_location}</p>
          </div>
        )}
        {data?.pole_location_imgs?.length > 0 && (
          <div className="w-1/2 my-4">
            <h4 className={styles.h4}>Pole Location Images</h4>
            <div className="flex flex-row flex-wrap gap-2">
              {data?.pole_location_imgs?.map((file) => {
                const extension = file.split(".").pop().toLowerCase(); // Get the file extension
                if (
                  extension === "png" ||
                  extension === "jpg" ||
                  extension === "jpeg" ||
                  extension === "gif"
                ) {
                  return (
                    <div className="relative" key={file}>
                      <Image
                        key={file}
                        src={file}
                        zoomSrc={file}
                        alt="Image"
                        width="80"
                        height="60"
                        preview
                        imageClassName="w-20 h-20"
                      />
                    </div>
                  );
                } else if (extension === "mp4" || extension === "mov") {
                  return (
                    <div className="relative" key={file}>
                      <Button
                        icon="pi pi-play"
                        className="w-20 h-20"
                        onClick={(e) => {
                          e.preventDefault();
                          setVideoVisible(true);
                          setFile(file);
                        }}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row">
        <div className="w-1/2 my-4">
          <h4 className={styles.h4}>Location</h4>
          <p>{data?.location}</p>
        </div>
        {data?.prev_treatements !== null && (
          <div className="w-1/2 my-4">
            <h4 className={styles.h4}>Made Prev Treatment</h4>
            <Tag
              rounded
              severity={data?.prev_treatements ? "success" : "danger"}
              value={data?.prev_treatements?.toString()}
            ></Tag>
          </div>
        )}
      </div>
      <div className="flex flex-row">
        {data?.do_not_contact_again !== null && (
          <div className="w-1/2 my-4">
            <h4 className={styles.h4}>Do Not Contact Again</h4>
            <Tag
              rounded
              severity={data?.do_not_contact_again ? "success" : "danger"}
              value={data?.do_not_contact_again?.toString()}
            ></Tag>
          </div>
        )}
        {data?.ordered_windmill !== null && (
          <div className="w-1/2 my-4">
            <h4 className={styles.h4}>Have Windmill</h4>
            <Tag
              rounded
              severity={data?.ordered_windmill ? "success" : "danger"}
              value={data?.ordered_windmill?.toString()}
            ></Tag>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerDetails;
