import Header from "../Header";
import styles from "./CustomerTable.module.css";
import CustomerTableContent from "./CustomerTableContent";

const CustomerTable = () => {
  return (
    <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Customers"} />
          <div className={`w-full h-full rounded-2xl m-2 px-2`}>
            <CustomerTableContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerTable;
