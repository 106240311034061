import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";

import { addAdmin } from "../../features/admin/adminActions";
import { getFormErrorMessage } from "./../../utils/getFormErrorMessage";

const AddAdmin = ({ visible, setVisible, refetch, toast }) => {
  const dispatch = useDispatch();

  const defaultValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone: "",
    full_address: "",
    role: "admin",
    is_active: "active",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    const obj = {
      ...data,
      username: data.first_name + " " + data.last_name,
    };

    dispatch(addAdmin(obj)).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        reset();
        refetch();
        setVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <Dialog
      header="Add Admin"
      visible={visible}
      onHide={() => setVisible(false)}
      style={{ width: "50vw" }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="my-8">
        <div className="flex gap-2">
          <Controller
            name="first_name"
            control={control}
            rules={{
              required: "First Name is required.",
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  First Name
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <Controller
            name="last_name"
            control={control}
            rules={{
              required: "Last Name is required.",
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Last Name
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
        </div>
        <div className="flex gap-2">
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required.",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address. E.g. example@email.com",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Email
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{
              required: "Password is required.",
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Password
                </label>
                <span>
                  <Password
                    toggleMask
                    feedback={false}
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    placeholder="Password"
                    inputClassName="w-full rounded-xl"
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
        </div>
        <div className="flex gap-2">
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Phone is required.",
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Phone
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <Controller
            name="full_address"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Full Address
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
        </div>
        <Controller
          name="is_active"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Active
              </label>
              <div className="flex gap-4 my-4 flex-wrap">
                <div className="flex">
                  <RadioButton
                    inputId="Active"
                    name="isActive"
                    value="active"
                    checked={field.value === "active"}
                    onChange={() => field.onChange("active")}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} mr-1`}
                    inputRef={field.ref}
                  />
                  <label htmlFor="Active" className="ml-1 mr-3">
                    Yes
                  </label>
                </div>
                <div className="flex">
                  <RadioButton
                    inputId="inActive"
                    name="isActive"
                    value="inactive"
                    onChange={() => field.onChange("inactive")}
                    checked={field.value === "inactive"}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} mr-1`}
                  />
                  <label htmlFor="inActive" className="ml-1 mr-3">
                    No
                  </label>
                </div>
              </div>
              {/* <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </span> */}
              {getFormErrorMessage(field.name, errors)}
            </div>
          )}
        />
        <div className="flex items-center justify-center">
          <Button
            type="submit"
            icon="pi pi-plus"
            className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
            label="Submit"
          />
        </div>
      </form>
    </Dialog>
  );
};

export default AddAdmin;
