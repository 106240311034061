const OrderPdfTemplate = ({ data, id }) => {
  return (
    <>
      {data?.payment_status === "Paid in Full" ? (
        <>
          <iframe
            src={data?.receipt_pdf_url}
            width="100%"
            height="100%"
            title="receipt pdf"
          />
        </>
      ) : (
        <div className="flex items-center justify-center h-full">
          <p className="text-lg bold">This order not paid yet.</p>
        </div>
      )}
    </>
  );
};

export default OrderPdfTemplate;
