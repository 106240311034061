import { useEffect } from "react";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { updateCustomer } from "../../features/customer/customerActions";
import { useGetCustomerQuery } from "../../services/customer/customerServices";
import { getFormErrorMessage } from "../../utils/getFormErrorMessage";

const CustomerEdit = ({
  editCustomerId,
  setEditCustomerVisible,
  editCustomerVisible,
  refetch,
  toast,
}) => {
  const dispatch = useDispatch();
  const { updateLoading } = useSelector((state) => state.customer);

  const { data, isLoading, error } = useGetCustomerQuery(editCustomerId, {
    refetchOnMountOrArgChange: true,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (data?.data) {
      reset({
        firstName: data?.data?.first_name || "",
        lastName: data?.data?.last_name || "",
        email: data?.data?.email || "",
        phone: data?.data?.phone || "",
        street: data?.data?.street || "",
        state: data?.data?.state || "",
        town: data?.data?.town || "",
        postalCode: data?.data?.postal_code || "",
        secondaryFirstName: data?.data?.sec_first_name || "",
        secondaryLastName: data?.data?.sec_last_name || "",
        secondaryEmail: data?.data?.sec_email || "",
        secondaryPhone: data?.data?.sec_phone || "",
        haveWindmill: data?.data?.ordered_windmill?.toString(),
        contactAgain: data?.data?.do_not_contact_again?.toString(),
        prevTreatment: data?.data?.prev_treatements?.toString(),
      });
    }
  }, [reset, data?.data]);

  const onEdit = (data) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      street,
      state,
      town,
      postalCode,
      haveWindmill,
      contactAgain,
      prevTreatment,
    } = data;

    const obj = {
      id: editCustomerId,
      email,
      first_name: firstName,
      last_name: lastName,
      phone,
      postal_code: postalCode,
      state,
      street,
      town,
      ordered_windmill: haveWindmill === "true",
      do_not_contact_again: contactAgain === "true",
      prev_treatements: prevTreatment === "true",
    };

    if (data.secondaryFirstName && data.secondaryFirstName !== "") {
      obj.sec_first_name = data.secondaryFirstName;
    }
    if (data.secondaryLastName && data.secondaryLastName !== "") {
      obj.sec_last_name = data.secondaryLastName;
    }

    if (data.secondaryEmail && data.secondaryEmail !== "") {
      obj.sec_email = data.secondaryEmail;
    }

    if (data.secondaryPhone && data.secondaryPhone !== "") {
      obj.sec_phone = data.secondaryPhone;
    }

    dispatch(updateCustomer(obj)).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        reset();
        refetch();
        setEditCustomerVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <Dialog
      header="Edit Customer"
      visible={editCustomerVisible}
      style={{ width: "45vw" }}
      onHide={() => setEditCustomerVisible(false)}
    >
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onEdit)} className="my-8">
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="firstName"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    First Name
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name || ""}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="First Name"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Last Name
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Last Name"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Email
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Email"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="phone"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Phone
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Phone"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="street"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Street
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Street"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="state"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    State
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="State"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="town"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Town
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Town"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="postalCode"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Postal Code
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Postal Code"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="secondaryFirstName"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Other First Name
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Other First Name"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="secondaryLastName"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Other Second Name
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Other Last Name"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="secondaryEmail"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Other Email
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Other Email"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="secondaryPhone"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Other Phone
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Other Phone"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="contactAgain"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Do Not Contact Again
                  </label>
                  <div className="flex flex-wrap gap-4 my-4">
                    <div className="flex">
                      <RadioButton
                        inputId="Yes"
                        name="category"
                        value={"true"}
                        checked={field.value === "true"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        inputRef={field.ref}
                      />
                      <label htmlFor="Yes" className="ml-1 mr-3">
                        Yes
                      </label>
                    </div>
                    <div className="flex">
                      <RadioButton
                        inputId="No"
                        name="category"
                        value={"false"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        checked={field.value === "false"}
                      />
                      <label htmlFor="No" className="ml-1 mr-3">
                        No
                      </label>
                    </div>
                  </div>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
            <Controller
              name="haveWindmill"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Have Windmill
                  </label>
                  <div className="flex flex-wrap gap-4 my-4">
                    <div className="flex">
                      <RadioButton
                        inputId="Yes"
                        name="category"
                        value={"true"}
                        checked={field.value === "true"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        inputRef={field.ref}
                      />
                      <label htmlFor="Yes" className="ml-1 mr-3">
                        Yes
                      </label>
                    </div>
                    <div className="flex">
                      <RadioButton
                        inputId="No"
                        name="category"
                        value={"false"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        checked={field.value === "false"}
                      />
                      <label htmlFor="No" className="ml-1 mr-3">
                        No
                      </label>
                    </div>
                  </div>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="prevTreatment"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Made Prev Treatment
                  </label>
                  <div className="flex flex-wrap gap-4 my-4">
                    <div className="flex">
                      <RadioButton
                        inputId="Yes"
                        name="category"
                        value={"true"}
                        checked={field.value === "true"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        inputRef={field.ref}
                      />
                      <label htmlFor="Yes" className="ml-1 mr-3">
                        Yes
                      </label>
                    </div>
                    <div className="flex">
                      <RadioButton
                        inputId="No"
                        name="category"
                        value={"false"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        checked={field.value === "false"}
                      />
                      <label htmlFor="No" className="ml-1 mr-3">
                        No
                      </label>
                    </div>
                  </div>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-center">
            <Button
              label={updateLoading ? "" : "Submit"}
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              type="submit"
              icon={updateLoading ? "pi pi-spin pi-spinner" : "pi pi-check"}
            />
          </div>
        </form>
      )}
      {error && (
        <div className="flex items-center justify-center w-full h-full">
          <p>{error.message}</p>
        </div>
      )}
    </Dialog>
  );
};

export default CustomerEdit;
