import { createSlice } from "@reduxjs/toolkit";
import { loginAdmin, loginTechnician, uploadFile } from "./authActions";

const initialState = {
  loading: false,
  userInfo: null, // for user object
  token: null, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
  technicianLoading: false,
  technicianUserInfo: null, // for user object
  technicianToken: null, // for storing the JWT
  technicianError: null,
  technicianSuccess: false, // for monitoring the registration process.
  uploadFileLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.loading = false;
      state.userInfo = null;
      state.token = null;
      state.error = null;
      state.technicianLoading = false;
      state.technicianUserInfo = null;
      state.technicianToken = null;
      state.technicianError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginAdmin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.token = payload.data.data.token;
        state.userInfo = payload;
      })
      .addCase(loginAdmin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(loginTechnician.pending, (state) => {
        state.technicianLoading = true;
        state.technicianError = null;
      })
      .addCase(loginTechnician.fulfilled, (state, { payload }) => {
        state.technicianLoading = false;
        state.technicianToken = payload.data.data.token;
        state.technicianUserInfo = payload;
      })
      .addCase(loginTechnician.rejected, (state, { payload }) => {
        state.technicianLoading = false;
        state.technicianError = payload;
      })
      .addCase(uploadFile.pending, (state) => {
        state.uploadFileLoading = true;
      })
      .addCase(uploadFile.fulfilled, (state) => {
        state.uploadFileLoading = false;
      })
      .addCase(uploadFile.rejected, (state) => {
        state.uploadFileLoading = false;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
