"use client";
import { useState, useEffect } from "react";
import MapOrderTechnician from "../../services/map/mapOrdersTechnician";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { useGetAllOrdersForTechnicianQuery } from "../../services/operation/operationForTechnician";
import Header from "./../Header";
import styles from "./Map.module.css";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
const OrderMapTechnician = () => {
  const { technicianToken } = useSelector((state) => state.auth);
  const decodedToken = jwtDecode(technicianToken);
  console.log(decodedToken.id)
//   const [filteredData, setFilteredData] = useState([]);
  const { data, isLoading, error, refetch } = useGetAllOrdersForTechnicianQuery(decodedToken.id, {
    refetchOnMountOrArgChange: true,
  });
  console.log(data);

  useEffect(() => {
    // const filterData = () => {
    //   let orders = [];
    //   data?.data.map((order) => {
    //     if (
    //       order.order_delivery?.delivery_status === "Need to Deliver" ||
    //       order.order_delivery?.delivery_status === "Need to Deliver (Tropicals)" ||
    //       order.order_delivery?.delivery_status === "Delivery Scheduled"
    //     ) {
    //       orders.push(order);
    //     }

    //     return orders;
    //   });
    //   setFilteredData(orders);
    // };
    // filterData();
  }, [data, isLoading, error,refetch]);
  return (
    <>
      <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
        <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
          <div className="flex flex-col justify-between h-full">
            <Header title={"Orders"} />
            <div className="flex w-full h-full m-2 rounded-2xl">
              <div className="w-1/2 m-3 lg:w-full lg:m-10">
                <MapOrderTechnician orderData={{
                    //  data: filteredData ,
                     allOrderData : data ,
                      refetchdata : refetch
                      }} />
              </div>
              {/* <div className="w-1/2">
                <DataTable
                  // value={data?.data}
                  value={filteredData}
                  dataKey="id"
                  scrollable
                  scrollHeight="80vh"
                  loading={isLoading}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[5, 10, 25, 50]}
                >
                  {/* <Column style={{ minWidth: "5rem" }} header="ID" field="id" /> */}
                  {/* <Column
                    style={{ minWidth: "10rem" }}
                    header="Customer Name"
                    field="customer.full_name"
                  />
                  <Column
                    style={{ minWidth: "10rem" }}
                    field="status"
                    header="Payment Status"
                    //   filter
                  />
                  <Column
                    style={{ minWidth: "10rem" }}
                    field="order_delivery.delivery_status"
                    header="Delivery Status"
                    //   filter
                  />
                  <Column
                    style={{ minWidth: "10rem" }}
                    field="mode_of_payment"
                    header="Mode of Payment"
                  />
                </DataTable>
              </div>  */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderMapTechnician;
