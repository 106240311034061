import { TabPanel, TabView } from "primereact/tabview";
import { convertToAmericanDate } from "../../utils/convertToAmericanDate";
const ViewCommunication = ({
  communicationsDataOfCustomer,
  communicationsOfCustomersLoading,
  communicationsOfCustomersError,
  oldCommunicationsDataOfCustomer,
  oldCommunicationsOfCustomersError,
  oldCommunicationsOfCustomersLoading,
}) => {
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    <>
      <style>
        {`
          p {
            color: #BCAD67 !important;
          }
        `}
      </style>
      <TabView>
        <TabPanel header="View Communications">
          {communicationsOfCustomersLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>
            </div>
          ) : communicationsOfCustomersError ? (
            <div className="flex items-center justify-center w-full h-full">
              <p>{communicationsOfCustomersError?.data?.message}</p>
            </div>
          ) : communicationsDataOfCustomer?.data.length > 0 ? (
            communicationsDataOfCustomer?.data?.map((data, index) => (
              <div
                key={index}
                style={{ padding: "10px", borderBottom: "1px solid #ccc" }}
              >
                <p>
                  <strong>Method:</strong> {data.communication_method}
                </p>
                <p>
                  <strong>Direction:</strong> {data.direction}
                </p>
                <p>
                  <strong>Content:</strong>{" "}
                  <span dangerouslySetInnerHTML={createMarkup(data.content)} />
                </p>
                <p>
                  <strong>Date:</strong>{" "}
                  {convertToAmericanDate(data.created_at)}
                </p>
              </div>
            ))
          ) : (
            <h2>No Communications</h2>
          )}
        </TabPanel>

        <TabPanel header="Old Communications">
          {oldCommunicationsOfCustomersLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>
            </div>
          ) : oldCommunicationsOfCustomersError ? (
            <div className="flex items-center justify-center w-full h-full">
              <p>{oldCommunicationsOfCustomersError?.data?.message}</p>
            </div>
          ) : oldCommunicationsDataOfCustomer?.data.length > 0 ? (
            oldCommunicationsDataOfCustomer?.data?.map((data, index) => (
              <div
                key={index}
                style={{ padding: "10px", borderBottom: "1px solid #ccc" }}
              >
                <div className="w-full text-wrap">
                  <strong>Content:</strong>{" "}
                  {/* <pre
                    className="text-[#BCAD67]"
                    dangerouslySetInnerHTML={createMarkup(data.content)}
                    /> */}
                  <pre className="text-[#BCAD67] w-full text-wrap">
                    {data?.content}
                  </pre>
                </div>
              </div>
            ))
          ) : (
            <h2>No Communications</h2>
          )}
        </TabPanel>
      </TabView>
    </>
  );
};

export default ViewCommunication;
