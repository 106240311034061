export function convertToAmericanDate(dateString) {
  // Split the input date string into an array [YYYY, MM, DD]
  if (dateString) {
    const dateParts = dateString?.split("-");

    // Reorder the array to [MM, DD, YYYY]
    const americanDate = [dateParts[1], dateParts[2], dateParts[0]].join("/");

    return americanDate;
  }
}

export const formatDateToAmerican = (date) => {
  if (!(date instanceof Date)) {
    return "";
  }

  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

export const formatDateToAmericanDate = (date) => {
  // Get the month, day, and year from the date object
  const month = date.getMonth() + 1; // Months are zero-based, so add 1
  const day = date.getDate();
  const year = date.getFullYear();

  // Pad the month and day with leading zeros if needed
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  // Format the date in MM/DD/YYYY format
  return `${formattedMonth}/${formattedDay}/${year}`;
};
