import { Dialog } from "primereact/dialog";
import ReactPlayer from "react-player";

const VideoPlayerDialog = ({ videoVisible, setVideoVisible, file }) => {
  return (
    <Dialog
      visible={videoVisible}
      onHide={() => setVideoVisible(false)}
      style={{ width: "50vw" }}
    >
      <div className="flex items-center justify-center w-full">
        <ReactPlayer url={file} height={500} width={500} controls />
      </div>
    </Dialog>
  );
};

export default VideoPlayerDialog;
