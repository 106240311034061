export const formatCurrency = (value) => {
  if (value != null) {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }
  return "";

};
