import styles from "./TreatmentTable.module.css";

import Header from "../Header";
import TreatmentTableContent from "./TreatmentTableContent";

const TreatmentTable = () => {
  return (
    <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Treatments"} />
          <div className={`w-full h-full rounded-2xl m-2 px-2`}>
            <TreatmentTableContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentTable;
