import { v4 as uuidv4 } from "uuid";
import { createSlice } from "@reduxjs/toolkit";
import { addOrderLine, updateOrderLine } from "./orderLineActions";

const initialState = {
  addLoading: false,
  updateLoading: false,
  orderLines: [],
  orderLinesSubmit: false,
};

const orderLinesSlice = createSlice({
  name: "orderLines",
  initialState,
  reducers: {
    setOrderLine: (state, { payload }) => {
      if (!state.orderLines) {
        state.orderLines = []; // Initialize orderLines if it's undefined
      }
      state.orderLines.push({ id: uuidv4(), ...payload });
      state.orderLinesSubmit = true;
    },
    removeOrderLine: (state, { payload: orderId }) => {
      state.orderLines = state.orderLines.filter(
        (order) => order.id !== orderId
      );
    },
    clearOrderLines: (state, { payload }) => {
      state.orderLines = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrderLine.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addOrderLine.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addOrderLine.rejected, (state) => {
        state.addLoading = false;
      })
      .addCase(updateOrderLine.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateOrderLine.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateOrderLine.rejected, (state) => {
        state.updateLoading = false;
      });
  },
});

export const { setOrderLine, clearOrderLines, removeOrderLine } =
  orderLinesSlice.actions;

export default orderLinesSlice.reducer;
