"use client";
import { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { addPublicCustomer } from "../../features/customer/customerActions";
import MapAddCustomer from "./../Map/mapAddCustomer";
import { removeAddressData } from "../../features/customer/customerSlice";

const AddCustomerDialogPublic = () => {
  const [submitted, setSubmitted] = useState(false);
  const [selectedSite, setSelectedSite] = useState();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const toast = useRef(null);

  const { publicAddLoading, addressData } = useSelector(
    (state) => state.customer
  );
  const sites = [
    { name: "Ring Central" },
    { name: "Print" },
    { name: "Website" },
    { name: "Facebook" },
    { name: "Instagram" },
    { name: "LinkedIn" },
    { name: "Walk In" },
    { name: "Referral" },
    { name: "Email" },
    { name: "NY Press" },
    { name: "Postcard" },
    { name: "Chris Local" },
  ];

  const defaultValues = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    postalCode: "",
    state: "",
    street: "",
    town: "",
    geo_code: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const url = window.location.href;

  const checkForKeywords = (url, sites) => {
    for (const keyword of sites) {
      if (
        url.includes(keyword.name.toLowerCase()) ||
        url.includes(keyword.name)
      ) {
        console.log(`Keyword "${keyword.name}" found in URL`);
        setSelectedSite(keyword.name);
        setDisabled(true);
        return selectedSite;
      }
    }
    console.log("No keywords found in URL");
    return false;
  };

  useEffect(() => {
    checkForKeywords(url, sites);
  }, [url, sites]);

  useEffect(() => {
    setValue("street", `${addressData?.street}`);
    setValue("town", `${addressData?.town}`);
    setValue("postalCode", `${addressData?.postalCode}`);
    setValue("state", `${addressData?.state}`);
    setValue("geocode", `${addressData?.geocode}`);
    setValue("lead_source", `${selectedSite}`);
  }, [addressData, setValue, selectedSite]);

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onSubmit = (data) => {
    // console.log(data);
    const obj = {
      email: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone,
      postal_code: data.postalCode,
      state: data.state,
      street: data.street,
      town: data.town,
      geo_code: data.geocode,
    };
    if (data.secondaryFirstName && data.secondaryFirstName !== "") {
      obj.sec_first_name = data.secondaryFirstName;
    }

    if (data.secondaryLastName && data.secondaryLastName !== "") {
      obj.sec_last_name = data.secondaryLastName;
    }

    if (data.secondaryEmail && data.secondaryEmail !== "") {
      obj.sec_email = data.secondaryEmail;
    }

    if (data.secondaryPhone && data.secondaryPhone !== "") {
      obj.sec_phone = data.secondaryPhone;
    }

    dispatch(addPublicCustomer(obj)).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        reset();
        dispatch(removeAddressData());
        setSubmitted(true);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <div className="flex items-center justify-center">
      <div className="my-5 mx-8 p-5 bg-[#ffeded] rounded-xl flex-col w-11/12  flex items-center justify-center ">
        <Toast ref={toast} />
        {submitted ? (
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-xl font-bold text-center">Form Submitted</h1>
            <p>Please Close this tab.</p>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-center mt-4 text-3xl font-bold">
              <h1 className="mx-auto w-fit">Please Fill Out</h1>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full my-8">
              <div className="flex flex-col items-center justify-between w-full gap-2 md:flex-row ">
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: "First Name is required." }}
                  render={({ field, fieldState }) => (
                    <div className="flex flex-col w-full md:w-1/2">
                      <label
                        htmlFor={field.name}
                        className={classNames({ "p-error": errors.value })}
                      >
                        First Name
                      </label>
                      <span>
                        <InputText
                          id={field.name}
                          value={field.value}
                          className={`${classNames({
                            "p-invalid": fieldState.error,
                          })} w-full rounded-xl`}
                          onChange={(e) => field.onChange(e.target.value)}
                          placeholder="First Name"
                        />
                      </span>
                      {getFormErrorMessage(field.name)}
                    </div>
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: "Last Name is required." }}
                  render={({ field, fieldState }) => (
                    <div className="flex flex-col w-full md:w-1/2">
                      <label
                        htmlFor={field.name}
                        className={classNames({ "p-error": errors.value })}
                      >
                        Last Name
                      </label>
                      <span>
                        <InputText
                          id={field.name}
                          value={field.value}
                          className={`${classNames({
                            "p-invalid": fieldState.error,
                          })} w-full rounded-xl`}
                          onChange={(e) => field.onChange(e.target.value)}
                          placeholder="Last Name"
                        />
                      </span>
                      {getFormErrorMessage(field.name)}
                    </div>
                  )}
                />
              </div>
              <div className="flex flex-col items-center justify-between w-full gap-2 md:flex-row">
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: "Email is required." }}
                  render={({ field, fieldState }) => (
                    <div className="flex flex-col w-full md:w-1/2">
                      <label
                        htmlFor={field.name}
                        className={classNames({ "p-error": errors.value })}
                      >
                        Email
                      </label>
                      <span>
                        <InputText
                          id={field.name}
                          value={field.value}
                          className={`${classNames({
                            "p-invalid": fieldState.error,
                          })} w-full rounded-xl`}
                          onChange={(e) => field.onChange(e.target.value)}
                          placeholder="Email"
                        />
                      </span>
                      {getFormErrorMessage(field.name)}
                    </div>
                  )}
                />
                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: "Phone is required.",
                    pattern: {
                      value: /^\d{10}$/,
                      message: "Please enter a valid 10-digit phone number.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <div className="flex flex-col w-full md:w-1/2">
                      <label
                        htmlFor={field.name}
                        className={classNames({ "p-error": errors.value })}
                      >
                        Phone
                      </label>
                      <span>
                        <InputText
                          id={field.name}
                          value={field.value}
                          autoFocus={true}
                          className={`${classNames({
                            "p-invalid": fieldState.invalid,
                          })} w-full rounded-xl`}
                          onChange={(e) => field.onChange(e.target.value)}
                          placeholder="Phone"
                        />
                      </span>
                      {getFormErrorMessage(field.name)}
                    </div>
                  )}
                />
              </div>
              <div className="flex w-full mt-4 justify-content-center md:w-1/2">
                <Controller
                  name="lead_source"
                  control={control}
                  rules={{
                    required: "Please select where you heard about us.",
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <div className="flex flex-col w-full md:w-2/3">
                        <label
                          htmlFor={field.name}
                          className={classNames({
                            "p-error": errors.lead_source,
                          })}
                        >
                          Where did you hear about us?
                        </label>
                        <Dropdown
                          id={field.name}
                          value={selectedSite}
                          onChange={(e) => {
                            field.onChange(e.value.name);
                            setSelectedSite(e.value.name);
                          }}
                          options={sites}
                          optionLabel="name"
                          editable
                          disabled={disabled}
                          placeholder="Select an option..."
                          className={`${classNames({
                            "p-invalid": fieldState.error,
                          })} w-full md:w-14rem`}
                        />
                        {getFormErrorMessage(field.name)}
                      </div>
                    </>
                  )}
                />
              </div>
              <div className="my-4">
                <MapAddCustomer />
              </div>
              <div className="flex items-center justify-center">
                <Button
                  label={publicAddLoading ? "" : "Submit"}
                  className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
                  type="submit"
                  icon={
                    publicAddLoading ? "pi pi-spin pi-spinner" : "pi pi-plus"
                  }
                />
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default AddCustomerDialogPublic;
