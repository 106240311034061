import { useDispatch, useSelector } from "react-redux";
import { useGetAllAdminQuery } from "../../services/admin/adminServices";
import { addTask } from "../../features/tasks/tasksActions";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { getFormErrorMessage } from "../../utils/getFormErrorMessage";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";

const AddTaskForm = ({
  data: CustomerData,
  isLoading,
  error,
  refetch,
  toast,
  setState,
}) => {
  const dispatch = useDispatch();
  const { addLoading } = useSelector((state) => state.tasks);
  const Status = [
    { label: "To Do", value: "to-do" },
    { label: "In Progress", value: "in-progress" },
    { label: "Done", value: "done" },
  ];

  const allAdmins = useGetAllAdminQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data) => {
    // Create a new Date object
    const date = new Date(data?.date);

    // Get the day, month, and year from the date object
    const day = ("0" + date.getDate()).slice(-2); // Ensure leading zero if needed
    const month = ("0" + (date.getMonth() + 1)).slice(-2); // Month is zero-based, so add 1
    const year = date.getFullYear();

    // Format the date as "MM/DD/YYYY"
    const formattedDate = `${month}/${day}/${year}`;
    const name = data?.name;
    const notes = data?.notes;
    const status = data?.status;
    const assigned_to_id = data?.assigned_to_id;
    const created_by_id = data?.created_by_id;

    dispatch(
      addTask({
        name,
        notes,
        status,
        assigned_to_id,
        created_by_id,
        date: formattedDate,
        customer_id: CustomerData?.data?.id,
      })
    ).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        reset();
        refetch();
        setState("CustomerDetails");
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res?.payload?.data?.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res?.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="my-8">
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-lg text-bold`}
              >
                Name <span>*</span>
              </label>
              <span>
                <InputText
                  id={field.name}
                  value={field?.value}
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </span>
              {getFormErrorMessage(field.name, errors)}
            </div>
          )}
        />
        <Controller
          name="notes"
          control={control}
          rules={{
            required: true,
          }}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-lg text-bold`}
              >
                Notes <span>*</span>
              </label>
              <span>
                <InputTextarea
                  id={field.name}
                  {...field}
                  rows={4}
                  cols={30}
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  autoResize
                />
              </span>
              {getFormErrorMessage(field.name, errors)}
            </div>
          )}
        />
        <div className="gap-5 lg:flex">
          <div className="lg:w-1/2">
            <Controller
              name="created_by_id"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-lg text-bold`}
                  >
                    Created by:
                  </label>
                  <span>
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={allAdmins?.data?.data?.map((ele) => ({
                        label: ele.first_name,
                        value: ele.id,
                      }))}
                      optionLabel="label"
                      optionValue="value"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
          <div className="lg:w-1/2">
            <Controller
              name="assigned_to_id"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-lg text-bold`}
                  >
                    Assigned to:
                  </label>
                  <span>
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={allAdmins?.data?.data?.map((ele) => ({
                        label: ele.first_name,
                        value: ele.id,
                      }))}
                      optionLabel="label"
                      optionValue="value"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          </div>
        </div>
        <Controller
          name="status"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Status
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={Status}
                  optionLabel="label"
                  optionValue="value"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name, errors)}
            </div>
          )}
        />
        <Controller
          name="date"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Date
              </label>
              <span>
                <Calendar
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  dateFormat="mm/dd/yy"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  inputClassName="rounded-xl"
                />
              </span>
              {getFormErrorMessage(field.name, errors)}
            </div>
          )}
        />
        <div className="flex items-center justify-center">
          <Button
            label={addLoading ? "" : "Submit"}
            className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
            type="submit"
            icon={addLoading ? "pi pi-spin pi-spinner" : "pi pi-plus"}
          />
        </div>
      </form>
    </>
  );
};

export default AddTaskForm;
