import Header from "../Header";
import DriverContent from "./DriverContent";

const Driver = () => {
  return (
    <div className={` h-screen py-4 pr-4`}>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Technicians"} />
          <div className={`w-full h-full rounded-2xl m-2 px-2`}>
            <DriverContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Driver;
