import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import { useGetAllTechniciansQuery } from "../../services/technician/technicianServices";
import { updateOrder } from "../../features/order/orderActions";
import { useMediaQuery } from "react-responsive";

const AssignTechnician = ({
  assignTechnicianVisible,
  setAssignTechnicianVisible,
  assignTechnicianId,
  refetch,
  toast,
}) => {
  console.log({ assignTechnicianId });
  const dispatch = useDispatch();

  const { updateLoading: assignTechnicianState } = useSelector(
    (state) => state.orders
  );

  const defaultValues = {
    technicianId: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const { data: techniciansData, isLoading: techniciansLoading } =
    useGetAllTechniciansQuery("TechniciansDetails", {
      refetchOnMountOrArgChange: true,
    });

  const onSubmit = (data) => {
    const obj = {
      technician_id: data.technicianId.code,
      id: assignTechnicianId,
    };
    dispatch(updateOrder(obj)).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        refetch();
        setAssignTechnicianVisible(false);
        reset();
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return (
    <Dialog
      header="Assign a technician"
      visible={assignTechnicianVisible}
      style={isDesktop ? { width: "35vw" } : isMobile ? { width: "90vw" } : {}}
      onHide={() => setAssignTechnicianVisible(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="my-8">
        <Controller
          name="technicianId"
          control={control}
          rules={{ required: "Technician is required." }}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Select a technician:
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={
                    techniciansData
                      ? techniciansData.data.map((technician) => ({
                          name: technician.full_name,
                          code: technician.id,
                        }))
                      : []
                  }
                  loading={techniciansLoading}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  placeholder="Select a Technician"
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <div className="flex items-center justify-center">
          <Button
            label={assignTechnicianState ? "" : "Submit"}
            className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
            type="submit"
            icon={
              assignTechnicianState ? "pi pi-spin pi-spinner" : "pi pi-check"
            }
            loading={assignTechnicianState}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default AssignTechnician;
