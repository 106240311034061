import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "primeicons/primeicons.css";

import App from "./App.jsx";
import "./index.css";
import { persistor, store } from "./features/store.js";

import "primereact/resources/themes/lara-light-cyan/theme.css";

import "primereact/resources/primereact.css";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
