/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useRef } from "react";
import { fetchCoordinatesFromGeoCode } from "../../services/map/addressToCoordinates";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import OrderDetailsDialog from "../../components/Orders/OrderDetailsDialog";
import { Toast } from "primereact/toast";

const MapOrder = ({ orderData }) => {
  // console.log({ orderData });
  const [markers, setMarkers] = useState([]);
  const [selectedMapOption, setSelectedMapOption] = useState();
  const [filteredOperationData, setFilteredOperationData] = useState([]);
  const [editOrderId, setEditOrderId] = useState(null);
  const [orderDetailsVisible, setOrderDetailsVisible] = useState(false);
  const toast = useRef(null);

  const getPinColor = (orderDeliveryStatus, id) => {
    switch (orderDeliveryStatus) {
      case "Need to Deliver":
      case "Need to Deliver (Tropicals)":
        return "#E12958";
      case "Delivery Scheduled":
      case "Delivery Schedule":
        return "#22C55E";
      default:
        return null;
    }
  };

  const editOrderDialog = (status, id, lat, lng) => {
    setEditOrderId(id);
    setOrderDetailsVisible(true);
  };

  useEffect(() => {
    const filterData = async () => {
      let filteredData = [];
      switch (selectedMapOption) {
        case "today":
          for (let i = 0; i < orderData?.data?.length; i++) {
            if (
              todayFormattedDate ===
              orderData?.data[i]?.order_delivery?.estimated_delivery_date
            ) {
              filteredData.push(orderData?.data[i]);
            }
          }
          break;
        case "thisWeek":
          for (let i = 0; i <= 6; i++) {
            const today = new Date();
            const currentDate = new Date(today);
            currentDate.setDate(currentDate.getDate() + i);
            const formattedCurrentDate = currentDate.toLocaleDateString(
              "en-US",
              {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              }
            );
            for (let j = 0; j < orderData?.data?.length; j++) {
              if (
                formattedCurrentDate ===
                orderData?.data[j]?.order_delivery?.estimated_delivery_date
              ) {
                filteredData.push(orderData?.data[j]);
              }
            }
          }
          break;
        case "schedule":
          for (let i = 0; i < orderData?.data.length; i++) {
            if (
              orderData?.data[i]?.order_delivery?.estimated_delivery_date === ""
            ) {
              filteredData.push(orderData?.data[i]);
            }
          }
          break;
        case "allOrders":
          filteredData = orderData?.allOrderData?.data || [];
          break;
        default:
          filteredData = orderData?.data || [];
      }

      const coordinates = await fetchCoordinatesFromGeoCode(filteredData);
      setMarkers(coordinates.length !== 0 ? coordinates : [{ lat: 0, lng: 0 }]);
    };

    filterData();
  }, [orderData, selectedMapOption]);

  const currentDate = new Date();
  const todayFormattedDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  const handleSelection = (e) => {
    setSelectedMapOption(e.target.value);
    setFilteredOperationData([]);
  };

  const mapContainerStyle = {
    width: "75vw",
    height: "70vh",
  };

  const center = {
    lat: markers[0]?.lat || 0,
    lng: markers[0]?.lng || 0,
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCRVh8i3NvcSTjn9eZ3avLuP4eapTrXpG4",
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Toast ref={toast} />
      <div>
        <select
          id="dateOptions"
          className="h-10 mb-3 text-xl w-60 rounded-xl"
          onChange={handleSelection}
        >
          <option value="allDates">All Dates</option>
          <option value="allOrders">All Orders</option>
          <option value="today">Today</option>
          <option value="thisWeek">This Week</option>
          <option value="schedule">Need to Schedule</option>
        </select>
      </div>
      <GoogleMap mapContainerStyle={mapContainerStyle} zoom={8} center={center}>
        {markers?.map((marker, index) => (
          <MarkerF
            key={index}
            position={{ lat: marker?.lat, lng: marker?.lng }}
            icon={{
              path: "M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482C346.693,59.825,286.846,0,213.285,0zM274.865,136.62c0,34.124-27.761,61.884-61.885,61.884c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z",
              fillColor: getPinColor(marker.orderDeliveryStatus, marker.id),
              fillOpacity: 1,
              strokeWeight: 0,
              rotation: 0,
              scale: 0.08,
            }}
            onClick={() => {
              editOrderDialog(marker.status, marker.id, marker.lat, marker.lng);
            }}
          />
        ))}
      </GoogleMap>
      {editOrderId && (
        <OrderDetailsDialog
          visible={orderDetailsVisible}
          setVisible={setOrderDetailsVisible}
          setOrderId={setEditOrderId}
          id={editOrderId}
        />
      )}
    </div>
  );
};

export default MapOrder;
