import { createAsyncThunk } from "@reduxjs/toolkit";

import { instance } from "../../axios/axios";

export const addTask = createAsyncThunk(
  "task/create",
  async (
    {
      name,
      notes,
      created_by_id,
      assigned_to_id,
      status,
      is_active,
      date,
      customer_id,
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status: reqStatus } = await instance.post(
        "tasks",
        {
          name,
          created_by_id,
          assigned_to_id,
          status,
          is_active,
          notes,
          date,
          customer_id,
        },
        config
      );
      return { data, reqStatus };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTask = createAsyncThunk(
  "task/update",
  async (
    { id, name, created_by_id, assigned_to_id, status, is_active, notes, date },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status: reqStatus } = await instance.patch(
        `tasks/${id}`,
        {
          name,
          created_by_id,
          assigned_to_id,
          status,
          is_active,
          notes,
          date,
        },
        config
      );
      return { data };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteTask = createAsyncThunk(
  "task/delete",
  async ({ task_id }, { rejectWithValue, getState }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.delete(
        `tasks/${task_id}`,
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
