import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const TechnicianProtectedRoute = () => {
  const { technicianToken } = useSelector((state) => state.auth);
  const auth = technicianToken; // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to="/login" />;
};

export default TechnicianProtectedRoute;
