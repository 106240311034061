import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
// import { Chip } from "primereact/chip";

import { instance } from "../../axios/axios";
import { wantTreatment } from "../../features/treatment/treatmentActions";
import { InputNumber } from "primereact/inputnumber";
import { updateAddNoteOperation } from "../../features/operation/operationActions";
import { InputTextarea } from "primereact/inputtextarea";

const TreatmentForm = () => {
  const [treatmentData, setTreatmentData] = useState([]);
  console.log({ treatmentData });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const toast = useRef(null);

  const dispatch = useDispatch();

  const { token } = useParams();

  const { updateOperationLoading } = useSelector((state) => state.operation);
  const { wantTreatmentTreatment } = useSelector((state) => state.treatment);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await instance.put("customers/form/wants-treatment", {
          token,
        });
        setTreatmentData(response.data.data);
      } catch (error) {
        console.error("Error fetching treatment data:", error);
        // Handle error
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [token]);

  const defaultValues = {
    treatmentConfirmation: "",
    total_number_of_trees: 1,
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues });

  const selectedValue = watch("treatmentConfirmation");

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onSubmit = (data) => {
    const obj = {
      operationId: treatmentData && treatmentData.operation_id,
      want_treat_response: data.treatmentConfirmation,
    };

    dispatch(wantTreatment(obj)).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        if (data.treatmentConfirmation === "true") {
          dispatch(
            updateAddNoteOperation({
              id: obj.operationId,
              total_number_of_trees: data?.total_number_of_trees,
              customer_input_notes: data?.customer_input_notes,
            })
          ).then((res) => {
            if (res?.payload?.data?.status === "Success") {
              setIsSubmitted(true);
              reset();
            } else {
              // toast.current.show({
              //   severity: "success",
              //   summary: "Form Submitted",
              //   detail: res.payload.data.message,
              //   life: 3000,
              // });
            }
          });
        } else {
          setIsSubmitted(true);
        }
        // toast.current.show({
        //   severity: "success",
        //   summary: "Form Submitted",
        //   detail: res.payload.data.message,
        //   life: 3000,
        // });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <>
      <Toast ref={toast} />
      {isSubmitted ? (
        <div className="w-full flex min-h-screen bg-[#1F634E]">
          <div
            className="w-11/12 md:w-1/2 flex flex-col items-center justify-center mx-auto bg-[#FFEDED] m-4 rounded-3xl"
            style={{
              boxShadow:
                "7.434699535369873px 7.434699535369873px 7.434699535369873px 8.902203559875488px #0000004D",
            }}
          >
            <h1 className="text-6xl font-extrabold text-center">
              Form Submitted
            </h1>
            <p className="text-2xl font-semibold">Please Close this tab</p>
          </div>
        </div>
      ) : (
        <div className="w-full flex min-h-screen bg-[#1F634E]">
          {isLoading ? (
            <div
              className="w-11/12 md:w-1/2 flex flex-col items-center justify-center mx-auto bg-[#FFEDED] m-4 rounded-3xl"
              style={{
                boxShadow:
                  "7.434699535369873px 7.434699535369873px 7.434699535369873px 8.902203559875488px #0000004D",
              }}
            >
              <div className="m-4">
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "2rem" }}
                ></i>
              </div>
            </div>
          ) : (
            <div
              className="w-11/12 md:w-1/2 flex flex-col mx-auto bg-[#FFEDED] m-4 rounded-3xl"
              style={{
                boxShadow:
                  "7.434699535369873px 7.434699535369873px 7.434699535369873px 8.902203559875488px #0000004D",
              }}
            >
              <div className="m-4">
                <div className="m-4">
                  <h1 className="text-3xl font-bold">Signup Form</h1>
                </div>
                <div className="m-4">
                  <h2 className="text-2xl font-semibold">
                    {treatmentData?.treatment?.title}
                  </h2>
                </div>
                <pre className="m-4 font-sans whitespace-pre-line">
                  {treatmentData?.treatment?.form_text}
                </pre>
                <div className="m-4">
                  <h2 className="text-xl font-semibold">Name</h2>
                  <p>{treatmentData?.full_name}</p>
                </div>
                <div className="m-4">
                  <h2 className="text-xl font-semibold">Email</h2>
                  <p>{treatmentData?.email}</p>
                </div>
                <div className="m-4">
                  <h2 className="text-xl font-semibold">Full Address</h2>
                  <p>{treatmentData?.full_address}</p>
                </div>
                <div className="m-4">
                  <h2 className="text-xl font-semibold">Service</h2>
                  <p>{treatmentData?.treatment?.service}</p>
                </div>
                <div className="m-4">
                  <h2 className="text-xl font-semibold">Year</h2>
                  <p>{treatmentData?.treatment?.date.substring(0, 4)}</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="m-4">
                    <Controller
                      name="treatmentConfirmation"
                      control={control}
                      rules={{
                        required: "Treatment Confirmation is required.",
                      }}
                      render={({ field, fieldState }) => (
                        <div className="flex flex-col">
                          <label
                            htmlFor={field.name}
                            className={`${classNames({
                              "p-error": errors.value,
                            })} font-semibold text-xl`}
                          >
                            Are you interested in this services?
                          </label>
                          <div className="flex gap-4 my-4 flex-wrap">
                            <div className="flex">
                              <RadioButton
                                inputId="Yes"
                                name="category"
                                value={"true"}
                                checked={field.value === "true"}
                                onChange={(e) => field.onChange(e.target.value)}
                                className={`${classNames({
                                  "p-invalid": fieldState.error,
                                })} mr-1`}
                                inputRef={field.ref}
                              />
                              <label htmlFor="Yes" className="ml-1 mr-3">
                                Yes
                              </label>
                            </div>
                            <div className="flex">
                              <RadioButton
                                inputId="No"
                                name="category"
                                value={"false"}
                                onChange={(e) => field.onChange(e.target.value)}
                                className={`${classNames({
                                  "p-invalid": fieldState.error,
                                })} mr-1`}
                                checked={field.value === "false"}
                              />
                              <label htmlFor="No" className="ml-1 mr-3">
                                No
                              </label>
                            </div>
                          </div>
                          {getFormErrorMessage(field.name)}
                        </div>
                      )}
                    />
                  </div>
                  {selectedValue === "true" && (
                    <>
                      <div className="m-4">
                        <Controller
                          name="total_number_of_trees"
                          control={control}
                          rules={{
                            required: "Total Numbers Of Trees is required.",
                          }}
                          render={({ field, fieldState }) => (
                            <div className="flex flex-col">
                              <label
                                htmlFor={field.name}
                                className={`font-semibold text-xl ${classNames({
                                  "p-error": errors.value,
                                })}`}
                              >
                                Total Number of Trees to be Treated.
                              </label>
                              <span>
                                <InputNumber
                                  id={field.name}
                                  value={field.value}
                                  className={`${classNames({
                                    "p-invalid": fieldState.error,
                                  })} w-full rounded-xl`}
                                  onValueChange={(e) => field.onChange(e.value)}
                                  inputClassName="rounded-xl"
                                  min={1}
                                />
                              </span>
                              {getFormErrorMessage(field.name)}
                            </div>
                          )}
                        />
                      </div>
                      <div className="m-4">
                        <Controller
                          name="customer_input_notes"
                          control={control}
                          render={({ field, fieldState }) => (
                            <div className="flex flex-col">
                              <label
                                htmlFor={field.name}
                                className={`font-semibold text-xl ${classNames({
                                  "p-error": errors.value,
                                })}`}
                              >
                                Notes/Comments
                              </label>
                              <span>
                                <InputTextarea
                                  id={field.name}
                                  {...field}
                                  rows={4}
                                  cols={30}
                                  className={`${classNames({
                                    "p-invalid": fieldState.error,
                                  })} w-full rounded-xl`}
                                  autoResize
                                />
                              </span>
                              {getFormErrorMessage(field.name)}
                            </div>
                          )}
                        />
                      </div>
                    </>
                  )}
                  {(updateOperationLoading || wantTreatmentTreatment) && (
                    <p className="my-2 text-center">
                      Please wait until the form is submitted.
                    </p>
                  )}
                  <div className="flex items-center justify-center">
                    <Button
                      style={{
                        boxShadow:
                          "rgba(0, 0, 0, 0.25) 0px 3.16594px 3.16594px 2.37445px",
                      }}
                      type="submit"
                      className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
                      label="Submit"
                      loading={updateOperationLoading || wantTreatmentTreatment}
                    />
                  </div>
                </form>
              </div>
            </div>
          )}
          {error && (
            <div className="flex items-center justify-center w-full h-full">
              <p>{error.message}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TreatmentForm;
