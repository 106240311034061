import logo from "../assets/plamLogo.png";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div className="flex flex-col items-center justify-between h-screen text-gray-50">
      <div className="flex items-end justify-center md:p-4 pb-2 md:pd-0 pt-8 border-b border-[--light-text] w-4/5">
        <img src={logo} className="md:mr-2" alt="Logo" />
        <h2 className="hidden text-2xl font-bold leading-9 md:block ">
          Palm Trees
        </h2>
      </div>
      <div className="flex flex-col items-center justify-around w-full h-full gap-2 mx-2 mb-8">
        <div className="flex items-center justify-end w-full h-1/9">
          <Link
            to={"/"}
            className={`${
              pathname === "/" && "bg-[#ffeded] text-[--main-green-background]"
            } flex py-4 items-center md:justify-start justify-center md:w-[90%] w-full  transition-colors duration-500 linear cursor-pointer hover:bg-[#ffeded] hover:text-[--main-green-background] rounded-s-xl`}
          >
            <i className="mx-2 text-2xl pi pi-cart-plus"></i>
            <p className="hidden text-xl font-bold leading-6 md:block">
              Orders
            </p>
          </Link>
        </div>
        <div className="flex items-center justify-end w-full h-1/9">
          <Link
            to={"/operation"}
            className={`flex py-4 items-center md:justify-start justify-center md:w-[90%] w-full  transition-colors duration-500 linear cursor-pointer hover:bg-[#ffeded] hover:text-[--main-green-background] rounded-s-xl ${
              (pathname === "/operation" || pathname === "/send-treatment") &&
              "bg-[#ffeded] text-[--main-green-background]"
            }`}
          >
            <i className="mx-2 text-2xl pi pi-home"></i>
            <p className="hidden text-xl font-bold leading-6 md:block">
              Trees Treatments
            </p>
          </Link>
        </div>
        <div className="flex items-center justify-end w-full h-1/9">
          <Link
            to={"/customer"}
            className={`flex py-4 items-center md:justify-start justify-center md:w-[90%] w-full  transition-colors duration-500 linear cursor-pointer hover:bg-[#ffeded] hover:text-[--main-green-background] rounded-s-xl  ${
              pathname === "/customer" &&
              "bg-[#ffeded] text-[--main-green-background]"
            }`}
          >
            <i className="mx-2 text-2xl pi pi-users"></i>
            <p className="hidden text-xl font-bold leading-6 md:block">
              Customers
            </p>
          </Link>
        </div>
        <div className="flex items-center justify-end w-full h-1/9">
          <Link
            to={"/treatment"}
            className={`${
              pathname === "/treatment" &&
              "bg-[#ffeded] text-[--main-green-background]"
            } flex py-4 items-center md:justify-start justify-center md:w-[90%] w-full  transition-colors duration-500 linear cursor-pointer hover:bg-[#ffeded] hover:text-[--main-green-background] rounded-s-xl`}
          >
            <i className="mx-2 text-2xl pi pi-file"></i>
            <p className="hidden text-xl font-bold leading-6 md:block">
              Treatments
            </p>
          </Link>
        </div>
        <div className="flex items-center justify-end w-full h-1/9">
          <Link
            to={"/products"}
            className={`${
              pathname === "/products" &&
              "bg-[#ffeded] text-[--main-green-background]"
            } flex py-4 items-center md:justify-start justify-center md:w-[90%] w-full  transition-colors duration-500 linear cursor-pointer hover:bg-[#ffeded] hover:text-[--main-green-background] rounded-s-xl`}
          >
            <i className="mx-2 text-2xl pi pi-box"></i>
            <p className="hidden text-xl font-bold leading-6 md:block">
              Products
            </p>
          </Link>
        </div>
        <div className="flex items-center justify-end w-full h-1/9">
          <Link
            to={"/technicians"}
            className={`${
              pathname === "/technicians" &&
              "bg-[#ffeded] text-[--main-green-background]"
            } flex py-4 items-center md:justify-start justify-center md:w-[90%] w-full  transition-colors duration-500 linear cursor-pointer hover:bg-[#ffeded] hover:text-[--main-green-background] rounded-s-xl`}
          >
            <i className="mx-2 text-2xl pi pi-truck"></i>
            <p className="hidden text-xl font-bold leading-6 md:block">
              Technician
            </p>
          </Link>
        </div>
        <div className="flex items-center justify-end w-full h-1/9">
          <Link
            to={"/admins"}
            className={`${
              pathname === "/admins" &&
              "bg-[#ffeded] text-[--main-green-background]"
            } flex py-4 items-center md:justify-start justify-center md:w-[90%] w-full  transition-colors duration-500 linear cursor-pointer hover:bg-[#ffeded] hover:text-[--main-green-background] rounded-s-xl`}
          >
            <i className="mx-2 text-2xl pi pi-user"></i>
            <p className="hidden text-xl font-bold leading-6 md:block">
              Admins
            </p>
          </Link>
        </div>
        <div className="flex items-center justify-end w-full h-1/9">
          <Link
            to={"/tasks"}
            className={`${
              pathname === "/tasks" &&
              "bg-[#ffeded] text-[--main-green-background]"
            } flex py-4 items-center md:justify-start justify-center md:w-[90%] w-full  transition-colors duration-500 linear cursor-pointer hover:bg-[#ffeded] hover:text-[--main-green-background] rounded-s-xl`}
          >
            <i className="mx-2 text-2xl pi pi-list"></i>
            <p className="hidden text-xl font-bold leading-6 md:block">Tasks</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
