// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../../utils/apiURL";

export const techniciansApi = createApi({
  reducerPath: "techniciansApi",
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({

    baseUrl: apiUrl,

    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllTechnicians: builder.query({ query: () => `technicians` }),
    getTechnician: builder.query({ query: (id) => `technicians/${id}` }),
  }),
});

export const { useGetAllTechniciansQuery, useGetTechnicianQuery } =
  techniciansApi;
