import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { useForm, Controller } from "react-hook-form";
import { useGetTreeConditionsQuery } from "../../services/TreeConditions/treeConditionsServices";
import { editTreeConditions } from "../../features/TreeConditions/treeConditionsActions";
import { Image } from "primereact/image";
import VideoPlayerDialog from "./VideoPlayerDialog";
import { apiUrl } from "../../utils/apiURL";

const EditTree = ({
  showTreeEdit,
  setShowTreeEdit,
  treeId,
  toast,
  refetch,
}) => {
  const { updateLoading } = useSelector((state) => state.treeConditions);
  const [treeMapsImages, setTreeMapsImages] = useState([]);
  const [videoVisible, setVideoVisible] = useState(false);
  const [file, setFile] = useState("");

  const dispatch = useDispatch();

  const treeConditionOptions = [
    { name: "Poor", code: "Poor" },
    { name: "Good", code: "Good" },
    { name: "Great", code: "Great" },
  ];

  const typeOfCoverOptions = [
    { name: "Old Green", code: "Old Green" },
    { name: "New Green", code: "New Green" },
    { name: "White", code: "White" },
  ];

  const coverSizeOptions = [
    { name: 6, code: 6 },
    { name: 8, code: 8 },
    { name: 10, code: 10 },
    { name: 12, code: 12 },
    { name: 14, code: 14 },
    { name: 16, code: 16 },
    { name: 18, code: 18 },
    { name: 20, code: 20 },
    { name: 22, code: 22 },
    { name: 24, code: 24 },
  ];

  const frameSizeOptions = [
    { name: 4, code: 4 },
    { name: 6, code: 6 },
    { name: 8, code: 8 },
    { name: 10, code: 10 },
    { name: 12, code: 12 },
    { name: 14, code: 14 },
    { name: 16, code: 16 },
    { name: 18, code: 18 },
    { name: 20, code: 20 },
    { name: 22, code: 22 },
    { name: 24, code: 24 },
  ];

  const extensionNextYearOptions = [
    { name: "Yes", code: "Yes" },
    { name: "No", code: "No" },
  ];

  const typeOfExtensionOptions = [
    { name: "Solid Green", code: "Solid Green" },
    { name: "Screen Green", code: "Screen Green" },
    { name: "None", code: "None" },
    { name: "White", code: "None" },
  ];

  const { data, isLoading, error } = useGetTreeConditionsQuery(treeId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data) setTreeMapsImages(data?.data?.tree_pictures);
  }, [data]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (data) {
      reset({
        tree_condition: {
          name: data?.data?.tree_condition,
          code: data?.data?.tree_condition,
        },
        type_of_cover: {
          name: data?.data?.type_of_cover,
          code: data?.data?.type_of_cover,
        },
        cover_size: {
          name: Number(data?.data?.cover_size),
          code: Number(data?.data?.cover_size),
        },
        frame_size: {
          name: Number(data?.data?.frame_size),
          code: Number(data?.data?.frame_size),
        },
        is_extension_next_year: data?.data?.is_extension_next_year
          ? {
              name: "Yes",
              code: "Yes",
            }
          : {
              name: "No",
              code: "No",
            },
        type_of_extension: {
          name: data?.data?.type_of_extension,
          code: data?.data?.type_of_extension,
        },
        note: data?.data?.note,
      });
    }
  }, [reset, data]);

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onSubmit = (data) => {
    const obj = {
      tree_id: treeId,
      tree_condition: data?.tree_condition?.name,
      type_of_cover: data?.type_of_cover?.name || "",
      cover_size: data?.cover_size?.name,
      frame_size: data?.cover_size?.name,
      is_extension_next_year: data?.is_extension_next_year?.name === "Yes",
      type_of_extension: data?.type_of_extension?.name || "",
      note: data?.note,
      tree_pictures: treeMapsImages,
    };

    dispatch(editTreeConditions(obj)).then((res) => {
      if (res?.payload?.status === 200) {
        refetch();
        setShowTreeEdit(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const onUpload = (e) => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });

    const response = JSON.parse(e.xhr.response);
    const imageUrl = response.data;

    setTreeMapsImages((prevImages) => {
      if (!Array.isArray(prevImages)) {
        console.error("prevImages is not an array:", prevImages);
        return [imageUrl]; // Return a new array with the image
      }
      return [...prevImages, imageUrl];
    });
  };

  return (
    <Dialog
      header="Edit Tree Conditions"
      visible={showTreeEdit}
      onHide={() => setShowTreeEdit(false)}
      style={{ width: "30vw" }}
    >
      <VideoPlayerDialog
        file={file}
        videoVisible={videoVisible}
        setVideoVisible={setVideoVisible}
      />
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="my-4 p-field">
            <label htmlFor="treePicture">Tree Picture</label>
            <FileUpload
              id="treePicture"
              mode="basic"
              name="img"

              url={`${apiUrl}media/api/upload`}

              accept="image/*, video/*"
              maxFileSize={35000000}
              onUpload={(e) => onUpload(e)}
              chooseLabel="Drop here"
              auto
            />
            <div className="flex flex-wrap gap-4 my-4">
              {treeMapsImages?.map((file) => {
                const extension = file.split(".").pop().toLowerCase(); // Get the file extension

                if (
                  extension === "png" ||
                  extension === "jpg" ||
                  extension === "jpeg" ||
                  extension === "gif"
                ) {
                  return (
                    <Image
                      key={file}
                      src={file}
                      zoomSrc={file}
                      alt="Image"
                      width="80"
                      height="60"
                      preview
                      imageClassName="w-20 h-20"
                    />
                  );
                } else if (extension === "mp4" || extension === "mov") {
                  return (
                    <div>
                      <Button
                        icon="pi pi-play"
                        className="w-20 h-20"
                        onClick={(e) => {
                          e.preventDefault();
                          setVideoVisible(true);
                          setFile(file);
                        }}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          {/* <div className="my-4">
            <h3 className="text-lg font-bold">Tree Picture</h3>
            <div className="flex flex-wrap gap-4 my-4">
              {data?.data?.tree_pictures?.map((img) => (
                <Image
                  src={img}
                  zoomSrc={img}
                  alt="Image"
                  width="80"
                  height="60"
                  preview
                  imageClassName="w-20 h-20"
                />
              ))}
            </div>
          </div> */}
          <Controller
            name="tree_condition"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Tree Conditions
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={treeConditionOptions}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="type_of_cover"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Type Of Cover
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={typeOfCoverOptions}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="cover_size"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Cover Size
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={coverSizeOptions}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="frame_size"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Frame Size
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={frameSizeOptions}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="is_extension_next_year"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Extension Next Year?
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={extensionNextYearOptions}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="type_of_extension"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Type of Extension
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={typeOfExtensionOptions}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="note"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Comments
                </label>
                <span>
                  <InputTextarea
                    id={field.name}
                    {...field}
                    rows={4}
                    cols={30}
                    autoResize
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <div className="flex items-center justify-center">
            <Button label="Submit" loading={updateLoading} />
          </div>
          {error && (
            <div className="flex items-center justify-center w-full h-full">
              <p>{error.message}</p>
            </div>
          )}
        </form>
      )}
    </Dialog>
  );
};

export default EditTree;
