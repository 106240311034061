import { combineReducers } from "redux";

import authSlice from "./auth/authSlice";
import adminSlice from "./admin/adminSlice";
import tasksSlice from "./tasks/tasksSlice";
import orderSlice from "./order/orderSlice";
import productsSlice from "./products/productsSlice";
import deliverySlice from "./delivery/deliverySlice";
import customerSlice from "./customer/customerSlice";
import operationSlice from "./operation/operationSlice";
import treatmentSlice from "./treatment/treatmentSlice";
import orderLineSlice from "./orderLine/orderLineSlice";
import technicanSlice from "./technician/technicanSlice";
import treeConditionsSlice from "./TreeConditions/treeConditionsSlice";

import { orderApi } from "../services/order/orderServices";
import { tasksApi } from "../services/tasks/tasksServices";
import { adminApi } from "./../services/admin/adminServices";
import { productApi } from "../services/product/productServices";
import { customerApi } from "../services/customer/customerServices";
import { operationApi } from "../services/operation/operationServices";
import { treatmentApi } from "../services/treatment/treatmentServices";
import { orderLineApi } from "../services/orderLine/orderLineServices";
import { techniciansApi } from "../services/technician/technicianServices";
import { treeConditionsApi } from "../services/TreeConditions/treeConditionsServices";
import { operationForTechnicianApi } from "../services/operation/operationForTechnician";

export default combineReducers({
  auth: authSlice,
  admin: adminSlice,
  tasks: tasksSlice,
  orders: orderSlice,
  delivery: deliverySlice,
  products: productsSlice,
  customer: customerSlice,
  treatment: treatmentSlice,
  operation: operationSlice,
  technician: technicanSlice,
  orderLines: orderLineSlice,
  treeConditions: treeConditionsSlice,
  [orderApi.reducerPath]: orderApi.reducer,
  [adminApi.reducerPath]: adminApi.reducer,
  [tasksApi.reducerPath]: tasksApi.reducer,
  [productApi.reducerPath]: productApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [orderLineApi.reducerPath]: orderLineApi.reducer,
  [treatmentApi.reducerPath]: treatmentApi.reducer,
  [operationApi.reducerPath]: operationApi.reducer,
  [techniciansApi.reducerPath]: techniciansApi.reducer,
  [treeConditionsApi.reducerPath]: treeConditionsApi.reducer,
  [operationForTechnicianApi.reducerPath]: operationForTechnicianApi.reducer,
});
