"use client";
import { useState, useEffect } from "react";
import MapOrder from "../../services/map/mapOrder";
import { useGetAllOrdersQuery } from "../../services/order/orderServices";
import Header from "./../Header";
import styles from "./Map.module.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const OrderMapDialog = () => {
  const [filteredData, setFilteredData] = useState([]);
  const { data, isLoading, error, refetch } = useGetAllOrdersQuery("orders", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const filterData = () => {
      let orders = [];
      data?.data.map((order) => {
        if (
          order.order_delivery?.delivery_status === "Need to Deliver" ||
          order.order_delivery?.delivery_status ===
            "Need to Deliver (Tropicals)" ||
          order.order_delivery?.delivery_status === "Delivery Schedule" ||
          order.order_delivery?.delivery_status === "Delivery Scheduled"
        ) {
          orders.push(order);
        }

        return orders;
      });
      setFilteredData(orders);
      console.log(filteredData)
    };
    filterData();
  }, [data, isLoading, error, refetch]);
  return (
    <>
      <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
        <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
          <div className="flex flex-col justify-between h-full">
            <Header title={"Orders"} />
              <div className="flex justify-center">
                <MapOrder orderData={{ data: filteredData , allOrderData : data , refetchdata : refetch}} />
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderMapDialog;
