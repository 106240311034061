import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../axios/axios";

export const addCustomer = createAsyncThunk(
  "customer/create",
  async (
    {
      email,
      first_name,
      last_name,
      phone,
      postal_code,
      sec_email,
      sec_first_name,
      sec_last_name,
      sec_phone,
      state,
      street,
      town,
      geo_code,
      location,
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.post(
        "customers",
        {
          email,
          first_name,
          last_name,
          phone,
          postal_code,
          sec_email,
          sec_first_name,
          sec_last_name,
          sec_phone,
          state,
          street,
          town,
          geo_code,
          location,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateCustomer = createAsyncThunk(
  "customer/update",
  async (
    {
      id,
      email,
      first_name,
      last_name,
      phone,
      postal_code,
      sec_email,
      sec_first_name,
      sec_last_name,
      sec_phone,
      state,
      street,
      town,
      ordered_windmill,
      do_not_contact_again,
      prev_treatements,
      to_do_notes,
      geo_code,
      location,
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.patch(
        `customers/${id}`,
        {
          email,
          first_name,
          last_name,
          phone,
          postal_code,
          sec_email,
          sec_first_name,
          sec_last_name,
          sec_phone,
          state,
          street,
          town,
          ordered_windmill,
          do_not_contact_again,
          prev_treatements,
          to_do_notes,
          geo_code,
          location,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/delete",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.delete(`customers/${id}`, config);
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const contactAgainCustomer = createAsyncThunk(
  "customer/contactAgain",
  async (
    { id },

    { rejectWithValue }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.patch(
        `customers/${id}/donot_contact_again`,
        {},
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const communicateToCustomerEmail = createAsyncThunk(
  "customer/communicate",
  async (
    { cust_id_arr, email_body, email_subject },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.put(
        `marketingComm/send`,
        {
          cust_id_arr,
          email_body,
          //incoming_category_type,
          email_subject,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const communicateToCustomerSms = createAsyncThunk(
  "customer/communicate",
  async ({ cust_id_arr, sms_body }, { rejectWithValue, getState }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.put(
        `marketingComm/send`,
        {
          cust_id_arr,
          //incoming_category_type,
          sms_body,
        },

        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addPublicCustomer = createAsyncThunk(
  "public/customer",
  async (
    {
      email,
      first_name,
      last_name,
      phone,
      postal_code,
      sec_email,
      sec_first_name,
      sec_last_name,
      sec_phone,
      state,
      street,
      town,
      geo_code,
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.post(
        "customers/public-form",
        {
          email,
          first_name,
          last_name,
          phone,
          postal_code,
          sec_email,
          sec_first_name,
          sec_last_name,
          sec_phone,
          state,
          street,
          town,
          geo_code,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getFilterCustomerByProduct = createAsyncThunk(
  "customers/getFilterCustomerByProduct",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data } = await instance.get(
        `customers/filter/product/${id}`,
        config
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
