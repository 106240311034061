"use client";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api";
import { setAddressData } from "../../features/customer/customerSlice";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Base64 } from "js-base64";

function MapCustomerService() {
  const [mapCenter, setMapCenter] = useState();
  const [markerPosition, setMarkerPosition] = useState(null);
  const [inputAddress, setInputAddress] = useState("");
  const [geocode, setGeocode] = useState("");
  const [street, setStreet] = useState("");
  const [town, setTown] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const addLocationRef = useRef();

  const dispatch = useDispatch();

  const handleAddressChange = (event) => {
    setInputAddress(event.target.value);
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCRVh8i3NvcSTjn9eZ3avLuP4eapTrXpG4",
    libraries: ["places"],
  });

  const handleSearch = (event) => {
    event.preventDefault();
    setInputAddress(addLocationRef.current.value);

    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode(
      { address: addLocationRef.current.value },
      (results, status) => {
        if (status === "OK") {
          const { lat, lng } = results[0].geometry.location;
          setMapCenter({ lat: lat(), lng: lng() });
          setMarkerPosition({ lat: lat(), lng: lng() });
          const addressComponents = results[0].address_components;
          // Extracting relevant address components
          const streetNumber =
            addressComponents.find((component) =>
              component.types.includes("street_number")
            )?.long_name || "";
          const streetName =
            addressComponents.find((component) =>
              component.types.includes("route")
            )?.long_name || "";

          const region =
            addressComponents.find((component) =>
              component.types.includes("administrative_area_level_3")
            )?.long_name || "";

          const street = streetNumber + " " + streetName + " " + region;
          setStreet(street);
          const town =
            addressComponents.find((component) =>
              component.types.includes("locality")
            )?.long_name || "";
          setTown(town);
          const state =
            addressComponents.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name || "";
          setState(state);
          const postalCode =
            addressComponents.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";
          setPostalCode(postalCode);
        } else {
          console.error(
            "Geocode was not successful for the following reason:",
            status
          );
        }
      }
    );
  };

  useEffect(() => {
    if (isLoaded && mapCenter) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: mapCenter }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const formattedAddress = results[0].formatted_address;
            const geocodeData = {
              i: formattedAddress,
              o: {
                status: "OK",
                formattedAddress: formattedAddress,
                lat: mapCenter.lat,
                lng: mapCenter.lng,
              },
              e: Date.now(),
            };
            const encodedGeocode = Base64.encode(JSON.stringify(geocodeData));
            setGeocode(encodedGeocode);
            console.log(encodedGeocode)
          } else {
            console.log("No results found");
          }
        } else {
          console.log("Geocoder failed due to: " + status);
        }
      });
    }
  }, [isLoaded, mapCenter]);

  useEffect(() => {
    if (geocode && street && town && state && postalCode) {
      dispatch(
        setAddressData({
          street,
          town,
          state,
          postalCode,
          geocode,
        })
      );
    }
  }, [dispatch, geocode, postalCode, state, street, town]);

  return isLoaded ? (
    <>
      <div className="flex items-center justify-between gap-2 mt-5 md:justify-start">
        <Autocomplete>
          <InputText
            required
            className="w-full rounded-xl"
            type="text"
            placeholder="Please type your address..."
            ref={addLocationRef}
            value={inputAddress}
            onChange={handleAddressChange}
          />
        </Autocomplete>
        <Button
          className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
          onClick={handleSearch}
          label="Search"
        />
      </div>
      <small className="ml-3 text-[#0D6047] font-extrabold text-sm">
        * Please ensure you click the "Search" button before proceeding with
        your submission.
      </small>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        center={mapCenter}
        zoom={12}
      >
        {markerPosition && <MarkerF position={markerPosition} />}
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default MapCustomerService;
