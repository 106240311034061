import Header from "../Header";
import AdminsTable from "./AdminsTable";
import styles from "./Admins.module.css";

const Admins = () => {
  return (
    <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Admins"} />
          <div className={`w-full h-full rounded-2xl m-2 px-2`}>
            <AdminsTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admins;
