import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../axios/axios";

export const addTechnician = createAsyncThunk(
  "technician/create",
  async (
    {
      first_name,
      last_name,
      username,
      email,
      password,
      is_active,
      role,
      phone,
      full_address,
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.post(
        "technicians",
        {
          first_name,
          last_name,
          username,
          email,
          password,
          is_active,
          role,
          phone,
          full_address,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateTechnician = createAsyncThunk(
  "technician/update",
  async (
    {
      id,
      first_name,
      last_name,
      username,
      email,
      password,
      is_active,
      role,
      phone,
      full_address,
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.patch(
        `technicians/${id}`,
        {
          first_name,
          last_name,
          username,
          email,
          password,
          is_active,
          role,
          phone,
          full_address,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteTechnician = createAsyncThunk(
  "technician/delete",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.delete(
        `technicians/${id}`,
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
