import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputNumber } from "primereact/inputnumber";

import { useGetProductQuery } from "../../services/product/productServices";
import VideoPlayerDialog from "../ClientTreatment/VideoPlayerDialog";
import { updateProduct } from "../../features/products/productActions";
import { apiUrl } from "../../utils/apiURL";

const EditProduct = ({
  editProductId,
  editProductVisible,
  setEditProductVisible,
  refetch,
  toast,
}) => {
  const [videoVisible, setVideoVisible] = useState(false);
  const [file, setFile] = useState("");
  const [productsImages, setProductsImages] = useState([]);

  const dispatch = useDispatch();

  const { updateProductLoading } = useSelector((state) => state.products);

  const categoryOptions = [
    { name: "Misc.", code: "Misc." },
    { name: "Palm Tree", code: "Palm Tree" },
    { name: "Tropical Plant", code: "Tropical Plant" },
    { name: "Pots", code: "Pots" },
    { name: "Tiki Bar", code: "Tiki Bar" },
    { name: "Service", code: "Service" },
    { name: "Frames", code: "Frames" },
    { name: "Bracing", code: "Bracing" },
  ];

  const productTypeArray = [
    "7 Gallon",
    "15 Gallon",
    "25 Gallon",
    "Areca",
    "Auntie Lou",
    "Black Magic",
    "Bottle",
    "Chinese Fan",
    "Christmas Palm",
    "Coconut",
    "Croton",
    "Elephant Ear",
    "Fertilizer",
    "Musa Bajoo",
    "Queen Emma",
    "Red Banana",
    "Red Sister",
    "Robelilin",
    "Sago",
    "Tarp",
    "Windmill",
    "Yucca",
    "Marginata",
    "Cat",
    "Hibiscus Bush",
    "Bismarck",
    "Lady Palm",
    "Special",
    "Big Black",
    "Multi Spindle",
    "Tiki Bar Rental",
    "Foxtail",
    "Tree Rental",
    "45 Gallon",
    "Spindle",
    "mex",
    "Mexican Pertunia",
    "Majesty",
    "Wrapping Kit",
    "Treatment",
    "Bracing",
    "Washingtonia",
    "delivery",
    "planting",
    "square plastic",
    "Canary",
    "Steel",
    "Powder Coat",
  ];

  const productsTypeOptions = productTypeArray.map((item) => {
    return { name: item, code: item };
  });

  const sizeVariation = [
    "7 Gallon - Single",
    "7 Gallon - Double",
    "7 Gallon - Triple",
    "15 Gallon - Single",
    "15 Gallon - Double",
    "15 Gallon - Triple",
    "25 Gallon - Single",
    "25 Gallon - Double",
    "25 Gallon - Triple",
    "25 Gallon - Single XL",
    "25 Gallon - Double XL",
    "25 Gallon - Triple XL",
    "Field Grown - Double",
    "Field Grown - Triple",
    "7 Gallon",
    "15 Gallon",
    "25 Gallon",
    "25 Gallon XL",
    "3 Gallon",
    "4 Foot",
    "5 Foot",
    "6 Foot",
    "7 Foot",
    "8 Foot",
    "9 Foot",
    "10 Foot",
    "11 Foot",
    "12 Foot",
    "13 Foot",
    "14 Foot",
    "Rostrata 1.00 Inch",
    "Faxon 1.00 Inch",
    "Regular",
    "3 Gallon - Calidora",
    "3 Gallon - Odora",
    "3 Gallon - Portora",
    "7 Gallon - Calidora",
    "7 Gallon - Odora",
    "7 Gallon - Portora",
    "15 Gallon - Calidora",
    "15 Gallon - Odora",
    "15 Gallon - Portora",
    "3 Gallon - Mammey",
    "3 Gallon - Mango",
    "3 Gallon - Petra",
    "7 Gallon - Mammey",
    "7 Gallon - Mango",
    "7 Gallon - Petra",
    "White",
    "Black",
    "Brown",
    "Gray",
    "Tan",
    "7 Gallon - Single",
    "7 Gallon - Double",
    "7 Gallon - Triple",
    "15 Gallon - Single",
    "15 Gallon - Double",
    "15 Gallon - Triple",
    "25 Gallon - Single",
    "25 Gallon - Double",
    "25 Gallon - Triple",
    "25 Gallon - Single XL",
    "25 Gallon - Double XL",
    "25 Gallon - Triple XL",
    "45 Gallon - Single",
    "Field Grown - Double",
    "Field Grown - Triple",
    "7 Gallon - Single XL",
    "7 Gallon - Double XL",
    "3 Gallon - Yello Spot",
    "3 ft Trunk",
    "4 ft Trunk",
    "Waggie",
    "Plastic",
    "Plastic with Wood",
    "7 FT WAGGIE",
    "5 FT WAGGIE",
    "6 FT WAGGIE",
    "7 FT WAGGIE",
    "8 FT WAGGIE",
    "9 FT WAGGIE",
    "10 FT WAGGIE",
    "45 Gallon - Double",
    "45",
    "45 Gallon - Triple",
    "Wood",
    "Mailed",
    "First Tree Treatment",
    "Additional Tree Treatment",
    "$75",
    "$150",
    "Field Grown 8ft ct",
    "Field Grown -10ft CT",
    "Field Grown -XL Double",
    "Field Grown",
    "Field Grown TPL Stem 12ft",
    "Smaller Triple",
    "field grown 12ft double",
    "15 Gallon Round",
    "25 Gallon Round",
    "45 Gallon Round",
    "15 Gallon Square",
    "25 Gallon Square",
    "45 Gallon Square",
    "15 Gallon Tappered",
    "25 Gallon Tappered",
    "45 Gallon Tappered",
    "45 Gallon",
    "100g",
  ];

  const sizeVariationOption = sizeVariation.map((item) => {
    return { name: item, code: item };
  });

  const { data, isLoading, error } = useGetProductQuery(editProductId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (data) {
      setProductsImages(data?.data?.photo);
    } else {
      setProductsImages([]);
    }
  }, [data]);

  const onSubmit = (data) => {
    const obj = {
      id: editProductId,
      category: data?.category.name,
      product_type: data?.product_type.name,
      size_variation: data?.size_variation.name,
      price: data?.price,
      product_name: `${data?.category.name} - ${data?.product_type.name} - ${data?.size_variation.name}`,
      photo: productsImages,
    };
    dispatch(updateProduct(obj)).then((res) => {
      if (res?.payload?.status === 200) {
        refetch();
        setEditProductVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const onUpload = (e) => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });

    const response = JSON.parse(e.xhr.response);
    const imageUrl = response.data;

    setProductsImages((prevImages) => {
      // Additional Debug log
      if (!Array.isArray(prevImages)) {
        console.error("prevImages is not an array:", prevImages);
        return [imageUrl]; // Return a new array with the image
      }
      return [...prevImages, imageUrl];
    });
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  useEffect(() => {
    if (data) {
      reset({
        category: {
          name: data?.data?.category,
          code: data?.data?.category,
        },
        product_type: {
          name: data?.data?.product_type,
          code: data?.data?.product_type,
        },
        size_variation: {
          name: data?.data?.size_variation,
          code: data?.data?.size_variation,
        },
        price: data?.data?.price,
      });
    }
  }, [data, reset]);

  return (
    <Dialog
      header="Edit Product"
      visible={editProductVisible}
      onHide={() => setEditProductVisible(false)}
      style={{ width: "50vw" }}
    >
      <VideoPlayerDialog
        file={file}
        videoVisible={videoVisible}
        setVideoVisible={setVideoVisible}
      />
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="my-8">
          <Controller
            name="category"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-lg text-bold`}
                >
                  Category
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    filter
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={categoryOptions}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="product_type"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-lg text-bold`}
                >
                  Product Type
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    filter
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={productsTypeOptions}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="size_variation"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-lg text-bold`}
                >
                  Size / Variation
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    filter
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={sizeVariationOption}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="price"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-lg text-bold`}
                >
                  Price
                </label>
                <span>
                  <InputNumber
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    inputId="currency-us"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    min={"1"}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <div className="my-4 p-field">
            <label htmlFor="productsImages">Photos</label>
            <FileUpload
              id="productsImages"
              mode="basic"
              name="img"

              url={`${apiUrl}media/api/upload`}

              accept="image/*, video/*"
              maxFileSize={35000000}
              onUpload={onUpload}
              chooseLabel="Drop here"
              auto
            />
            <div className="flex flex-wrap gap-4 my-4">
              {productsImages?.map((file) => {
                const extension = file.split(".").pop().toLowerCase(); // Get the file extension
                if (
                  extension === "png" ||
                  extension === "jpg" ||
                  extension === "jpeg" ||
                  extension === "gif"
                ) {
                  return (
                    <Image
                      key={file}
                      src={file}
                      zoomSrc={file}
                      alt="Image"
                      width="80"
                      height="60"
                      preview
                      imageClassName="w-20 h-20"
                    />
                  );
                } else if (extension === "mp4" || extension === "mov") {
                  return (
                    <div>
                      <Button
                        icon="pi pi-play"
                        className="w-20 h-20"
                        onClick={(e) => {
                          e.preventDefault();
                          setVideoVisible(true);
                          setFile(file);
                        }}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Button
              label={updateProductLoading ? "" : "Submit"}
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              type="submit"
              icon={
                updateProductLoading ? "pi pi-spin pi-spinner" : "pi pi-plus"
              }
            />
          </div>
        </form>
      )}
      {error && (
        <div className="flex items-center justify-center w-full h-full">
          <p>{error.message}</p>
        </div>
      )}
    </Dialog>
  );
};

export default EditProduct;
