import { Dialog } from "primereact/dialog";

import { useGetCommunicationOfCustomersQuery } from "../../services/customer/customerServices";
import { convertToAmericanDate } from "../../utils/convertToAmericanDate";

const CommunicationDialog = ({ visible, setVisible, customerId }) => {
  const {
    data: communicationsDataOfCustomer,
    isLoading: filteredCustomersLoading,
    error,
  } = useGetCommunicationOfCustomersQuery(customerId, {
    refetchOnMountOrArgChange: true,
  });

  // const [displayData, setDisplayData] = useState(null);

  // useEffect(() => {
  //   if (!isFetching) {
  //     setDisplayData(communicationsDataOfCustomer);
  //   }
  // }, [communicationsDataOfCustomer, isFetching]);

  // useEffect(() => {
  //   if (!visible) {
  //     setDisplayData(null);
  //   }
  // }, [visible, customerId]);

  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    <Dialog
      header="Customer Communication"
      visible={visible}
      onHide={() => setVisible(false)}
      style={{ width: "50vw" }}
      modal
    >
      {filteredCustomersLoading && (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      )}
      {error ? (
        <div className="flex items-center justify-center w-full h-full">
          <p>{error.data.message}</p>
        </div>
      ) : (
        <>
          {communicationsDataOfCustomer?.data &&
            communicationsDataOfCustomer.data.map((data, index) => (
              <div
                key={index}
                style={{ padding: "10px", borderBottom: "1px solid #ccc" }}
              >
                <p>
                  <strong>Method:</strong> {data.communication_method}
                </p>
                <p>
                  <strong>Direction:</strong> {data.direction}
                </p>
                <p>
                  <strong>Content:</strong>{" "}
                  <span dangerouslySetInnerHTML={createMarkup(data.content)} />
                </p>
                <p>
                  <strong>Date Created:</strong>{" "}
                  {convertToAmericanDate(data.created_at)}
                </p>
                <p>
                  <strong>Last Updated:</strong>{" "}
                  {convertToAmericanDate(data.updated_at)}
                </p>
              </div>
            ))}
        </>
      )}
    </Dialog>
  );
};

export default CommunicationDialog;
