import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../../utils/apiURL";

export const orderApi = createApi({
  reducerPath: "orderApi",
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({

    baseUrl: apiUrl,

    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
        ? getState().auth.token
        : getState().auth.technicianToken;
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllOrders: builder.query({
      query: () => `orders/?exclude_type=Service`,
    }),
    getOrder: builder.query({ query: (id) => `orders/${id}` }),
  }),
});

export const { useGetAllOrdersQuery, useGetOrderQuery } = orderApi;
