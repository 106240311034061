import { createAsyncThunk } from "@reduxjs/toolkit";

import { instance } from "../../axios/axios";

export const addTreeConditions = createAsyncThunk(
  "treeConditions/create",
  async (
    {
      customer_id,
      treatment_operation_id,
      tree_number,
      tree_condition,
      type_of_cover,
      cover_size,
      frame_size,
      is_extension_next_year,
      type_of_extension,
      note,
      tree_pictures,
    },
    { rejectWithValue, getState }
  ) => {
    const token = getState().auth.token
    ? getState().auth.token
    : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const { data, status } = await instance.post(
        `techReps`,
        {
          customer_id,
          treatment_operation_id,
          tree_number,
          tree_condition,
          type_of_cover,
          cover_size,
          frame_size,
          is_extension_next_year,
          type_of_extension,
          note,
          tree_pictures,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const editTreeConditions = createAsyncThunk(
  "treeConditions/edit",
  async (
    {
      tree_id,
      tree_condition,
      type_of_cover,
      cover_size,
      frame_size,
      is_extension_next_year,
      type_of_extension,
      note,
      tree_pictures,
    },
    { rejectWithValue, getState }
  ) => {
    const token = getState().auth.token
    ? getState().auth.token
    : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status } = await instance.patch(
        `techReps/${tree_id}`,
        {
          tree_condition,
          type_of_cover,
          cover_size,
          frame_size,
          is_extension_next_year,
          type_of_extension,
          note,
          tree_pictures,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteTreeCondition = createAsyncThunk(
  "treeCondition/delete",
  async ({ tree_id }, { rejectWithValue, getState }) => {
    const token = getState().auth.token
    ? getState().auth.token
    : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status } = await instance.delete(
        `techReps/${tree_id}`,
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
