// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../../utils/apiURL";

export const treatmentApi = createApi({
  reducerPath: "treatmentApi",
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({

    baseUrl: apiUrl,

    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllTreatment: builder.query({ query: () => `treatments` }),
    getTreatment: builder.query({ query: (id) => `treatments/${id}` }),
  }),
});

export const { useGetAllTreatmentQuery, useGetTreatmentQuery } = treatmentApi;
