import { Dialog } from "primereact/dialog";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useState } from "react";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
// import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { updateTechnician } from "../../features/technician/technicanActions";
import { instance } from "../../axios/axios";

const EditDriver = ({
  visible,
  setVisible,
  toast,
  refetch,
  editedTechnicianId,
}) => {
  const [technicianData, setTechnicianData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchData = async (id) => {
      setIsLoading(true);
      setError(null);
      if (id) {
        try {
          const response = await instance.get(`/technicians/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Attach the token to the Authorization header
            },
          });
          setTechnicianData(response.data.data);
        } catch (error) {
          console.error("Error fetching treatment data:", error);
          // Handle error
          setError(error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData(editedTechnicianId);
  }, [editedTechnicianId, token]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  // const role = [
  //   { name: "Technician", code: "Technician" },
  //   { name: "Driver", code: "Driver" },
  // ];

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      phone,
      address,
      email,
      password,
      role,
      active,
      username,
    } = data;

    const obj = {
      id: editedTechnicianId,
      first_name: firstName,
      last_name: lastName,
      username: username,
      email: email,
      password: password,
      is_active: active,
      role: role,
      phone: phone,
      full_address: address,
    };

    dispatch(updateTechnician(obj)).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        setVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
    reset();
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  useEffect(() => {
    if (technicianData) {
      reset({
        firstName: technicianData.first_name || "",
        lastName: technicianData.last_name || "",
        username: technicianData.username || "",
        email: technicianData.email || "",
        active: technicianData.is_active,
        phone: technicianData.phone || "",
        address: technicianData.full_address || "",
        role: { name: technicianData.role, code: technicianData.role } || {},
      });
    }
  }, [reset, technicianData]);

  return (
    <Dialog
      header="Edit Technician"
      visible={visible}
      style={{ width: "50vw" }}
      onHide={() => setVisible(false)}
    >
      {isLoading && (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      )}
      {error && (
        <div className="flex items-center justify-center w-full h-full">
          <p>{error.message}</p>
        </div>
      )}
      {technicianData && (
        <form onSubmit={handleSubmit(onSubmit)} className="my-8">
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="firstName"
              control={control}
              rules={{ required: "First Name is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    First Name
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="First Name"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="lastName"
              control={control}
              rules={{ required: "last Name is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Last Name
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Last Name"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="phone"
              control={control}
              rules={{ required: "Phone is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Phone
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Phone"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="address"
              control={control}
              rules={{ required: "Address is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Address
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Address"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="email"
              control={control}
              rules={{ required: "Email is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Email
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Email"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-between w-full gap-2">
            <Controller
              name="username"
              control={control}
              rules={{ required: "Username is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Username
                  </label>
                  <span className="p-float-label">
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                      placeholder="Username"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </div>
          <div className="flex">
            <Controller
              name="active"
              control={control}
              rules={{ required: "Active is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col w-1/2">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Active
                  </label>
                  <div className="flex gap-4 my-4 flex-wrap">
                    <div className="flex">
                      <RadioButton
                        inputId="active"
                        name="category"
                        value={"active"}
                        checked={
                          field.value === true || field.value === "active"
                        }
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        inputRef={field.ref}
                      />
                      <label htmlFor="active" className="ml-1 mr-3">
                        active
                      </label>
                    </div>
                    <div className="flex">
                      <RadioButton
                        inputId="inactive"
                        name="category"
                        value={"inactive"}
                        onChange={(e) => field.onChange(e.target.value)}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} mr-1`}
                        checked={
                          field.value === false || field.value === "inactive"
                        }
                      />
                      {console.log({ active: field.value })}
                      <label htmlFor="inactive" className="ml-1 mr-3">
                        inactive
                      </label>
                    </div>
                  </div>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </div>
          <div className="flex items-center justify-center">
            <Button
              type="submit"
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              label="Submit"
            />
          </div>
        </form>
      )}
    </Dialog>
  );
};

export default EditDriver;
