// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../../utils/apiURL";

export const customerApi = createApi({
  reducerPath: "customerApi",
  baseQuery: fetchBaseQuery({

    baseUrl: apiUrl,

    keepUnusedDataFor: 0,
    refetchOnMountOrArgChange: true,


    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      const tokenTechnician = getState().auth.technicianToken;
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
      } else if (tokenTechnician) {
        headers.set("authorization", `Bearer ${tokenTechnician}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllCustomer: builder.query({ query: () => `customers` }),
    getCustomer: builder.query({ query: (id) => `customers/${id}` }),
    getFilteredCustomers: builder.query({
      query: () => `customers/filter/send-form`,
    }),
    getCustomersContactAgain: builder.query({
      query: () => `customers/filter/contact-again`,
    }),
    getCustomersByProduct: builder.query({
      query: (id) => `customers/filter/product/${id}`,
    }),
    getCommunicationOfCustomers: builder.query({
      query: (id) => `customers/${id}/communications`,
    }),
    getOldCommunicationOfCustomers: builder.query({
      query: (id) => `customers/${id}/old-comm`,
    }),
  }),
});

export const {
  useGetAllCustomerQuery,
  useGetCustomerQuery,
  useGetFilteredCustomersQuery,
  useGetCustomersContactAgainQuery,
  useGetCustomersByProductQuery,
  useGetCommunicationOfCustomersQuery,
  useGetOldCommunicationOfCustomersQuery,
} = customerApi;
