import { createSlice } from "@reduxjs/toolkit";
import { addProduct, updateProduct } from "./productActions";

const initialState = {
  addProductLoading: false,
  updateProductLoading: false,
};

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addProduct.pending, (state) => {
        state.addProductLoading = true;
      })
      .addCase(addProduct.fulfilled, (state) => {
        state.addProductLoading = false;
      })
      .addCase(addProduct.rejected, (state) => {
        state.addProductLoading = false;
      })
      .addCase(updateProduct.pending, (state) => {
        state.updateProductLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state) => {
        state.updateProductLoading = false;
      })
      .addCase(updateProduct.rejected, (state) => {
        state.updateProductLoading = false;
      });
  },
});

export default productsSlice.reducer;
