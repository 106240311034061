import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
// import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";
import { Password } from "primereact/password";
import { useDispatch } from "react-redux";
import { useRef } from "react";

import { addTechnician } from "../../features/technician/technicanActions";

const AddDriver = ({ visible, setVisible, toast, refetch }) => {
  const dispatch = useDispatch();
  const defaultValues = {
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    active: "",
    phone: "",
    address: "",
    confirmPassword: "",
    role: "technician",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues });

  const password = useRef({});
  password.current = watch("password", "");

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onSubmit = (data) => {
    const {
      firstName,
      lastName,
      phone,
      address,
      email,
      password,
      active,
      username,
    } = data;

    const obj = {
      first_name: firstName,
      last_name: lastName,
      username: username,
      email: email,
      password: password,
      is_active: active,
      phone: phone,
      full_address: address,
      role: "technician",
    };

    dispatch(addTechnician(obj)).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        reset();
        setVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <Dialog
      header="Add Driver/Technician"
      visible={visible}
      style={{ width: "50vw" }}
      onHide={() => setVisible(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="my-8">
        <div className="flex items-center justify-between w-full gap-2">
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "First Name is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  First Name
                </label>
                <span className="p-float-label">
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="First Name"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: "last Name is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Last Name
                </label>
                <span className="p-float-label">
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Last Name"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
        </div>
        <div className="flex items-center justify-between w-full gap-2">
          <Controller
            name="phone"
            control={control}
            rules={{ required: "Phone is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Phone
                </label>
                <span className="p-float-label">
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Phone"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="address"
            control={control}
            rules={{ required: "Address is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Full Address
                </label>
                <span className="p-float-label">
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Address"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
        </div>
        <div className="flex items-center justify-between w-full gap-2">
          <Controller
            name="email"
            control={control}
            rules={{ required: "Email is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Email
                </label>
                <span className="p-float-label">
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Email"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="username"
            control={control}
            rules={{ required: "Username is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Username
                </label>
                <span className="p-float-label">
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Username"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
        </div>
        <div className="flex items-center justify-between w-full gap-2">
          <Controller
            name="password"
            control={control}
            rules={{ required: "Password is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Password
                </label>
                <span className="p-float-label">
                  <Password
                    id={field.name}
                    toggleMask
                    feedback={false}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Password"
                    inputClassName="w-full rounded-xl"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: "Confirm Password is required.",
              validate: (value) =>
                value === password.current || "The passwords do not match",
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Confirm Password
                </label>
                <span className="p-float-label">
                  <Password
                    id={field.name}
                    toggleMask
                    feedback={false}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="Password"
                    inputClassName="w-full rounded-xl"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
        </div>
        <div className="flex items-center justify-between w-full gap-2">
          <Controller
            name="active"
            control={control}
            rules={{ required: "Active is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Active
                </label>
                <div className="flex gap-4 my-4 flex-wrap">
                  <div className="flex">
                    <RadioButton
                      inputId="Yes"
                      name="category"
                      value={"active"}
                      checked={field.value === "active"}
                      onChange={(e) => field.onChange(e.target.value)}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} mr-1`}
                      inputRef={field.ref}
                    />
                    <label htmlFor="Yes" className="ml-1 mr-3">
                      Yes
                    </label>
                  </div>
                  <div className="flex">
                    <RadioButton
                      inputId="No"
                      name="category"
                      value={"inactive"}
                      onChange={(e) => field.onChange(e.target.value)}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} mr-1`}
                      checked={field.value === "inactive"}
                    />
                    <label htmlFor="No" className="ml-1 mr-3">
                      No
                    </label>
                  </div>
                </div>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
        </div>
        <div className="flex items-center justify-center">
          <Button
            type="submit"
            className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
            label="Submit"
          />
        </div>
      </form>
    </Dialog>
  );
};

export default AddDriver;
