import { createSlice } from "@reduxjs/toolkit";

import {
  addTreatment,
  sendTreatment,
  updateTreatment,
  wantTreatment,
} from "./treatmentActions";

const initialState = {
  addLoading: false,
  updateLoading: false,
  wantTreatmentTreatment: false,
  sendTreatmentLoading: false,
};

const treatmentSlice = createSlice({
  name: "treatment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTreatment.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addTreatment.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addTreatment.rejected, (state) => {
        state.addLoading = false;
      })
      .addCase(wantTreatment.pending, (state) => {
        state.wantTreatmentTreatment = true;
      })
      .addCase(wantTreatment.fulfilled, (state) => {
        state.wantTreatmentTreatment = false;
      })
      .addCase(wantTreatment.rejected, (state) => {
        state.wantTreatmentTreatment = false;
      })
      .addCase(updateTreatment.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateTreatment.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateTreatment.rejected, (state) => {
        state.updateLoading = false;
      })
      .addCase(sendTreatment.pending, (state) => {
        state.sendTreatmentLoading = true;
      })
      .addCase(sendTreatment.fulfilled, (state) => {
        state.sendTreatmentLoading = false;
      })
      .addCase(sendTreatment.rejected, (state) => {
        state.sendTreatmentLoading = false;
      });
  },
});

export default treatmentSlice.reducer;
