// app/services/auth/authService.js
// React-specific entry point to allow generating React hooks
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../../utils/apiURL";

export const treeConditionsApi = createApi({
  reducerPath: "treeConditionsApi",
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({

    baseUrl: apiUrl,

    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token
    ? getState().auth.token
    : getState().auth.technicianToken;
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllTreeConditions: builder.query({ query: () => `techReps` }),
    getTreeConditions: builder.query({ query: (id) => `techReps/${id}` }),
  }),
});

export const { useGetAllTreeConditionsQuery, useGetTreeConditionsQuery } =
  treeConditionsApi;
