import { createSlice } from "@reduxjs/toolkit";
import {
  addOrder,
  addOrderLineInOrders,
  sendOrderEmail,
  updateOrder,
} from "./orderActions";

const initialState = {
  addLoading: false,
  updateLoading: false,
  updateAddOrderLineLoading: false,
  sendMail: false,
};

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addOrder.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addOrder.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addOrder.rejected, (state) => {
        state.addLoading = false;
      })
      .addCase(updateOrder.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateOrder.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateOrder.rejected, (state) => {
        state.updateLoading = false;
      })
      .addCase(addOrderLineInOrders.pending, (state) => {
        state.updateAddOrderLineLoading = true;
      })
      .addCase(addOrderLineInOrders.fulfilled, (state) => {
        state.updateAddOrderLineLoading = false;
      })
      .addCase(addOrderLineInOrders.rejected, (state) => {
        state.updateAddOrderLineLoading = false;
      })
      .addCase(sendOrderEmail.pending, (state) => {
        state.sendMail = true;
      })
      .addCase(sendOrderEmail.fulfilled, (state) => {
        state.sendMail = false;
      })
      .addCase(sendOrderEmail.rejected, (state) => {
        state.sendMail = false;
      });
  },
});

export default ordersSlice.reducer;
