import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

import { useGetAllAdminQuery } from "../../services/admin/adminServices";
import { deleteAdmin } from "../../features/admin/adminActions";

import AddAdmin from "./AddAdmin";
import EditAdmin from "./EditAdmin";

const AdminsTable = () => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [editAdminId, setEditAdminId] = useState(null);
  const [editAdminVisible, setEditAdminVisible] = useState(false);

  const { token } = useSelector((state) => state.auth);

  const decodedToken = jwtDecode(token);

  const toast = useRef(null);

  const dispatch = useDispatch();

  const { data, error, isLoading, refetch } = useGetAllAdminQuery(
    "adminsDetails",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
  };

  const header = () => {
    return (
      <div className="flex items-center justify-between bg-none">
        <div className="flex">
          <span className="mr-2 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm rounded-xl"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
        {(decodedToken?.role === "superadmin" ||
          decodedToken?.role === "admin") && (
          <div className="flex">
            <div>
              <Button
                icon="pi pi-plus"
                label="Add Admin"
                className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
                size="small"
                onClick={() => setVisible(true)}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const onEdit = (id) => {
    setEditAdminId(id);
    setEditAdminVisible(true);
  };

  const removeAdmin = (id) => {
    dispatch(deleteAdmin({ admin_id: id })).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };

  const confirm2 = (id) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => removeAdmin(id),
      reject,
    });
  };

  const activeBodyTemplate = (rowData) => {
    return (
      <div className={`py-4`}>
        <i
          className={`${classNames("pi", {
            "true-icon pi-check-circle text-blue-600":
              rowData.is_active === "active",
            "false-icon pi-times-circle text-red-500":
              rowData.is_active === "inactive",
          })}`}
        ></i>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    const { id } = rowData;
    return (
      <div className="flex items-center justify-around">
        <Button
          icon="pi pi-pencil"
          rounded
          text
          size="small"
          onClick={() => onEdit(id)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          size="small"
          onClick={() => confirm2(id)}
        />
      </div>
    );
  };

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p>{error?.error}</p>
      </div>
    );
  }

  return (
    <div>
      <Toast ref={toast} />
      <ConfirmDialog />
      <AddAdmin
        visible={visible}
        setVisible={setVisible}
        refetch={refetch}
        toast={toast}
      />
      {editAdminId && (
        <EditAdmin
          editAdminId={editAdminId}
          editAdminVisible={editAdminVisible}
          setEditAdminVisible={setEditAdminVisible}
          refetch={refetch}
          toast={toast}
        />
      )}
      <DataTable
        value={data?.data}
        dataKey="id"
        scrollable
        scrollHeight="68vh"
        loading={isLoading}
        header={header}
        globalFilter={globalFilterValue}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column field="first_name" header="First Name" />
        <Column field="last_name" header="Last Name" />
        <Column field="email" header="Email" />
        <Column field="phone" header="Phone" />
        <Column field="is_active" header="Active" body={activeBodyTemplate} />
        <Column field="full_address" header="Address" />
        {(decodedToken?.role === "superadmin" ||
          decodedToken?.role === "admin") && (
          <Column header="Actions" body={actionBodyTemplate} />
        )}
      </DataTable>
    </div>
  );
};

export default AdminsTable;
