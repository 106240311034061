import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import { useMediaQuery } from "react-responsive";
import { jwtDecode } from "jwt-decode";

import styles from "./ClientTreatmentTable.module.css";

import { useGetOperationQuery } from "../../services/operation/operationServices";
import OperationDialogDetails from "./OperationDialogDetails";
import EditNoteDialog from "./EditNoteDialog";
import { useGetAllTechniciansQuery } from "../../services/technician/technicianServices";
import { assignTechnician } from "../../features/operation/operationActions";
import { getFormErrorMessage } from "../../utils/getFormErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import ViewCommunication from "../Customer/ViewCommunication";
import {
  useGetCommunicationOfCustomersQuery,
  useGetOldCommunicationOfCustomersQuery,
} from "../../services/customer/customerServices";

export const BigContainer = ({ titleOne, titleTwo, valueOne, valueTwo }) => (
  <div className={styles.detailsContainer}>
    <div className={styles.detailsContainerDiv}>
      <h3 className={styles.detailsTitle}>{titleOne}</h3>
      <p className={styles.detailsContent}>{valueOne}</p>
    </div>
    <div className={styles.detailsContainerDiv}>
      <h3 className={styles.detailsTitle}>{titleTwo}</h3>
      <p className={styles.detailsContent}>{valueTwo}</p>
    </div>
  </div>
);

export const ImagesSection = ({
  imgsArr,
  imgTitle,
  setVideoVisible,
  setFile,
}) => (
  <div>
    <h3 className={styles.detailsTitle}>{imgTitle}</h3>
    <div className="flex flex-row flex-wrap gap-4 my-2">
      {imgsArr?.map((file, idx) => {
        const extension = file.split(".").pop().toLowerCase();
        if (
          extension === "png" ||
          extension === "jpg" ||
          extension === "jpeg" ||
          extension === "gif"
        ) {
          return (
            <Image
              key={idx}
              src={file}
              zoomSrc={file}
              alt="Image"
              width="80"
              height="60"
              preview
              imageClassName="w-20 h-20"
            />
          );
        } else if (extension === "mp4" || extension === "mov") {
          return (
            <Button
              icon="pi pi-play"
              className="w-20 h-20"
              onClick={(e) => {
                e.preventDefault();
                setVideoVisible(true);
                setFile(file);
              }}
              key={idx}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  </div>
);

const OperationDialog = ({
  visible,
  setVisible,
  id,
  setId,
  lat,
  lng,
  refetch,
}) => {
  const [page, setPage] = useState("operationDetails");
  const [assignVisible, setAssignVisible] = useState(false);
  const [customerId, setCustomerId] = useState("");

  const { token, technicianToken } = useSelector((state) => state.auth);

  const decodedToken = jwtDecode(token ? token : technicianToken);

  const {
    data,
    isLoading,
    error,
    refetch: CardRefetch,
  } = useGetOperationQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const isBase64 = (str) => {
    try {
      // This checks if the string can be decoded without errors
      return btoa(atob(str)) === str;
    } catch (err) {
      return false;
    }
  };

  let latitude, longitude;

  if (data?.data?.customer?.geo_code) {
    const geoCode = data.data.customer.geo_code;

    try {
      if (isBase64(geoCode)) {
        // Decode the Base64 string
        const decodedString = atob(geoCode);
        // Parse the decoded JSON string
        const parsedData = JSON.parse(decodedString);

        // Extract latitude and longitude
        latitude = parsedData.o.lat;
        longitude = parsedData.o.lng;
      } else {
        // If not Base64, directly parse as JSON
        const parsedData = JSON.parse(geoCode);
        latitude = parsedData.o.lat;
        longitude = parsedData.o.lng;
      }
    } catch (error) {
      console.error("Failed to decode and parse geo_code:", error);
    }
  }

  useEffect(() => {
    setCustomerId(data?.data?.customer?.id);
  }, [data]);

  const {
    data: communicationsDataOfCustomer,
    isLoading: communicationsOfCustomersLoading,
    error: communicationsOfCustomersError,
  } = useGetCommunicationOfCustomersQuery(customerId, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: oldCommunicationsDataOfCustomer,
    isLoading: oldCommunicationsOfCustomersLoading,
    error: oldCommunicationsOfCustomersError,
  } = useGetOldCommunicationOfCustomersQuery(customerId, {
    refetchOnMountOrArgChange: true,
  });

  const toast = useRef(null);

  let operationData = data && data?.data;

  const dispatch = useDispatch();

  const { assignTechnician: assignTechnicianState } = useSelector(
    (state) => state.operation
  );

  const defaultValues = {
    technicianId: "",
  };

  const handleCallButtonClick = (number) => {
    window.location.href = `tel:${number}`;
  };

  function goToGoogleMaps(lat, lng) {
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${lat || latitude},${
        lng || longitude
      }`,
      "_blank"
    );
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const { data: techniciansData, isLoading: techniciansLoading } =
    useGetAllTechniciansQuery("TechniciansDetails", {
      refetchOnMountOrArgChange: true,
    });

  const onSubmit = (data) => {
    const obj = {
      technicianId: data.technicianId.code,
      operationId: operationData?.id,
    };
    dispatch(assignTechnician(obj)).then((res) => {
      if (res.payload.status === 200) {
        CardRefetch();
        setAssignVisible(false);
        reset();
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Dialog
      visible={visible}
      onHide={() => {
        setVisible(false);
        setId(null);
        refetch();
      }}
      header="Operation"
      style={{ width: "80vw" }}
    >
      <Dialog
        header="Assign a technician"
        visible={assignVisible}
        style={
          isDesktop ? { width: "35vw" } : isMobile ? { width: "90vw" } : {}
        }
        onHide={() => setAssignVisible(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="my-8">
          <Controller
            name="technicianId"
            control={control}
            rules={{ required: "Technician is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Select a technician:
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={
                      techniciansData
                        ? techniciansData.data.map((technician) => ({
                            name: technician.full_name,
                            code: technician.id,
                          }))
                        : []
                    }
                    loading={techniciansLoading}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    placeholder="Select a Technician"
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <div className="flex items-center justify-center">
            <Button
              label={"Submit"}
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              type="submit"
              icon={"pi pi-check"}
              loading={assignTechnicianState}
            />
          </div>
        </form>
      </Dialog>
      <Toast ref={toast} />
      <div className="flex flex-col items-center justify-between md:flex-row">
        <div>
          {isLoading ? (
            <div className="flex items-center justify-center w-full h-full">
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>
            </div>
          ) : (
            <div>
              <h3 className="text-xl font-bold text-center md:mr-3">
                {operationData?.total_number_of_trees} -{" "}
                {operationData?.customer?.full_name}
              </h3>
              <p>
                <span className="font-bold">Phone no / </span>
                {operationData?.customer?.phone}
                <span>
                  <Button
                    icon="pi pi-phone"
                    severity="success"
                    className="h-5 ml-5"
                    onClick={() =>
                      handleCallButtonClick(operationData?.customer?.phone)
                    }
                  />
                </span>
              </p>
            </div>
          )}
        </div>
        <div className="flex flex-col items-center md:flex-row">
          <Button
            icon="pi pi-list"
            size="small"
            label="Details"
            text
            rounded
            onClick={() => setPage("operationDetails")}
          />
          <Button
            severity="info"
            size="small"
            text
            rounded
            label="Communications"
            icon="pi pi-comments"
            onClick={() => setPage("ViewCommunications")}
          />
          {(decodedToken?.role === "superadmin" ||
            decodedToken?.role === "admin") && (
            <Button
              label="Assign Technician"
              icon="pi pi-truck"
              text
              size="small"
              onClick={() => setAssignVisible(true)}
            />
          )}
          <Button
            label="Add Note"
            icon="pi pi-plus"
            text
            size="small"
            onClick={() => setPage("addNote")}
          />
        </div>
      </div>
      {latitude && longitude ? (
        <>
          <div className="flex justify-end">
            <div
              className="flex justify-center cursor-pointer md:w-[15%] w-full my-2 h-7 text-xl bg-[#C4E1D8] rounded-lg text-[#0D6047]"
              onClick={() => {
                goToGoogleMaps(lat, lng);
              }}
            >
              Map
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {page === "operationDetails" && (
        <OperationDialogDetails
          operationData={operationData}
          error={error}
          isLoading={isLoading}
          refetch={CardRefetch}
        />
      )}
      {page === "addNote" && (
        <EditNoteDialog
          isLoading={isLoading}
          operationData={operationData}
          operationsRefetch={CardRefetch}
          setPage={setPage}
          toast={toast}
          refetch={CardRefetch}
          operationTableRefetch={refetch}
        />
      )}
      {page === "ViewCommunications" && (
        <ViewCommunication
          communicationsDataOfCustomer={communicationsDataOfCustomer}
          communicationsOfCustomersError={communicationsOfCustomersError}
          communicationsOfCustomersLoading={communicationsOfCustomersLoading}
          oldCommunicationsDataOfCustomer={oldCommunicationsDataOfCustomer}
          oldCommunicationsOfCustomersError={oldCommunicationsOfCustomersError}
          oldCommunicationsOfCustomersLoading={
            oldCommunicationsOfCustomersLoading
          }
        />
      )}
    </Dialog>
  );
};

export default OperationDialog;
