import { Dialog } from "primereact/dialog";
import { useGetTreeConditionsQuery } from "../../services/TreeConditions/treeConditionsServices";
import { useEffect, useState } from "react";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import VideoPlayerDialog from "./VideoPlayerDialog";

const ShowTreeDetails = ({ showTreeDetails, setShowTreeDetails, treeId }) => {
  const [videoVisible, setVideoVisible] = useState(false);
  const [file, setFile] = useState("");

  const { data, isLoading, error, refetch } = useGetTreeConditionsQuery(
    treeId,
    {
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    refetch();
  }, [refetch, treeId]);

  return (
    <Dialog
      header="Tree Details"
      visible={showTreeDetails}
      onHide={() => setShowTreeDetails(false)}
      style={{ width: "30vw" }}
    >
      <VideoPlayerDialog
        file={file}
        videoVisible={videoVisible}
        setVideoVisible={setVideoVisible}
      />
      {error && (
        <div className="flex items-center justify-center w-full h-full">
          <p>{error.message}</p>
        </div>
      )}
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <div>
          <div className="my-4">
            <h3 className="text-lg font-bold">Tree Number</h3>
            <p>{data?.data?.tree_number}</p>
          </div>
          <div className="my-4">
            <h3 className="text-lg font-bold">Tree Picture</h3>
            <div className="flex gap-4 my-4 flex-wrap">
              {/* {data?.data?.tree_pictures?.map((img) => (
                <Image
                  src={img}
                  zoomSrc={img}
                  alt="Image"
                  width="80"
                  height="60"
                  preview
                  imageClassName="w-20 h-20"
                />
              ))} */}
              {data?.data?.tree_pictures?.map((file) => {
                const extension = file.split(".").pop().toLowerCase(); // Get the file extension

                if (
                  extension === "png" ||
                  extension === "jpg" ||
                  extension === "jpeg" ||
                  extension === "gif"
                ) {
                  return (
                    <Image
                      key={file}
                      src={file}
                      zoomSrc={file}
                      alt="Image"
                      width="80"
                      height="60"
                      preview
                      imageClassName="w-20 h-20"
                    />
                  );
                } else if (extension === "mp4" || extension === "mov") {
                  return (
                    <div>
                      <Button
                        icon="pi pi-play"
                        className="w-20 h-20"
                        onClick={(e) => {
                          e.preventDefault();
                          setVideoVisible(true);
                          setFile(file);
                        }}
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          </div>
          <div className="my-4">
            <h3 className="text-lg font-bold">Tree Condition</h3>
            <p>{data?.data?.tree_condition}</p>
          </div>
          <div className="my-4">
            <h3 className="text-lg font-bold">Type of Cover</h3>
            <p>{data?.data?.type_of_cover}</p>
          </div>
          <div className="my-4">
            <h3 className="text-lg font-bold">Cover Size</h3>
            <p>{data?.data?.cover_size}</p>
          </div>
          <div className="my-4">
            <h3 className="text-lg font-bold">Frame Size</h3>
            <p>{data?.data?.frame_size}</p>
          </div>
          <div className="my-4">
            <h3 className="text-lg font-bold">Extension Next Year?</h3>
            <p>{data?.data?.is_extension_next_year?.toString()}</p>
          </div>
          <div className="my-4">
            <h3 className="text-lg font-bold">Type of Extension</h3>
            <p>{data?.data?.type_of_extension}</p>
          </div>
          <div className="my-4">
            <h3 className="text-lg font-bold">Comments</h3>
            <p>{data?.data?.note}</p>
          </div>
          <div className="my-4">
            <h3 className="text-lg font-bold">Notes</h3>
            <p>{data?.data?.note}</p>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default ShowTreeDetails;
