import { Dialog } from "primereact/dialog";
import { useState } from "react";

import { BigContainer, ImagesSection } from "./OperationDialog";

import VideoPlayerDialog from "./VideoPlayerDialog";

const TreeConditionsDialog = ({ visible, setVisible, tree }) => {
  const [videoVisible, setVideoVisible] = useState(false);
  const [file, setFile] = useState("");

  return (
    <Dialog
      visible={visible}
      onHide={() => setVisible(false)}
      header="Tree Conditions"
      style={{ width: "50vw" }}
    >
      <VideoPlayerDialog
        file={file}
        videoVisible={videoVisible}
        setVideoVisible={setVideoVisible}
      />
      <div className="flex flex-col gap-4">
        <BigContainer
          titleOne={"Tree Number"}
          titleTwo={"Tree Condition"}
          valueOne={tree?.tree_number}
          valueTwo={tree?.tree_condition}
        />
        <BigContainer
          titleOne={"Type Of Cover"}
          titleTwo={"Cover Size"}
          valueOne={tree?.type_of_cover}
          valueTwo={tree?.cover_size}
        />
        <BigContainer
          titleOne={"Frame Size"}
          titleTwo={"Is Extension Next Year"}
          valueOne={tree?.frame_size}
          valueTwo={tree?.is_extension_next_year?.toString()}
        />
        <BigContainer
          titleOne={"Type Of Extension"}
          titleTwo={"Note"}
          valueOne={tree?.type_of_extension}
          valueTwo={tree?.note}
        />
        <ImagesSection
          setFile={setFile}
          setVideoVisible={setVideoVisible}
          imgTitle={"Tree Pictures"}
          imgsArr={tree?.tree_pictures}
        />
      </div>
    </Dialog>
  );
};

export default TreeConditionsDialog;
