import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useMediaQuery } from "react-responsive";

import { useGetAllProductsQuery } from "../../services/product/productServices";
import { setOrderLine } from "../../features/orderLine/orderLineSlice";

const AddOrderLine = ({
  showOrderDialog,
  setShowOrderDialog,
  showOrderCustomerId,
  refetch,
  toast,
}) => {
  const [productPrice, setProductPrice] = useState(null);

  const { addLoading, orderLinesSubmit } = useSelector(
    (state) => state.orderLines
  );

  const { data, isLoading } = useGetAllProductsQuery("productsDetails", {
    refetchOnMountOrArgChange: true,
  });

  const dispatch = useDispatch();

  const defaultValues = {
    // order_id: showOrderLineCustomerId,
    customer_id: showOrderCustomerId,
    product_id: null,
    item_type: "",
    line_item_quantity: 1,
    notes: "",
    unit_price_override: 0,
  };

  const itemTypeOptions = [
    { name: "Product", code: "product" },
    { name: "Fees", code: "fees" },
  ];

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues });

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const itemTypeValue = watch("item_type");

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onSubmit = (data) => {
    const {
      unit_price_override,
      notes,
      line_item_quantity,
      item_type,
      product_id,
    } = data;

    const orderLineObj = {
      // order_id: showOrderLineCustomerId,
      customer_id: showOrderCustomerId,
      product_id: product_id?.code,
      product_id_name: product_id?.name,
      unit_price_override: product_id?.price,
      item_type: item_type?.code,
      line_item_quantity,
      notes,
    };

    if (unit_price_override > 0) {
      orderLineObj.unit_price_override = unit_price_override;
    }

    dispatch(setOrderLine(orderLineObj));

    if (orderLinesSubmit) {
      reset();
      setShowOrderDialog(false);
    }
  };

  const handleProductChange = (selectedProduct) => {
    setProductPrice(selectedProduct.price);
  };

  return (
    <Dialog
      visible={showOrderDialog}
      onHide={() => setShowOrderDialog(false)}
      style={isDesktop ? { width: "35vw" } : isMobile ? { width: "90vw" } : {}}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="my-8">
        <Controller
          name="item_type"
          control={control}
          rules={{
            required: "Item Type is required.",
          }}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-lg text-bold`}
              >
                Item Type
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={itemTypeOptions}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        {itemTypeValue.name === "Fees" && (
          <Controller
            name="unit_price_override"
            control={control}
            rules={{
              required: "Price is required.",
              min: {
                value: 0.01,
                message: "Price must be greater than 0.",
              },
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-lg text-bold`}
                >
                  Price
                </label>
                <span>
                  <InputNumber
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    inputId="currency-us"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
        )}
        {itemTypeValue.name === "Product" && (
          <>
            <Controller
              name="product_id"
              control={control}
              rules={{ required: "Product is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-lg text-bold`}
                  >
                    Select a Product:
                  </label>
                  <span>
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      filter
                      onChange={(e) => {
                        field.onChange(e.value);
                        handleProductChange(e.value); // Call the function when value changes
                      }}
                      options={
                        data
                          ? data?.data.map((product) => ({
                              name: product.product_name,
                              code: product.id,
                              price: product.price,
                            }))
                          : []
                      }
                      loading={isLoading}
                      optionLabel="name"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      placeholder="Select a Product"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            {productPrice && (
              <div className="flex flex-col my-2">
                <p className="text-lg text-bold">Suggested Price:</p>
                <p>${productPrice}</p>
              </div>
            )}
            <Controller
              name="unit_price_override"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-lg text-bold`}
                  >
                    Override Price
                  </label>
                  <span>
                    <InputNumber
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      inputId="currency-us"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </>
        )}
        <Controller
          name="line_item_quantity"
          rules={{
            required: "Quantity is required.",
          }}
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-lg text-bold`}
              >
                Quantity:
              </label>
              <span>
                <InputNumber
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  showButtons
                  min={1}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="notes"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-lg text-bold`}
              >
                Notes:
              </label>
              <span>
                <InputTextarea
                  id={field.name}
                  {...field}
                  rows={1}
                  cols={30}
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  autoResize
                  placeholder="Add A Note"
                  maxLength={25}
                />
                <p>{field?.value?.length}/25 characters used</p>
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <div className="flex items-center justify-center">
          <Button
            label={addLoading ? "" : "Submit"}
            className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
            type="submit"
            icon={addLoading ? "pi pi-spin pi-spinner" : "pi pi-plus"}
          />
        </div>
      </form>
    </Dialog>
  );
};

export default AddOrderLine;
