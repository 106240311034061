import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../axios/axios";

export const addProduct = createAsyncThunk(
  "product/create",
  async (
    { product_name, category, product_type, size_variation, price, photo },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.post(
        "products",
        { product_name, category, product_type, size_variation, price, photo },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "product/delete",
  async ({ product_id }, { rejectWithValue, getState }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.delete(
        `products/${product_id}`,
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateProduct = createAsyncThunk(
  "product/update",
  async (
    { id, product_name, category, product_type, size_variation, price, photo },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.patch(
        `products/${id}`,
        { product_name, category, product_type, size_variation, price, photo },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
