import { useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Controller, useForm } from "react-hook-form";

import EditOrder from "./EditOrder";
import { useGetAllTechniciansQuery } from "../../services/technician/technicianServices";
import { useGetAllOrdersQuery } from "../../services/order/orderServices";
import { updateOrder } from "../../features/order/orderActions";
import { useDispatch, useSelector } from "react-redux";
import { formatCurrency } from "../../utils/formatCurrency";
import OrderDetailsDialog from "./OrderDetailsDialog";

const OrderTable = () => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [editOrderId, setEditOrderId] = useState(null);
  const [editOrderVisible, setEditOrderVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [orderDetailsVisible, setOrderDetailsVisible] = useState(false);
  const navigate = useNavigate();

  const { updateLoading: assignTechnicianState } = useSelector(
    (state) => state.orders
  );

  const dispatch = useDispatch();

  const toast = useRef(null);

  const { data, isLoading, error, refetch } = useGetAllOrdersQuery("orders", {
    refetchOnMountOrArgChange: true,
  });

  const { data: techniciansData, isLoading: techniciansLoading } =
    useGetAllTechniciansQuery("TechniciansDetails", {
      refetchOnMountOrArgChange: true,
    });

  const defaultValues = {
    technicianId: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    const obj = { technician_id: data.technicianId.code, id: orderId };
    dispatch(updateOrder(obj)).then((res) => {
      if (res?.payload?.data?.status === "Success") {
        refetch();
        setVisible(false);
        reset();
        setOrderId("");
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
  };

  const header = () => {
    return (
      <div className="flex items-center justify-between bg-none">
        <div className="flex">
          <span className="mr-2 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm rounded-xl"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
        <div>
          <Button
            label="Order map"
            icon="pi pi-map"
            className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
            size="small"
            onClick={() => navigate("/ordermap")}
          />
        </div>
      </div>
    );
  };

  const onEditOrder = (id) => {
    setEditOrderId(id);
    setEditOrderVisible(true);
  };

  const onShow = (id) => {
    setVisible(true);
    setOrderId(id);
  };

  const actionBodyTemplate = (rowData) => {
    const { id } = rowData;
    return (
      <div className="flex items-center justify-center">
        <Button
          icon="pi pi-pencil"
          size="small"
          text
          rounded
          onClick={() => onEditOrder(id)}
        />
        <Button
          label="Create Receipt"
          icon="pi pi-print"
          size="small"
          text
          rounded
          onClick={() => {
            navigate(`/orders/${id}`);
          }}
        />
        <Button
          icon="pi pi-user-edit"
          size="small"
          text
          label="Assign Technician"
          rounded
          onClick={() => onShow(id)}
        />
      </div>
    );
  };

  const onShowDetails = (id) => {
    setOrderId(id);
    setOrderDetailsVisible(true);
  };

  const customerNameBodyTemplate = (rowData) => {
    const { customer, order_date } = rowData;
    return (
      <div className="m-4 text-sm font-bold">
        {customer.full_name} - {order_date}
      </div>
    );
  };

  const paymentStatusBodyTemplate = (rowData) => {
    const { payment_status: paymentStatus, status: paymentStatusExist } =
      rowData;
    return (
      <div className="m-4 text-sm font-bold">
        {paymentStatus ? paymentStatus : paymentStatusExist}
      </div>
    );
  };

  const modeOfPaymentBodyTemplate = (rowData) => {
    const { mode_of_payment } = rowData;
    return <div className="m-4 text-sm font-bold">{mode_of_payment}</div>;
  };

  const deliveryStatusBodyTemplate = (rowData) => {
    const { order_delivery } = rowData;
    return (
      <div className="m-4 text-sm font-bold">
        {order_delivery?.delivery_status}
      </div>
    );
  };

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p>{error?.data?.message}</p>
      </div>
    );
  }

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        header="Assign a technician"
        visible={visible}
        style={
          isDesktop ? { width: "35vw" } : isMobile ? { width: "90vw" } : {}
        }
        onHide={() => setVisible(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="my-8">
          <Controller
            name="technicianId"
            control={control}
            rules={{ required: "Technician is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Select a technician:
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={
                      techniciansData
                        ? techniciansData.data.map((technician) => ({
                            name: technician.full_name,
                            code: technician.id,
                          }))
                        : []
                    }
                    loading={techniciansLoading}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    placeholder="Select a Technician"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <div className="flex items-center justify-center">
            <Button
              label={assignTechnicianState ? "" : "Submit"}
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              type="submit"
              icon={
                assignTechnicianState ? "pi pi-spin pi-spinner" : "pi pi-check"
              }
            />
          </div>
        </form>
      </Dialog>
      {editOrderId && (
        <EditOrder
          editOrderId={editOrderId}
          editOrderVisible={editOrderVisible}
          refetch={refetch}
          toast={toast}
          setEditOrderVisible={setEditOrderVisible}
        />
      )}
      {orderId && (
        <OrderDetailsDialog
          visible={orderDetailsVisible}
          setVisible={setOrderDetailsVisible}
          setOrderId={setOrderId}
          id={orderId}
        />
      )}
      <DataTable
        value={data?.data}
        dataKey="id"
        scrollable
        scrollHeight="68vh"
        loading={isLoading}
        header={header}
        globalFilter={globalFilterValue}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        onRowClick={(e) => {
          setOrderId(null);
          onShowDetails(e?.data?.id);
        }}
        rowClassName="cursor-pointer"
      >
        {/* <Column
          style={{
            minWidth: "5rem",
          }}
          headerStyle={{
            textAlign: "center",
          }}
          header="ID"
          field="id"
        /> */}
        <Column
          header="Order Name"
          field="customer.full_name"
          body={customerNameBodyTemplate}
        />
        <Column
          field="payment_status"
          header="Payment Status"
          filter
          style={{ minWidth: "15rem" }}
          body={paymentStatusBodyTemplate}
        />
        <Column
          field="mode_of_payment"
          header="Mode of Payment"
          body={modeOfPaymentBodyTemplate}
        />
        <Column
          style={{ minWidth: "15rem" }}
          field="order_delivery.delivery_status"
          header="Delivery Status"
          filter
          body={deliveryStatusBodyTemplate}
        />
        {/* <Column
          style={{ minWidth: "10rem" }}
          header="Deposit"
          field="deposit"
          body={depositBodyTemplate}
        /> */}
        {/* <Column
          style={{ minWidth: "10rem" }}
          header="Deposit"
          field="deposit"
        /> */}
        {/* <Column
          style={{ minWidth: "12rem" }}
          header="Deposit After Taxes"
          field="deposit_after_taxes"
          body={depositAfterTaxesBodyTemplate}
        />
        <Column
          style={{ minWidth: "15rem" }}
          header="Deposit After Processing Fees"
          field="deposit_after_processing_fees"
          body={depositAfterProcessingFeesBodyTemplate}
        />
        <Column
          style={{ minWidth: "15rem" }}
          header="Order Unpaid Amount"
          field="order_unpaid_amount"
          body={orderUnpaidAmountBodyTemplate}
        />
        <Column
          style={{ minWidth: "15rem" }}
          header="Order Unpaid Amount After Taxes"
          field="order_unpaid_amount_after_taxes"
          body={orderUnpaidAmountAfterTaxesBodyTemplate}
        />
        <Column
          style={{ minWidth: "20rem" }}
          header="Order Unpaid Amount After Processing"
          field="order_unpaid_amount_after_processing"
          body={orderUnpaidAmountAfterProcessingBodyTemplate}
        />
        <Column
          style={{ minWidth: "15rem" }}
          header="Remaining After Deposit"
          field="remaining_after_deposit"
          body={remainingAfterDepositBodyTemplate}
        />
        <Column
          style={{ minWidth: "15rem" }}
          header="Remain After Deposit After Taxes"
          field="remaining_after_deposit_after_taxes"
          body={remainingAfterDepositAfterTaxesBodyTemplate}
        />
        <Column
          style={{ minWidth: "10rem" }}
          header="Balance"
          field="remaining_after_deposit_after_fees"
          body={balanceBodyTemplate}
        />

        <Column
          style={{ minWidth: "20rem" }}
          field="payment_status.payment_status"
          header="Status"
          filter
        />
        <Column
          style={{ minWidth: "20rem" }}
          field="mode_of_payment"
          header="Mode of Payment"
        />
        <Column
          style={{ minWidth: "20rem" }}
          field="follow_up_special_notes"
          header="Notes Customer Sees"
        /> */}
        {/* <Column
          style={{ minWidth: "15rem" }}
          field="trees_qty_all"
          header="Tree QTY"
        />
        <Column
          style={{ minWidth: "15rem" }}
          field="planting_fee"
          header="Planting Fee"
        /> */}
        {/* <Column
          style={{ minWidth: "15rem" }}
          field="order_type"
          header="Purchase / Rental"
        />
        <Column
          style={{ minWidth: "15rem" }}
          field="order_date"
          header="Order Date"
          filter
        />
        <Column
          style={{ minWidth: "15rem" }}
          field="customer_type"
          header="Type Of Order"
        />
        <Column
          style={{ minWidth: "10rem" }}
          field="discount"
          header="Discount"
        /> */}
        {/* <Column
          header="Technician"
          body={technicianBodyTemplate}
          style={{ minWidth: "10rem" }}
        /> */}
        {/* <Column
          body={actionBodyTemplate}
          header="Actions"
          style={{ minWidth: "25rem" }}
        /> */}
      </DataTable>
    </div>
  );
};

export default OrderTable;
