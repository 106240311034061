import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Controller, useForm } from "react-hook-form";

import { addTreeConditions } from "../../features/TreeConditions/treeConditionsActions";
import { apiUrl } from "../../utils/apiURL";

const AddTree = ({
  showAddTree,
  setShowAddTree,
  toast,
  customerId,
  operationId,
  refetch,
}) => {
  const { addLoading } = useSelector((state) => state.treeConditions);
  const [treeMapsImages, setTreeMapsImages] = useState([]);
  const dispatch = useDispatch();
  const defaultValues = {
    tree_number: "",
    tree_condition: "",
    tree_pictures: "",
    trees_map: "",
    type_of_cover: "",
    cover_size: "",
    frame_size: "",
    is_extension_next_year: "",
    type_of_extension: "",
    note: "",
  };

  const treeConditionOptions = [
    { name: "Poor", code: "Poor" },
    { name: "Good", code: "Good" },
    { name: "Great", code: "Great" },
  ];

  const typeOfCoverOptions = [
    { name: "Old Green", code: "Old Green" },
    { name: "New Green", code: "New Green" },
    { name: "White", code: "White" },
  ];

  const coverSizeOptions = [
    { name: 6, code: 6 },
    { name: 8, code: 8 },
    { name: 10, code: 10 },
    { name: 12, code: 12 },
    { name: 14, code: 14 },
    { name: 16, code: 16 },
    { name: 18, code: 18 },
    { name: 20, code: 20 },
    { name: 22, code: 22 },
    { name: 24, code: 24 },
  ];

  const frameSizeOptions = [
    { name: 4, code: 4 },
    { name: 6, code: 6 },
    { name: 8, code: 8 },
    { name: 10, code: 10 },
    { name: 12, code: 12 },
    { name: 14, code: 14 },
    { name: 16, code: 16 },
    { name: 18, code: 18 },
    { name: 20, code: 20 },
    { name: 22, code: 22 },
    { name: 24, code: 24 },
  ];

  const extensionNextYearOptions = [
    { name: "Yes", code: "Yes" },
    { name: "No", code: "No" },
  ];

  const typeOfExtensionOptions = [
    { name: "Solid Green", code: "Solid Green" },
    { name: "Screen Green", code: "Screen Green" },
    { name: "None", code: "None" },
    { name: "White", code: "None" },
  ];

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    const obj = {
      customer_id: customerId,
      treatment_operation_id: operationId,
      tree_number: data.tree_number,
      tree_condition: data.tree_condition.name,
      type_of_cover: data.type_of_cover.name || "",
      cover_size: data.cover_size.name,
      frame_size: data.frame_size.name,
      is_extension_next_year: data.is_extension_next_year.name === "Yes",
      type_of_extension: data.type_of_extension.name || "",
      note: data.note,
      tree_pictures: treeMapsImages,
    };

    dispatch(addTreeConditions(obj)).then((res) => {
      if (res.payload.status === 200) {
        setShowAddTree(false);
        reset();
        refetch();
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const onUpload = (e) => {
    toast.current.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });

    const response = JSON.parse(e.xhr.response);
    const imageUrl = response.data;

    setTreeMapsImages((prevImages) => {
      if (!Array.isArray(prevImages)) {
        console.error("prevImages is not an array:", prevImages);
        return [imageUrl]; // Return a new array with the image
      }
      return [...prevImages, imageUrl];
    });
  };

  const onRemoveImage = (e, fileToRemove) => {
    e.preventDefault();
    const filteredImages = treeMapsImages.filter(
      (file) => file !== fileToRemove
    );
    setTreeMapsImages(filteredImages);
  };

  return (
    <Dialog
      header="Add Tree"
      visible={showAddTree}
      onHide={() => setShowAddTree(false)}
      style={{ width: "35vw" }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="tree_number"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Tree Number
              </label>
              <span>
                <InputText
                  id={field.name}
                  value={field.value}
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <div className="my-4 p-field">
          <label htmlFor="treePicture">Tree Picture</label>
          <FileUpload
            id="treePicture"
            mode="basic"
            name="img"
            url={`${apiUrl}media/api/upload`}
            accept="image/*, video/*"
            maxFileSize={35000000}
            onUpload={(e) => onUpload(e)}
            chooseLabel="Drop here"
            auto
          />
          <div className="flex flex-wrap gap-4 my-4">
            {treeMapsImages.map((img) => (
              <div className="relative" key={img}>
                <Button
                  icon="pi pi-times"
                  rounded
                  text
                  aria-label="Cancel"
                  onClick={(e) => onRemoveImage(e, img)}
                  className="absolute z-[99999999999999999999999999999] -top-[25px] -right-[25px] m-0 p-0 text-red-500"
                />
                <Image
                  src={img}
                  zoomSrc={img}
                  alt="Image"
                  width="80"
                  height="60"
                  preview
                  imageClassName="w-20 h-20"
                />
              </div>
            ))}
          </div>
        </div>
        <Controller
          name="tree_condition"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Tree Conditions
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={treeConditionOptions}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="type_of_cover"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Type Of Cover
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={typeOfCoverOptions}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="cover_size"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Cover Size
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={coverSizeOptions}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="frame_size"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Frame Size
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={frameSizeOptions}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="is_extension_next_year"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Extension Next Year?
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={extensionNextYearOptions}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="type_of_extension"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Type of Extension
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={typeOfExtensionOptions}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="note"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={classNames({ "p-error": errors.value })}
              >
                Comments
              </label>
              <span>
                <InputTextarea
                  id={field.name}
                  {...field}
                  rows={4}
                  cols={30}
                  autoResize
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <div className="flex items-center justify-center">
          <Button label="Submit" loading={addLoading} />
        </div>
      </form>
    </Dialog>
  );
};

export default AddTree;
