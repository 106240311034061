import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";

import { useGetCustomerQuery } from "../../services/customer/customerServices";
import { addOrder, sendOrderEmail } from "../../features/order/orderActions";
import AddOrderLine from "../OrderLines/AddOrderLine";
import {
  clearOrderLines,
  removeOrderLine,
} from "../../features/orderLine/orderLineSlice";
import { addOrderLine } from "../../features/orderLine/orderLineActions";
import { addDelivery } from "../../features/delivery/deliveryActions";
import { formatCurrency } from "./../../utils/formatCurrency";

const AddOrder = ({
  showOrderCustomerVisible,
  setShowOrderCustomerVisible,
  showOrderCustomerId,
  toast,
  refetch,
}) => {
  const [showOrderDialog, setShowOrderDialog] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const { addLoading, orderLines } = useSelector((state) => state.orderLines);
  const { addLoading: addLoadingOrders } = useSelector((state) => state.orders);
  const { addLoading: addLoadingDelivery } = useSelector(
    (state) => state.delivery
  );

  useEffect(() => {
    let totalPriceCalculation = 0;
    orderLines.forEach((item) => {
      if (item.item_type === "product") {
        totalPriceCalculation +=
          item.line_item_quantity * item.unit_price_override;
      } else if (item.item_type === "fees") {
        totalPriceCalculation +=
          item.line_item_quantity * item.unit_price_override;
      }
    });
    setTotalPrice(totalPriceCalculation);
  }, [orderLines]);

  const onShowAddOrderLine = (e, id) => {
    e.preventDefault();
    setShowOrderDialog(true);
  };

  const dispatch = useDispatch();

  const defaultValues = {
    id: showOrderCustomerId,
    purchase: "",
    orderDate: new Date(),
    rentalStartDate: "",
    rentalEndDate: "",
    typeOfOrder: "",
    deliveryStatus: "",
    estimatedDeliveryDate: "",
    deliveryNote: "",
    modeOfPayment: "",
    deposit: 0,
    discount: 0,
    customerNote: "",
    orderNote: "",
  };

  const purchaseOptions = [
    { name: "Purchase", code: "Purchase" },
    { name: "Palm Trees Rental", code: "Palm Trees Rental" },
    { name: "Tiki Bar Rental", code: "Tiki Bar Rental" },
  ];

  const typeOfOrderOptions = [
    { name: "Walk In", code: "Walk In" },
    { name: "Phone In", code: "Phone In" },
    { name: "Website", code: "Website" },
    { name: "Home Show", code: "Home Show" },
  ];

  const deliveryStatusOptions = [
    {
      name: "Need to Deliver (Tropicals)",
      code: "Need to Deliver (Tropicals)",
    },
    { name: "Need to Deliver", code: "Need to Deliver" },
    { name: "Took Trees", code: "Took Trees" },
    { name: "Picking Up", code: "Picking Up" },
  ];

  const modeOfPaymentOptions = [
    { name: "Cash", code: "Cash" },
    { name: "Credit Card", code: "Credit Card" },
    { name: "Check/Zelle", code: "Check" },
    {
      name: "Cash Deposit / Credit Card Balance",
      code: "Cash Deposit / Credit Card Balance",
    },
    {
      name: "Credit Card Deposit / Cash Balance",
      code: "Credit Card Deposit / Cash Balance",
    },
  ];

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues });

  const rental = watch("purchase");
  const modeOfPayment = watch("modeOfPayment");

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const removeOneOrderLine = (e, id) => {
    e.preventDefault();
    dispatch(removeOrderLine(id));
  };

  function formatDateToAmerican(date) {
    // Get the month, day, and year from the date object
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const day = date.getDate();
    const year = date.getFullYear();

    // Pad the month and day with leading zeros if needed
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    // Format the date in MM/DD/YYYY format
    return `${formattedMonth}/${formattedDay}/${year}`;
  }

  const onSubmit = (data) => {
    const {
      purchase,
      orderDate,
      rentalStartDate,
      rentalEndDate,
      typeOfOrder,
      modeOfPayment,
      deposit,
      discount,
      customerNote,
      orderNote,
      estimatedDeliveryDate,
      deliveryStatus,
      deliveryNote,
    } = data;

    const formattedOrderDate = formatDateToAmerican(new Date(orderDate));
    const formattedRentalStartDate =
      rentalStartDate !== ""
        ? formatDateToAmerican(new Date(rentalStartDate))
        : "";
    const formattedRentalEndDate =
      rentalEndDate !== "" ? formatDateToAmerican(new Date(rentalEndDate)) : "";
    const formattedEstimatedDeliveryDate =
      estimatedDeliveryDate !== ""
        ? formatDateToAmerican(new Date(estimatedDeliveryDate))
        : "";

    const obj = {
      customer_id: showOrderCustomerId,
      customer_type: typeOfOrder.name,
      mode_of_payment: modeOfPayment.code,
      order_date: formattedOrderDate,
      order_notes: orderNote,
      follow_up_special_notes: customerNote,
      order_type: purchase.name,
      rental_start_date: formattedRentalStartDate,
      rental_end_date: formattedRentalEndDate,
      discount,
      deposit,
      order_name: "",
    };

    if (
      modeOfPayment.name === "Cash Deposit / Credit Card Balance" ||
      modeOfPayment.name === "Credit Card Deposit / Cash Balance"
    ) {
      obj.deposit = deposit;
    }

    if (deposit === "") {
      obj.deposit = 0;
    }

    if (discount === "") {
      obj.discount = 0;
    }

    const deliveryObj = {
      estimated_delivery_date: formattedEstimatedDeliveryDate,
      delivery_status: deliveryStatus.name,
      delivery_notes: deliveryNote,
    };

    if (orderLines.length > 0) {
      dispatch(addOrder(obj)).then((res) => {
        if (res?.payload?.data?.status === "Success") {
          const orderId = res.payload.data.data.id;
          const transformedOrderLines = orderLines.map((order) => {
            const unitPriceOverride = order.unit_price_override || 0;
            return {
              product_id:
                order.item_type !== "fees" ? order.product_id || "" : undefined,
              item_type: order?.item_type || "",
              line_item_quantity: order?.line_item_quantity || 0,
              notes: order?.notes || "",
              ...(unitPriceOverride > 0 && {
                unit_price_override: unitPriceOverride,
              }),
              order_id: orderId,
            };
          });
          for (let i = 0; i < transformedOrderLines.length; i++) {
            const element = transformedOrderLines[i];
            dispatch(addOrderLine(element)).then((res) => {
              if (res?.payload?.data?.status === "Success") {
                reset();
                dispatch(clearOrderLines());
                if (i === transformedOrderLines.length - 1) {
                  dispatch(sendOrderEmail({ id: orderId }));
                  setShowOrderCustomerVisible(false);
                }
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: res.payload,
                  life: 3000,
                });
              }
            });
          }

          dispatch(addDelivery({ ...deliveryObj, order_id: orderId })).then(
            (res) => {
              if (res?.payload?.data?.status === "Success") {
                reset();
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: res.payload,
                  life: 3000,
                });
              }
            }
          );
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.payload,
            life: 3000,
          });
        }
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Please Add An Order Line",
        life: 3000,
      });
    }
  };

  const { data, isLoading } = useGetCustomerQuery(showOrderCustomerId, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <Dialog
      header="Add Order"
      visible={showOrderCustomerVisible}
      style={{ width: "45vw" }}
      onHide={() => {
        dispatch(clearOrderLines());
        setShowOrderCustomerVisible(false);
      }}
    >
      <AddOrderLine
        showOrderDialog={showOrderDialog}
        setShowOrderDialog={setShowOrderDialog}
        showOrderCustomerId={showOrderCustomerId}
        toast={toast}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="my-8">
        {isLoading ? (
          <div className="flex items-center justify-center w-full h-full">
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2rem" }}
            ></i>
          </div>
        ) : (
          <div className="flex flex-col items-start justify-center">
            <h3 className="text-base text-bold">Customer Name</h3>
            <p>{data?.data?.full_name}</p>
          </div>
        )}
        <Controller
          name="purchase"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-base text-bold`}
              >
                Purchase / Rental
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={purchaseOptions}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        {(rental.name === "Palm Trees Rental" ||
          rental.name === "Tiki Bar Rental") && (
          <>
            <Controller
              name="rentalStartDate"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Rental Start Date
                  </label>
                  <span>
                    <Calendar
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      dateFormat="mm/dd/yy"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      inputClassName="rounded-xl"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
            <Controller
              name="rentalEndDate"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Rental End Date
                  </label>
                  <span>
                    <Calendar
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      dateFormat="mm/dd/yy"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      inputClassName="rounded-xl"
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          </>
        )}
        <Controller
          name="orderDate"
          control={control}
          rules={{
            required: "Order Date is required.",
          }}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-base text-bold`}
              >
                Order Date
              </label>
              <span>
                <Calendar
                  id={field.name}
                  value={field.value}
                  // onChange={(e) => field.onChange(e.value)}
                  disabled
                  dateFormat="mm/dd/yy"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  inputClassName="rounded-xl"
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="typeOfOrder"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-base text-bold`}
              >
                Type of Order
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={typeOfOrderOptions}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <div className="flex flex-col">
          <p className="text-base text-bold">Products Ordered:</p>
          <Button label="Order" onClick={(e) => onShowAddOrderLine(e)} />
        </div>
        <div className="flex flex-col">
          {orderLines.map((item, idx) => {
            return (
              <div
                className="flex items-center justify-between rounded-xl bg-[#FFFFFF] p-2 my-2 border-2 border-green-900"
                key={item.id}
              >
                <p>
                  {item?.line_item_quantity} X{" "}
                  {item.product_id_name
                    ? item?.product_id_name
                    : item?.item_type}{" "}
                  - {formatCurrency(item?.unit_price_override)}
                </p>
                <Button
                  text
                  icon="pi pi-trash"
                  severity="danger"
                  onClick={(e) => removeOneOrderLine(e, item.id)}
                />
              </div>
            );
          })}
          {totalPrice > 0 && (
            <div>Total Price: {formatCurrency(totalPrice)}</div>
          )}
        </div>
        <Controller
          name="modeOfPayment"
          rules={{
            required: "Mode Of Payment is required.",
          }}
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-base text-bold`}
              >
                Mode of Payment
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={modeOfPaymentOptions}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        {(modeOfPayment?.name === "Cash Deposit / Credit Card Balance" ||
          modeOfPayment?.name === "Credit Card Deposit / Cash Balance") && (
          <Controller
            name="deposit"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Deposit
                </label>
                <span>
                  <InputNumber
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    inputId="currency-us"
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
        )}
        <Controller
          name="discount"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-base text-bold`}
              >
                Discount
              </label>
              <span>
                <InputNumber
                  mode="currency"
                  currency="USD"
                  locale="en-US"
                  id={field.name}
                  value={field.value}
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  onChange={(e) => field.onChange(e.value)}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="customerNote"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-base text-bold`}
              >
                Notes Customer Sees
              </label>
              <span>
                <InputTextarea
                  id={field.name}
                  {...field}
                  rows={4}
                  cols={30}
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  autoResize
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="orderNote"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-base text-bold`}
              >
                Order Followup Notes
              </label>
              <span>
                <InputTextarea
                  id={field.name}
                  {...field}
                  rows={4}
                  cols={30}
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  autoResize
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="deliveryStatus"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-base text-bold`}
              >
                Delivery status
              </label>
              <span>
                <Dropdown
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  options={deliveryStatusOptions}
                  optionLabel="name"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="estimatedDeliveryDate"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-base text-bold`}
              >
                Estimated Delivery / Pickup Date
              </label>
              <span>
                <Calendar
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  dateFormat="mm/dd/yy"
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  inputClassName="rounded-xl"
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <Controller
          name="deliveryNote"
          control={control}
          render={({ field, fieldState }) => (
            <div className="flex flex-col">
              <label
                htmlFor={field.name}
                className={`${classNames({
                  "p-error": errors.value,
                })} text-base text-bold`}
              >
                Delivery Notes
              </label>
              <span>
                <InputTextarea
                  id={field.name}
                  {...field}
                  rows={4}
                  cols={30}
                  className={`${classNames({
                    "p-invalid": fieldState.error,
                  })} w-full rounded-xl`}
                  autoResize
                />
              </span>
              {getFormErrorMessage(field.name)}
            </div>
          )}
        />
        <div className="flex items-center justify-center">
          <Button
            label={
              addLoading || addLoadingOrders || addLoadingDelivery
                ? ""
                : "Submit"
            }
            className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
            type="submit"
            icon={
              addLoading || addLoadingOrders || addLoadingDelivery
                ? "pi pi-spin pi-spinner"
                : "pi pi-plus"
            }
          />
        </div>
      </form>
    </Dialog>
  );
};

export default AddOrder;
