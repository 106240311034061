import Header from "../Header";
import CashTreatmentTableContent from "./CashTreatmentTableContent";
import styles from "./ClientTreatmentTable.module.css";

const AdminOperationTable = () => {
  return (
    <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Tree Treatment"} />
          <div className={`w-full h-full rounded-2xl m-2 px-2`}>
            <CashTreatmentTableContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminOperationTable;
