import { Dialog } from "primereact/dialog";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useGetAllAdminQuery } from "../../services/admin/adminServices";
import { useGetTaskQuery } from "../../services/tasks/tasksServices";
import { updateTask } from "../../features/tasks/tasksActions";
import { Calendar } from "primereact/calendar";

const EditTask = ({
  editTaskId,
  editProductVisible,
  setEditProductVisible,
  refetch,
  toast,
}) => {
  const dispatch = useDispatch();
  const { updateLoading } = useSelector((state) => state.tasks);

  const Status = [
    { label: "To Do", value: "to-do" },
    { label: "In Progress", value: "in-progress" },
    { label: "Done", value: "done" },
  ];
  const { data, isLoading, error } = useGetTaskQuery(editTaskId, {
    refetchOnMountOrArgChange: true,
  });
  const allAdmins = useGetAllAdminQuery(editTaskId, {
    refetchOnMountOrArgChange: true,
  });

  const onSubmit = (data) => {
    const name = data.name;
    const notes = data?.notes;
    const status = data?.status;
    const assigned_to_id = data?.assigned_to_id;
    const date = data?.date;
    const id = editTaskId;

    dispatch(
      updateTask({ name, notes, status, assigned_to_id, id, date })
    ).then((res) => {
      if (res?.payload?.data?.status === "success") {
        reset();
        refetch();
        setEditProductVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res?.payload?.data?.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res?.payload,
          life: 3000,
        });
      }
    });
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  console.log({ data });

  useEffect(() => {
    if (data?.data) {
      reset({
        name: data?.data[0].name || "",
        notes: data?.data[0].notes || "",
        status: data?.data[0].status || "",
        created_by_id: data?.data[0].created_by.first_name || "",
        assigned_to_id: data?.data[0].assigned_to.id || "",
        date: new Date(data?.data[0].date || null),
      });
    }
  }, [data, allAdmins, reset]);

  return (
    <Dialog
      header="Edit Tasks"
      visible={editProductVisible}
      onHide={() => setEditProductVisible(false)}
      style={{ width: "50vw" }}
    >
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className="my-8">
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-lg text-bold`}
                >
                  Name <span>*</span>
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field?.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="notes"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-lg text-bold`}
                >
                  Notes <span>*</span>
                </label>
                <span>
                  <InputTextarea
                    id={field.name}
                    {...field}
                    rows={4}
                    cols={30}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    autoResize
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <div className="gap-5 lg:flex">
            <div className="lg:w-1/2">
              <Controller
                name="created_by_id"
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field, fieldState }) => (
                  <div className="flex flex-col">
                    <label
                      htmlFor={field.name}
                      className={`${classNames({
                        "p-error": errors.value,
                      })} text-lg text-bold`}
                    >
                      Created by:
                    </label>
                    <span>
                      <InputText
                        readOnly
                        id={field.name}
                        value={field?.value}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} w-full rounded-xl`}
                        // onChange={(e) => field.onChange(e.target.value)}
                      />
                    </span>
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
            </div>
            <div className="lg:w-1/2">
              <Controller
                name="assigned_to_id"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="flex flex-col">
                    <label
                      htmlFor={field.name}
                      className={`${classNames({
                        "p-error": errors.value,
                      })} text-lg text-bold`}
                    >
                      Assigned to:
                    </label>
                    <span>
                      <Dropdown
                        id={field.name}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        options={allAdmins?.data?.data?.map((ele) => ({
                          label: ele.first_name,
                          value: ele.id,
                        }))}
                        loading={isLoading}
                        optionLabel="label"
                        optionValue="value"
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} w-full rounded-xl`}
                      />
                    </span>
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
            </div>
          </div>
          <Controller
            name="status"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Status
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={Status}
                    optionLabel="label"
                    optionValue="value"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="date"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Date
                </label>
                <span>
                  <Calendar
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    dateFormat="mm/dd/yy"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    inputClassName="rounded-xl"
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <div className="flex items-center justify-center">
            <Button
              label={updateLoading ? "" : "Submit"}
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              type="submit"
              icon={updateLoading ? "pi pi-spin pi-spinner" : "pi pi-plus"}
            />
          </div>
        </form>
      )}
      {error && (
        <div className="flex items-center justify-center w-full h-full">
          <p>{error.message}</p>
        </div>
      )}
    </Dialog>
  );
};

export default EditTask;
