import MapCustomerService from "../../services/map/mapCustomer";

const MapAddCustomer = () => {
  return (
    <>
      <MapCustomerService />
    </>
  );
};

export default MapAddCustomer;
