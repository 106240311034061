import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  communicateToCustomerEmail,
  communicateToCustomerSms,
} from "../../features/customer/customerActions";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";

const CommunicateCustomer = ({ refetch, toast, data: CustomerData }) => {
  const dispatch = useDispatch();
  const { addLoading } = useSelector((state) => state.customer);

  const defaultValues = {
    email: "",
    sms: "",
    Emailsubject: "",
    emailCheckbox: false,
    smsCheckbox: false,
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
  } = useForm({ defaultValues });

  const emailCheckbox = watch("emailCheckbox");
  const smsCheckbox = watch("smsCheckbox");

  const handleCheckboxChange = (field, isChecked) => {
    setValue(field, isChecked);
  };

  const onSubmit = (data) => {
    if (data.emailCheckbox) {
      const obj = { ...data, contactIds: [CustomerData?.data?.id] };
      const reqObj = {
        cust_id_arr: obj.contactIds,
        email_body: data.email,
        email_subject: data.Emailsubject,
        //incoming_category_type: "email"
      };
      dispatch(communicateToCustomerEmail(reqObj)).then((res) => {
        if (res.payload.status === 200) {
          //setShowAddTree(false);
          reset();
          refetch();
          toast.current.show({
            severity: "success",
            summary: "Form Submitted",
            detail: res.payload.data.message,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.payload,
            life: 3000,
          });
        }
      });
    }
    if (data.smsCheckbox) {
      const obj = { ...data, contactIds: [CustomerData?.data?.id] };
      const reqObj = {
        cust_id_arr: obj.contactIds,
        sms_body: data.sms,
        //incoming_category_type: "sms"
      };
      dispatch(communicateToCustomerSms(reqObj)).then((res) => {
        if (res.payload.status === 200) {
          //setShowAddTree(false);
          reset();
          refetch();
          toast.current.show({
            severity: "success",
            summary: "Form Submitted",
            detail: res.payload.data.message,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.payload,
            life: 3000,
          });
        }
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="my-8">
        <div className="flex items-center justify-between w-full gap-2 mb-4">
          <Controller
            name="Emailsubject"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-full">
                <label className="block mb-2">Email subject</label>
                <div className="flex items-center gap-2">
                  <InputText
                    {...field}
                    className={classNames(
                      { "p-invalid": fieldState.error },
                      "rounded-xl min-h-[20px] resize-none w-1/2"
                    )}
                    placeholder="Enter email subject"
                    maxLength={70}
                  />
                </div>
                {errors.email && (
                  <small className="p-error">{errors.email.message}</small>
                )}
                <small>{getValues("Emailsubject")?.length} out of 70</small>
              </div>
            )}
          />
        </div>
        <div className="flex items-center justify-between w-full gap-2">
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-full">
                <label className="block mb-2">Email</label>
                <div className="flex items-center gap-2">
                  <InputTextarea
                    {...field}
                    className={classNames(
                      { "p-invalid": fieldState.error },
                      "rounded-xl min-h-[200px] resize-none w-full"
                    )}
                    placeholder="Enter email"
                    maxLength={1000}
                  />
                  <Checkbox
                    onChange={(e) =>
                      handleCheckboxChange("emailCheckbox", e.checked)
                    }
                    checked={emailCheckbox}
                  />
                </div>
                {errors.email && (
                  <small className="p-error">{errors.email.message}</small>
                )}
                <small>{getValues("email")?.length} out of 1000</small>
              </div>
            )}
          />
        </div>
        <div className="flex items-center justify-between w-full gap-2 mt-4">
          <Controller
            name="sms"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-full">
                <label className="block mb-2">SMS</label>
                <div className="flex items-center gap-2">
                  <InputTextarea
                    {...field}
                    className={classNames(
                      { "p-invalid": fieldState.error },
                      "rounded-xl min-h-[200px] resize-none  w-full"
                    )}
                    placeholder="Enter SMS content"
                    style={{ height: "100px" }}
                    maxLength={1000}
                  />
                  <Checkbox
                    onChange={(e) =>
                      handleCheckboxChange("smsCheckbox", e.checked)
                    }
                    checked={smsCheckbox}
                  />
                </div>
                {errors.sms && (
                  <small className="p-error">{errors.sms.message}</small>
                )}
                <small>{getValues("sms")?.length} out of 1000</small>
              </div>
            )}
          />
        </div>
        <div className="flex items-center justify-center mt-4">
          <Button
            label={addLoading ? "Loading..." : "Submit"}
            className="bg-[#C4E1D8] text-[#0D6047] border-none rounded-xl"
            type="submit"
            icon={addLoading ? "pi pi-spin pi-spinner" : ""}
          />
        </div>
      </form>
    </>
  );
};

export default CommunicateCustomer;
