import { createSlice } from "@reduxjs/toolkit";
import { addTechnician, updateTechnician } from "./technicanActions";

const initialState = {
  addLoading: false,
  updateLoading: false,
};

const technicianSlice = createSlice({
  name: "technician",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTechnician.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addTechnician.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addTechnician.rejected, (state) => {
        state.addLoading = false;
      })
      .addCase(updateTechnician.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateTechnician.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateTechnician.rejected, (state) => {
        state.updateLoading = false;
      });
  },
});

export default technicianSlice.reducer;
