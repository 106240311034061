import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../axios/axios";

export const addOrder = createAsyncThunk(
  "order/create",
  async (
    {
      customer_id,
      customer_type,
      mode_of_payment,
      order_date,
      order_notes,
      follow_up_special_notes,
      client_billing_notes,
      order_type,
      discount,
      deposit,
      rental_start_date,
      rental_end_date,
      status,
    },
    { rejectWithValue, getState }
  ) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status: reqStatus } = await instance.post(
        "orders",
        {
          customer_id,
          customer_type,
          mode_of_payment,
          order_date,
          order_notes,
          follow_up_special_notes,
          client_billing_notes,
          order_type,
          discount,
          deposit,
          rental_start_date,
          rental_end_date,
          status,
        },
        config
      );
      return { data, reqStatus };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateOrder = createAsyncThunk(
  "order/update",
  async (
    {
      id,
      customer_id,
      customer_type,
      mode_of_payment,
      order_date,
      order_notes,
      follow_up_special_notes,
      client_billing_notes,
      order_type,
      discount,
      deposit,
      rental_start_date,
      rental_end_date,
      status,
      technician_id,
      receipt_pdf,
    },
    { rejectWithValue, getState }
  ) => {
    const token = getState().auth.token? getState().auth.token : getState().auth.technicianToken;
    try { 
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status: reqStatus } = await instance.patch(
        `orders/${id}`,
        {
          customer_id,
          customer_type,
          mode_of_payment,
          order_date,
          order_notes,
          follow_up_special_notes,
          client_billing_notes,
          order_type,
          discount,
          deposit,
          rental_start_date,
          rental_end_date,
          status,
          technician_id,
          receipt_pdf,
        },
        config
      );
      return { data, reqStatus };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const sendOrderEmail = createAsyncThunk(
  "orders/sendOrderEmail",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data } = await instance.post(
        `orders/${id}/send-mail`,
        {},
        config
      );
      return data;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addOrderLineInOrders = createAsyncThunk(
  "orders/addOrderLine",
  async ({ id, OrderLineArr }, { rejectWithValue, getState }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data } = await instance.post(
        `orders/${id}/line-items`,
        OrderLineArr,
        config
      );
      return data;
    } catch (error) {
      console.log({ error });
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
