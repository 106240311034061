import "./LoginPage.css";

import { useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import logo from "../assets/LOGO.png";

import { loginAdmin, loginTechnician } from "../features/auth/authActions";
import { RadioButton } from "primereact/radiobutton";

const LoginPage = () => {
  const { token, technicianToken, loading, technicianLoading } = useSelector(
    (state) => state.auth
  );
  const toast = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const defaultValues = {
    email: "",
    password: "",
    role: "Admin",
  };

  useEffect(() => {
    if (token) {
      navigate("/");
    }
    if (technicianToken) {
      navigate("/technician");
    }
  }, [token, navigate, technicianToken]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    const { email, password, role } = data;
    const obj = { email: email, password: password };
    if (role === "Admin") {
      dispatch(loginAdmin(obj)).then((res) => {
        if (res.payload.status === 200) {
          reset();
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.payload,
            life: 3000,
          });
        }
      });
    } else if (role === "Technician") {
      dispatch(loginTechnician(obj)).then((res) => {
        if (res.payload.status === 200) {
          reset();
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.payload,
            life: 3000,
          });
        }
      });
    }
  };

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  return (
    <div className="w-full flex min-h-screen bg-[#1F634E]">
      <Toast ref={toast} />
      <div className="flex-col items-center justify-around hidden w-1/3 min-h-screen mx-auto md:flex">
        <div className="flex items-center justify-center w-4/5 mx-auto">
          <p className="text-[#BCAD67] font-normal text-center text-[80px] leading-[80px] login-title">
            Welcome Back!
          </p>
        </div>
        <div className="flex items-center justify-center w-4/5">
          <img src={logo} alt="LOGO" />
        </div>
      </div>
      <div
        className="md:w-2/3 w-full flex flex-col bg-[#FFEDED] m-4 rounded-3xl justify-center items-center"
        style={{
          boxShadow:
            "7.434699535369873px 7.434699535369873px 7.434699535369873px 8.902203559875488px #0000004D",
        }}
      >
        <h5 className="login-title text-[80px] leading-[80px] text-[#BCAD67]">
          Login
        </h5>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-4/5 mx-auto my-8 login-form"
        >
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required.",
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Email
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-3xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    placeholder="E-mail"
                    autoFocus
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: "Password is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor="password"
                  className={classNames({ "p-error": errors.password })}
                >
                  Password
                </label>
                <span>
                  <Password
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-3xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    toggleMask
                    feedback={false}
                    placeholder="Password"
                    inputClassName="w-full rounded-3xl"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="role"
            control={control}
            rules={{ required: "Role is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col w-1/2">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Role
                </label>
                <div className="flex gap-4 my-4">
                  <div className="flex">
                    <RadioButton
                      inputId="Admin"
                      name="role"
                      value={"Admin"}
                      checked={field.value === "Admin"}
                      onChange={(e) => field.onChange(e.target.value)}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} mr-1`}
                      inputRef={field.ref}
                    />
                    <label htmlFor="Admin" className="ml-1 mr-3">
                      Admin
                    </label>
                  </div>
                  <div className="flex">
                    <RadioButton
                      inputId="Technician"
                      name="role"
                      value={"Technician"}
                      onChange={(e) => field.onChange(e.target.value)}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} mr-1`}
                      checked={field.value === "Technician"}
                    />
                    <label htmlFor="Technician" className="ml-1 mr-3">
                      Technician
                    </label>
                  </div>
                </div>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <div className="flex items-center justify-center">
            <Button
              type="submit"
              icon={(loading || technicianLoading) && "pi pi-spin pi-spinner"}
              label={loading || technicianLoading ? "" : "Login"}
              className="mt-2 bg-[#C4E1D8] text-[#165720] font-bold text-xl rounded-xl border-none py-2 px-6"
              style={{
                boxShadow:
                  "0px 2.973879814147949px 2.973879814147949px 2.23041033744812px #00000040",
              }}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
