import { Link } from "react-router-dom";

import styles from "./ClientTreatmentTable.module.css";
import ClientTreatmentTableContent from "./ClientTreatmentTableContent";
import Header from "../Header";

const ClientTreatmentTable = () => {
  return (
    <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Tree Treatment"} />
          <div className={`w-full h-full rounded-2xl m-2 px-2`}>
            <ClientTreatmentTableContent />
          </div>
          <div className="flex items-start justify-center">
            <div className="flex items-center w-1/2 gap-8">
              <Link
                to={"/send-treatment"}
                className="bg-[#C4E1D8] p-3 shadow-xl rounded-2xl text-bold text-xl text-center leading-5 w-full text-[#165720]"
                style={{
                  boxShadow:
                    "0px 3.165938377380371px 3.165938377380371px 2.3744542598724365px #00000040",
                }}
              >
                Send Treatment
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientTreatmentTable;
