import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../../utils/apiURL";

export const tasksApi = createApi({
  reducerPath: "tasksApi",
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({

    baseUrl: apiUrl,

    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        // include token in req header
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllTasks: builder.query({ query: () => `tasks` }),
    getTask: builder.query({ query: (id) => `tasks/${id}` }),
    getTaskByStatus: builder.query({
      query: ({ status, adminId, typeTasks }) =>
        `/admins/${adminId}/${typeTasks}/?status=${status}`,
    }),
    getTaskByStatusAdmin: builder.query({
      query: ({ status }) => `tasks/?status=${status}`,
    }),
  }),
});

export const {
  useGetTaskQuery,
  useGetAllTasksQuery,
  useGetTaskByStatusQuery,
  useGetTaskByStatusAdminQuery,
} = tasksApi;
