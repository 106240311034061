import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";

import { useNavigate } from "react-router-dom";
import { useGetCashOperationQuery } from "../../services/operation/operationServices";
import { Column } from "primereact/column";
import { formatCurrency } from "../../utils/formatCurrency";
import { Message } from "primereact/message";

const CashTreatmentTableContent = () => {
  const navigate = useNavigate();
  const {
    data: allOperationData,
    isLoading,
    error,
  } = useGetCashOperationQuery("operationsDetails", {
    refetchOnMountOrArgChange: true,
  });

  console.log({ allOperationData: allOperationData?.data });

  const header = () => {
    return (
      <div className="flex items-center justify-between bg-none">
        <div className="flex items-center justify-between w-full">
          <div></div>
          <div>
            <Button
              icon="pi pi-cog"
              label="Operations"
              className="mr-3 text-white bg-gray-500 border-none rounded-xl"
              size="small"
              onClick={() => {
                navigate("/operation");
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const headerTemplate = (data) => {
    return (
      <div className="flex items-center bg-[#FFEDED] justify-start w-full gap-2 rounded-xl border-[#C4E1D8] border-2">
        <span className="p-4 font-bold ">
          {data?.technician_name ? data?.technician_name : "No Date Yet"}
        </span>
      </div>
    );
  };

  const currencyCashBodyTemplate = (data) => {
    const { operation } = data;
    return (
      <div className="py-4">
        {formatCurrency(operation?.total_amount_by_cash)}
      </div>
    );
  };

  const currencyCreditCardBodyTemplate = (data) => {
    const { operation } = data;
    return (
      <div className="py-4">
        {formatCurrency(operation?.total_amount_by_credit_card)}
      </div>
    );
  };
  const currencyZelleBodyTemplate = (data) => {
    const { operation } = data;
    return (
      <div className="py-4">
        {formatCurrency(operation["total_amount_by_check/zelle"])}
      </div>
    );
  };

  return (
    <div className="flex justify-center h-full admin-group-table">
      {error && (
        <Message
          severity="error"
          text="An error occurred while fetching the data."
        />
      )}
      <DataTable
        value={allOperationData?.data}
        loading={isLoading}
        scrollHeight="58vh"
        scrollable
        dataKey="fake_id"
        paginator
        className="w-full"
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        header={header}
        rowGroupMode="subheader"
        groupRowsBy="technician_name"
        sortMode="single"
        sortField="technician_name"
        sortOrder={1}
        rowGroupHeaderTemplate={headerTemplate}
      >
        <Column header="Date" field="operation.date_of_tech_visit" />
        <Column
          header="Total Amount - Cash"
          field="operation.total_amount"
          body={currencyCashBodyTemplate}
        />
        <Column
          header="Total Amount - Credit Card"
          field="operation.total_amount"
          body={currencyCreditCardBodyTemplate}
        />
        <Column
          header="Total Amount - Check/Zelle"
          field="operation.total_amount"
          body={currencyZelleBodyTemplate}
        />
      </DataTable>
    </div>
  );
};

export default CashTreatmentTableContent;
