import { createSlice } from "@reduxjs/toolkit";

import {
  assignTechnician,
  updateAddNoteCustomer,
  updateAddNoteOperation,
  updateAddNoteOrder,
} from "./operationActions";

const initialState = {
  assignTechnician: false,
  updateCustomerLoading: false,
  updateOperationLoading: false,
  updateOrderLoading: false,
};

const treatmentSlice = createSlice({
  name: "operation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAddNoteOperation.pending, (state) => {
        state.updateOperationLoading = true;
      })
      .addCase(updateAddNoteOperation.fulfilled, (state) => {
        state.updateOperationLoading = false;
      })
      .addCase(updateAddNoteOperation.rejected, (state) => {
        state.updateOperationLoading = false;
      })
      .addCase(updateAddNoteCustomer.pending, (state) => {
        state.updateCustomerLoading = true;
      })
      .addCase(updateAddNoteCustomer.fulfilled, (state) => {
        state.updateCustomerLoading = false;
      })
      .addCase(updateAddNoteCustomer.rejected, (state) => {
        state.updateCustomerLoading = false;
      })
      .addCase(updateAddNoteOrder.pending, (state) => {
        state.updateOrderLoading = true;
      })
      .addCase(updateAddNoteOrder.fulfilled, (state) => {
        state.updateOrderLoading = false;
      })
      .addCase(updateAddNoteOrder.rejected, (state) => {
        state.updateOrderLoading = false;
      })
      .addCase(assignTechnician.pending, (state) => {
        state.assignTechnician = true;
      })
      .addCase(assignTechnician.fulfilled, (state) => {
        state.assignTechnician = false;
      })
      .addCase(assignTechnician.rejected, (state) => {
        state.assignTechnician = false;
      });
  },
});

export default treatmentSlice.reducer;
