import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";

import { updateTreatment } from "../../features/treatment/treatmentActions";
import { instance } from "../../axios/axios";
import { InputTextarea } from "primereact/inputtextarea";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";

const TreatmentEdit = ({
  setEditTreatmentVisible,
  editTreatmentId,
  editTreatmentVisible,
  refetch,
  toast,
}) => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [treatmentData, setTreatmentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async (id) => {
      setIsLoading(true);
      setError(null);
      if (id) {
        try {
          const response = await instance.get(`/treatments/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setTreatmentData(response.data.data);
        } catch (error) {
          console.error("Error fetching treatment data:", error);
          // Handle error
          setError(error);
        } finally {
          setIsLoading(false);
        }
      }
    };
    fetchData(editTreatmentId);
  }, [editTreatmentId, token]);

  const services = [
    { name: "Wrapping", code: "Wrapping" },
    { name: "Unwrapping", code: "Unwrapping" },
    { name: "Spring", code: "Spring" },
    { name: "Mid Summer", code: "Mid Summer" },
    { name: "Pre Winter", code: "Pre Winter" },
  ];

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();

  useEffect(() => {
    if (treatmentData) {
      reset({
        title: treatmentData.title || "",
        date: new Date(treatmentData.date) || null,
        service:
          { name: treatmentData.service, code: treatmentData.service } || "",
        season: treatmentData.season || "",
        general_tree_price: treatmentData.general_tree_price || "",
        first_tree_price: treatmentData.first_tree_price || "",
        additional_price: treatmentData.additional_price || "",
        description: treatmentData.description || "",
        is_active: treatmentData.is_active,
        subject: treatmentData.subject || " ",
        form_text: treatmentData.form_text || "",
      });
    }
  }, [reset, treatmentData]);

  const serviceField = watch("service");

  const getFormErrorMessage = (name) => {
    return errors[name] ? (
      <small className="p-error">{errors[name].message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const { updateLoading } = useSelector((state) => state.treatment);

  const onEdit = (data) => {
    const {
      date,
      service,
      season,
      general_tree_price,
      first_tree_price,
      additional_price,
      description,
      is_active,
      subject,
      form_text,
    } = data;

    const formattedDate = date?.getFullYear();

    const obj = {
      id: editTreatmentId,
      title: `${service.name} - ${formattedDate} - ${season} `,
      date: formattedDate.toString(),
      service: service.name,
      season: season,
      description,
      is_active,
      subject,
      form_text,
    };

    if (service.name === "Wrapping" || service.name === "Unwrapping") {
      obj.general_tree_price = Number(general_tree_price);
    } else {
      obj.first_tree_price = Number(first_tree_price);
      obj.additional_price = Number(additional_price);
    }

    dispatch(updateTreatment(obj)).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        setEditTreatmentVisible(false);
        reset();
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  return (
    <Dialog
      header="Edit Treatment"
      visible={editTreatmentVisible}
      style={{ width: "45vw" }}
      onHide={() => setEditTreatmentVisible(false)}
    >
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onEdit)} className="my-8">
          <Controller
            name="date"
            control={control}
            rules={{ required: "Date is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Year
                </label>
                <span className="p-float-label">
                  <Calendar
                    dateFormat="yy"
                    view="year"
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full`}
                    onChange={(e) => field.onChange(e.target.value)}
                    inputClassName="rounded-xl"
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="service"
            control={control}
            rules={{ required: "Service is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Service
                </label>
                <span className="p-float-label">
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={services}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="season"
            control={control}
            rules={{ required: "Title is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Title
                </label>
                <span className="p-float-label">
                  <InputText
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="subject"
            control={control}
            rules={{ required: "Subject is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Subject
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    maxLength={70}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  <p>{field?.value?.length}/70 characters used</p>
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{ required: "Description of Email is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Email
                </label>
                <span className="my-2 p-float-label">
                  <p className="text-white">Hello dear customer,</p>
                  <InputTextarea
                    id={field.name}
                    value={field.value}
                    maxLength={600}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    rows={5}
                    cols={30}
                    placeholder="Description"
                  />
                  <p className="text-white">{`{{ formLink }}`}</p>
                  <p className="text-white">Thank you, </p>
                  <p className="text-white">Kevin</p>
                </span>
                <p>{field?.value?.length}/600 characters used</p>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          <Controller
            name="form_text"
            control={control}
            rules={{ required: "Description of Form is required." }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Form
                </label>
                <span className="p-float-label">
                  <InputTextarea
                    id={field.name}
                    value={field.value}
                    maxLength={1000}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.target.value)}
                    rows={5}
                    cols={30}
                    placeholder="Description"
                  />
                  <p>{field?.value?.length}/1000 characters used</p>
                </span>
                {getFormErrorMessage(field.name)}
              </div>
            )}
          />
          {serviceField?.name === "Wrapping" ||
          serviceField?.name === "Unwrapping" ? (
            <Controller
              name="general_tree_price"
              control={control}
              rules={{ required: "Tree Price is required." }}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={classNames({ "p-error": errors.value })}
                  >
                    Tree Price
                  </label>
                  <span className="p-float-label">
                    <InputNumber
                      min={1}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onValueChange={(e) => field.onChange(e.target.value)}
                    />
                  </span>
                  {getFormErrorMessage(field.name)}
                </div>
              )}
            />
          ) : serviceField?.name === "Spring" ||
            serviceField?.name === "Mid Summer" ||
            serviceField?.name === "Pre Winter" ? (
            <>
              <Controller
                name="first_tree_price"
                control={control}
                rules={{ required: "First Tree Price is required." }}
                render={({ field, fieldState }) => (
                  <div className="flex flex-col">
                    <label
                      htmlFor={field.name}
                      className={classNames({ "p-error": errors.value })}
                    >
                      First Tree Price
                    </label>
                    <span className="p-float-label">
                      <InputNumber
                        min={1}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        id={field.name}
                        value={field.value}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} w-full rounded-xl`}
                        onValueChange={(e) => field.onChange(e.target.value)}
                      />
                    </span>
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
              <Controller
                name="additional_price"
                control={control}
                rules={{ required: "Additional Trees Price is required." }}
                render={({ field, fieldState }) => (
                  <div className="flex flex-col">
                    <label
                      htmlFor={field.name}
                      className={classNames({ "p-error": errors.value })}
                    >
                      Additional Trees Price
                    </label>
                    <span className="p-float-label">
                      <InputNumber
                        min={1}
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        id={field.name}
                        value={field.value}
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} w-full rounded-xl`}
                        onValueChange={(e) => field.onChange(e.target.value)}
                      />
                    </span>
                    {getFormErrorMessage(field.name)}
                  </div>
                )}
              />
            </>
          ) : (
            <></>
          )}
          <Controller
            name="is_active"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={classNames({ "p-error": errors.value })}
                >
                  Active
                </label>
                <div className="flex gap-4 my-4 flex-wrap">
                  <div className="flex">
                    <RadioButton
                      inputId="Active"
                      name="isActive"
                      value={true}
                      checked={field.value === true}
                      onChange={() => field.onChange(true)}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} mr-1`}
                      inputRef={field.ref}
                    />
                    <label htmlFor="Active" className="ml-1 mr-3">
                      Yes
                    </label>
                  </div>
                  <div className="flex">
                    <RadioButton
                      inputId="inActive"
                      name="isActive"
                      value={false}
                      onChange={() => field.onChange(false)}
                      checked={field.value === false}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} mr-1`}
                    />
                    <label htmlFor="inActive" className="ml-1 mr-3">
                      No
                    </label>
                  </div>
                </div>
                {/* <span>
                    <InputText
                      id={field.name}
                      value={field.value}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  </span> */}
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <div className="flex items-center justify-center">
            <Button
              label={"Submit"}
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              type="submit"
              icon={"pi pi-check"}
              loading={updateLoading}
            />
          </div>
        </form>
      )}
      {error && (
        <div className="flex items-center justify-center w-full h-full">
          <p>{error.message}</p>
        </div>
      )}
    </Dialog>
  );
};

export default TreatmentEdit;
