import { Dialog } from "primereact/dialog";

import { useGetTreatmentQuery } from "../../services/treatment/treatmentServices";
import { useGetTaskQuery } from "../../services/tasks/tasksServices";

const TaskDetails = ({
    TaskVisible,
    setTaskVisible,
    id
}) => {

    const { data, isLoading, error } = useGetTaskQuery(id, {
        refetchOnMountOrArgChange: true,
      });
  return (
    <Dialog
      header="Task Details"
      visible={TaskVisible}
      style={{ width: "50vw" }}
      onHide={() => setTaskVisible(false)}
      className={`showDialog`}
    >
     <p>{data?.data[0]?.notes}</p>     
    </Dialog>
  );
};

export default TaskDetails;
