import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import EditTask from "./EditTask";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useGetTaskByStatusQuery } from "../../services/tasks/tasksServices";
import { deleteTask } from "../../features/tasks/tasksActions";
import { useDispatch } from "react-redux";
import TaskDetails from "./TaskDetails";
import AddTask from "./AddTask";

const OrderTable = ({ status, adminId, typeTasks }) => {
  const dispatch = useDispatch();
  const [editProductId, setEditProductId] = useState(null);
  const [TaskId, setTaskId] = useState(null);
  const [TaskVisible, setTaskVisible] = useState(false);
  const [editProductVisible, setEditProductVisible] = useState(false);
  const [addProductVisible, setAddProductVisible] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const { data, refetch, error, isLoading } = useGetTaskByStatusQuery(
    { status, adminId, typeTasks },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const toast = useRef(null);
  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Rejected",
      detail: "You have rejected",
      life: 3000,
    });
  };
  const confirm2 = (id) => {
    confirmDialog({
      message: "Do you want to delete this record?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      defaultFocus: "reject",
      acceptClassName: "p-button-danger",
      accept: () => removeProduct(id),
      reject,
    });
  };
  const removeProduct = (id) => {
    dispatch(deleteTask({ task_id: id })).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };
  const onShow = (id) => {
    setTaskVisible(true);
    setTaskId(id);
  };
  const onEdit = (id) => {
    setEditProductId(id);
    setEditProductVisible(true);
  };
  const actionBodyTemplate = (rowData) => {
    const { id } = rowData;

    return (
      <div className="flex items-center justify-around">
        <Button
          icon="pi pi-pencil"
          rounded
          text
          size="small"
          onClick={() => onEdit(id)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          size="small"
          onClick={() => confirm2(id)}
        />
        <Button
          icon="pi pi-ellipsis-h"
          rounded
          text
          severity="secondary"
          size="small"
          onClick={() => onShow(id)}
        />
      </div>
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
  };

  const header = () => {
    return (
      <div className="flex items-center justify-between bg-none">
        <div className="flex">
          <span className="mr-2 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm rounded-xl"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
        <Button
          icon="pi pi-plus"
          label="Add Task"
          className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
          size="small"
          onClick={() => setAddProductVisible(true)}
        />
      </div>
    );
  };

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p>{error?.data?.message}</p>
      </div>
    );
  }

  return (
    <div>
      {TaskId && (
        <TaskDetails
          setTaskVisible={setTaskVisible}
          TaskVisible={TaskVisible}
          id={TaskId}
        />
      )}
      {editProductId && (
        <EditTask
          editTaskId={editProductId}
          editProductVisible={editProductVisible}
          setEditProductVisible={setEditProductVisible}
          refetch={refetch}
          toast={toast}
        />
      )}

      <AddTask
        addProductVisible={addProductVisible}
        setAddProductVisible={setAddProductVisible}
        refetch={refetch}
        toast={toast}
      />

      <ConfirmDialog />
      <Toast ref={toast} />

      <DataTable
        value={data?.data}
        dataKey="id"
        scrollable
        scrollHeight="70vh"
        header={header}
        globalFilter={globalFilterValue}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        loading={isLoading}
      >
        <Column style={{ minWidth: "200px" }} field="name" header="Name" />
        <Column style={{ minWidth: "200px" }} field="status" header="Status" />
        <Column
          style={{ minWidth: "200px" }}
          field={(rowData) => rowData?.created_by?.first_name}
          header="Created by"
        />
        <Column
          style={{ minWidth: "200px" }}
          field={(rowData) => rowData?.assigned_to?.first_name}
          header="Assigned to"
        />
        <Column
          style={{ minWidth: "200px" }}
          field={(rowData) => rowData?.customer?.full_name}
          header="Customer"
        />
        <Column
          body={actionBodyTemplate}
          style={{ minWidth: "200px" }}
          header="Actions"
        />
      </DataTable>
    </div>
  );
};

export default OrderTable;
