import React, { useState, useEffect, useRef } from "react";
import { fetchCoordinatesFromGeoCode } from "../../services/map/addressToCoordinates";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import OrderDetailsDialog from "../../components/Orders/OrderDetailsDialog";
import { Toast } from "primereact/toast";
const MapOrderTechnician = ({ orderData }) => {
  console.log(orderData);
  const [markers, setMarkers] = useState([{ lat: 0, lng: 0 }]);
  const [selectedMapOption, setSelectedMapOption] = useState();
  const [filteredOperationData, setFilteredOperationData] = useState([]);
  const [editOrderId, setEditOrderId] = useState(null);
  const [orderDetailsVisible, setOrderDetailsVisible] = useState(false);
  const toast = useRef(null);
  const getPinColor = (orderDeliveryStatus, id) => {
    switch (orderDeliveryStatus) {
      case "Need to Deliver":
        return "#E12958";
      case "Need to Deliver (Tropicals)":
        return "#E12958";
      case "Delivery Schedule":
        return "#22C55E";
      default:
        return null;
    }
  };

  const editOrderDialog = (status, id, lat, lng) => {
    // console.log(`mn el dialog ${(status, id)}`);
    setEditOrderId(id);
    setOrderDetailsVisible(true);
  };

  useEffect(() => {
    // switch (selectedMapOption) {
    //   case "today":
    //     for (let i = 0; i < orderData?.data.length; i++) {
    //       if (
    //         todayFormattedDate === orderData?.data[i].order_delivery.estimated_delivery_date
    //       ) {
    //         filteredOperationData.push(orderData?.data[i]);
    //       } else {
    //         // console.log(orderData.data[i].order_delivery.estimated_delivery_date);
    //       }
    //     }
    //     fetchCoordinatesFromGeoCode(filteredOperationData).then((response) => {
    //       if(response.length!==0){
    //         setMarkers(response);
    //       }else{
    //         setMarkers([{ lat: 0, lng: 0 }]);
    //       }
    //       // console.log(`mn today ${markers}`);
    //       // console.log({ markers });
    //     });
    //     // console.log(filteredOperationData);
    //     break;
    //   case "thisWeek":
    //     for (let i = 0; i <= 6; i++) {
    //       const today = new Date();
    //       const currentDate = new Date(today);
    //       currentDate.setDate(currentDate.getDate() + i);
    //       const formattedCurrentDate = currentDate.toLocaleDateString("en-US", {
    //         month: "2-digit",
    //         day: "2-digit",
    //         year: "numeric",
    //       });
    //       // console.log(formattedCurrentDate);
    //       for (let i = 0; i < orderData.data.length; i++) {
    //         if (
    //           formattedCurrentDate ===
    //           orderData.data[i].order_delivery.estimated_delivery_date
    //         ) {
    //           filteredOperationData.push(orderData.data[i]);
    //         } else {
    //           // console.log(
    //           //   `Specific date is different from ${currentDate.toLocaleDateString()}.`
    //           // );
    //         }
    //       }
    //     }
    //     fetchCoordinatesFromGeoCode(filteredOperationData).then((response) => {
    //       if(response.length!==0){
    //         setMarkers(response);
    //       }else{
    //         setMarkers([{ lat: 0, lng: 0 }]);
    //       }
    //       // console.log(`mn this week ${markers}`);
    //       // console.log(markers);
    //     });
    //     // console.log(filteredOperationData);
    //     break;
    //   case "schedule":
    //     for (let i = 0; i < orderData?.data.length; i++) {
    //       if (orderData?.data[i].order_delivery.estimated_delivery_date === "") {
    //         filteredOperationData.push(orderData?.data[i]);
    //       } else {
    //         // console.log(orderData.data[i].date_of_tech_visit);
    //       }
    //     }
    //     fetchCoordinatesFromGeoCode(filteredOperationData).then((response) => {
    //       if(response.length!==0){
    //         setMarkers(response);
    //       }else{
    //         setMarkers( [{ lat: 0, lng: 0 }]);
    //       }
    //       // console.log(`mn schedule ${markers}`);
    //       // console.log(markers);
    //     });
    //     // console.log(filteredOperationData);
    //     break;
    //   case "allOrders":
    //     // console.log(orderData.allOrderData.data)
    //     fetchCoordinatesFromGeoCode(orderData.allOrderData.data).then((response) => {
    //       if(response.length!==0){
    //         setMarkers(response);
    //       }else{
    //         setMarkers( [{ lat: 0, lng: 0 }]);
    //       }
    //       // console.log(`mn schedule ${markers}`);
    //       // console.log(markers);
    //     });
    //     // console.log(filteredOperationData);
    //     break;
    //   default:
    //     fetchCoordinatesFromGeoCode(orderData?.data).then((response) => {
    //       if(response.length!==0){
    //         setMarkers(response);
    //       }else{
    //         setMarkers( [{ lat: 0, lng: 0 }]);
    //       }
    //       // console.log(`mn all dates ${markers}`);
    //     });
    //      // console.log(orderData.allOrderData.data)

    // }
    fetchCoordinatesFromGeoCode(orderData?.allOrderData?.data).then(
      (response) => {
        if (response.length !== 0) {
          setMarkers(response);
        } else {
          setMarkers([{ lat: 0, lng: 0 }]);
        }
        // console.log(`mn schedule ${markers}`);
        // console.log(markers);
      }
    );
    // console.log(filteredOperationData);
  }, [orderData, selectedMapOption]);
  // console.log(markers);
  const currentDate = new Date();
  const todayFormattedDate = currentDate.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  //   function handleSelection(e) {
  //     setSelectedMapOption(e.target.value);
  //     setFilteredOperationData([]);
  //   }
  const mapContainerStyle = {
    width: "90vw",
    height: "70vh",
  };
  const center = {
    lat: markers[0]?.lat,
    lng: markers[0]?.lng,
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCRVh8i3NvcSTjn9eZ3avLuP4eapTrXpG4",
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const defaultMarkerOptions = {
    openInfoWindowByDefault: true,
  };

  return (
    <div>
      <Toast ref={toast} />
      {/* <div>
        <select
          id="dateOptions"
          className="h-10 mb-3 text-xl w-60 rounded-xl"
          onChange={(e) => {
            handleSelection(e);
          }}
        >
          <option value="allDates">All Dates</option>
          <option value="allOrders">All Orders</option>
          <option value="today">Today</option>
          <option value="thisWeek">This Week</option>
          <option value="schedule">Need to Schedule</option>
        </select>
      </div> */}
      {!markers ? (
        <div className="flex justify-center w-full h-full align-middle">
          <i className="text-3xl font-extrabold pi pi-spin pi-spinner"></i>
        </div>
      ) : (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={8}
          center={center}
          defaultOptions={{ markerOptions: defaultMarkerOptions }}
        >
          {markers?.map((marker, index) => (
            <MarkerF
              key={index}
              position={{ lat: marker?.lat, lng: marker?.lng }}
              icon={{
                path: "M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482C346.693,59.825,286.846,0,213.285,0zM274.865,136.62c0,34.124-27.761,61.884-61.885,61.884c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z",
                fillColor: getPinColor(marker.orderDeliveryStatus, marker.id),
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
                scale: 0.08,
              }}
              onClick={() => {
                editOrderDialog(
                  marker.status,
                  marker.id,
                  marker.lat,
                  marker.lng
                );
              }}
            />
          ))}
        </GoogleMap>
      )}
      {editOrderId && (
        <OrderDetailsDialog
        visible={orderDetailsVisible}
        setVisible={setOrderDetailsVisible}
        setOrderId={setEditOrderId}
        id={editOrderId}
      />
      )}
    </div>
  );
};

export default MapOrderTechnician;
