/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
// import { useSelector } from "react-redux";
import Header from "../Header";
import styles from "./Map.module.css";
import MapService from "../../services/map/map";
// import { useGetAllOperationsQuery } from "../../services/operation/operationServices";
import { useGetAllActiveOperationsQuery } from "../../services/operation/operationServices";
// import { useGetAllOperationForTechnicianQuery } from "../../services/operation/operationForTechnician";
// import { jwtDecode } from "jwt-decode";

const Map = () => {
  const {
    data: allOperationDataAdmin,
    isLoading,
    error,
    refetch,
  } = useGetAllActiveOperationsQuery("operationsDetails", {
    refetchOnMountOrArgChange: true,
  });
  console.log({
    data: allOperationDataAdmin,
    isLoading,
    error,
    refetch,
  });

  // const {
  //   data :allOperationDataTechnician,
  //   isLoading: operationTechnicianLoading,
  //   error: operationTechnicianError,
  //   refetchData,
  // } = useGetAllOperationForTechnicianQuery(decodedToken.id, {
  //   refetchOnMountOrArgChange: true,
  // });
  // const showedMap = () => {
  //   if (decodedToken.role === "superadmin"){
  //     return (
  //       <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
  //       <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
  //         <div className="flex flex-col justify-between h-full">
  //           <Header title={"Map"} />
  //           <div className="w-full h-full m-2 rounded-2xl">
  //               <MapService allOperationData = {allOperationDataAdmin} refetch={refetch} />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  //   }else{
  //     return (
  //       <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
  //       <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
  //         <div className="flex flex-col justify-between h-full">
  //           <Header title={"Map"} />
  //           <div className="w-full h-full m-2 rounded-2xl">
  //               <MapService allOperationData = {allOperationDataTechnician} refetch={refetchData} />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  //   }
  // }

  return (
    <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Map"} />
          <div className="flex justify-center">
            <MapService
              allOperationData={allOperationDataAdmin}
              refetch={refetch}
              role="admin"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
