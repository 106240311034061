import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../axios/axios";

export const addDelivery = createAsyncThunk(
  "delivery/create",
  async (
    {
      order_id,
      estimated_delivery_date,
      scheduled_delivery_date,
      delivery_status,
      delivery_notes,
      delivery_sequence,
      date_delivered,
      delivery_fee,
      picture_of_delivery,
      truck_number,
      expected_date_of_pickup,
    },
    { rejectWithValue, getState }
  ) => {
    const token = getState().auth.token
      ? getState().auth.token
      : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status } = await instance.post(
        `order/${order_id}/delivery`,
        {
          estimated_delivery_date,
          scheduled_delivery_date,
          delivery_status,
          delivery_notes,
          delivery_sequence,
          date_delivered,
          delivery_fee,
          picture_of_delivery,
          truck_number,
          expected_date_of_pickup,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateDelivery = createAsyncThunk(
  "delivery/update",
  async (
    {
      id,
      order_id,
      estimated_delivery_date,
      scheduled_delivery_date,
      delivery_status,
      delivery_notes,
      delivery_sequence,
      date_delivered,
      delivery_fee,
      picture_of_delivery,
      truck_number,
      expected_date_of_pickup,
    },
    { rejectWithValue, getState }
  ) => {
    const token = getState().auth.token
    ? getState().auth.token
    : getState().auth.technicianToken;
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data, status } = await instance.patch(
        `orderdelivery/${id}`,
        {
          order_id,
          estimated_delivery_date,
          scheduled_delivery_date,
          delivery_status,
          delivery_notes,
          delivery_sequence,
          date_delivered,
          delivery_fee,
          picture_of_delivery,
          truck_number,
          expected_date_of_pickup,
        },
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteDelivery = createAsyncThunk(
  "delivery/delete",
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      };
      const { data, status } = await instance.delete(
        `orderdelivery/${id}`,
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const contactAgainCustomer = createAsyncThunk(
  "customer/contactAgain",
  async ({ id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data, status } = await instance.put(
        `customers/${id}/donot_contact_again`,
        {},
        config
      );
      return { data, status };
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
