import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { logout } from "../features/auth/authSlice";

const Header = ({ title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogOut = () => {
    dispatch(logout());
    navigate("/login");
  };
  return (
    <div className="flex items-center justify-between mx-8 border-b border-[#BCAD67] mb-2 pb-4">
      <p className="font-bold text-4xl leading-9 text-[#165720]">{title}</p>
      <button
        className="flex items-center gap-2 text-[#165720]"
        onClick={handleLogOut}
      >
        <span>LogOut</span>
        <i className="pi pi-sign-out"></i>
      </button>
    </div>
  );
};

export default Header;
