import { format } from "date-fns";

import styles from "./Orders.module.css";

import { formatCurrency } from "./../../utils/formatCurrency";
import { BigContainer } from "../ClientTreatment/OperationDialog";

const OrderDetails = ({ data }) => {
  const calculateTotal = () => {
    return data?.order_line_items?.reduce(
      (total, item) => total + item.total_price,
      0
    );
  };
  const formatDateToAmerican = (isoDate) => {
    // Parse and format the date using date-fns
    return format(new Date(isoDate), "MM/dd/yyyy");
  };
  const Cash = () => (
    <>
      <BigContainer
        titleOne={"Mode Of Payment"}
        valueOne={data?.mode_of_payment}
        titleTwo={"Order (Unpaid Amount)"}
        valueTwo={formatCurrency(data?.order_unpaid_amount)}
      />
      <div className={styles.detailsContainerDiv}>
        <h3 className={styles.detailsTitle}>Discount</h3>
        <p className={styles.detailsContent}>
          {formatCurrency(data?.discount)}
        </p>
      </div>
    </>
  );

  const CreditCard = () => (
    <>
      <BigContainer
        titleOne={"Mode Of Payment"}
        valueOne={data?.mode_of_payment}
        titleTwo={"Order (Unpaid Amount)"}
        valueTwo={formatCurrency(data?.order_unpaid_amount)}
      />
      <BigContainer
        titleOne={"Order (Unpaid Amount After Taxes)"}
        valueOne={formatCurrency(data?.order_unpaid_amount_after_taxes)}
        titleTwo={"Order (Unpaid Amount After Processing)"}
        valueTwo={formatCurrency(data?.order_unpaid_amount_after_processing)}
      />
      <BigContainer
        titleOne={"Order Total (Before Taxes/Fees)"}
        valueOne={formatCurrency(
          data?.order_invoice?.invoice_total_before_taxes_fees
        )}
        titleTwo={"Order Total (After Taxes)"}
        valueTwo={formatCurrency(
          data?.order_invoice?.invoice_total_after_taxes
        )}
      />
      <BigContainer
        titleTwo={"Order Total"}
        valueTwo={formatCurrency(data?.order_invoice?.invoice_total)}
        titleOne={"Order Total (After Processing Fees)"}
        valueOne={formatCurrency(
          data?.order_invoice?.invoice_total_after_processing_fees
        )}
      />
      <div className={styles.detailsContainerDiv}>
        <h3 className={styles.detailsTitle}>Discount</h3>
        <p className={styles.detailsContent}>
          {formatCurrency(data?.discount)}
        </p>
      </div>
    </>
  );

  const CashDepositCreditCardBalance = () => (
    <>
      <BigContainer
        titleOne={"Mode Of Payment"}
        valueOne={data?.mode_of_payment}
        titleTwo={"Deposit"}
        valueTwo={formatCurrency(data?.deposit)}
      />
      <BigContainer
        titleTwo={"Order (Unpaid Amount)"}
        valueTwo={formatCurrency(data?.order_unpaid_amount)}
        titleOne={"Order (Unpaid Amount After Taxes)"}
        valueOne={formatCurrency(data?.order_unpaid_amount_after_taxes)}
      />
      <BigContainer
        titleOne={"Order (Unpaid Amount After Processing)"}
        valueOne={formatCurrency(data?.order_unpaid_amount_after_processing)}
        titleTwo={"Remaining (After Deposit)"}
        valueTwo={formatCurrency(data?.remaining_after_deposit)}
      />
      <BigContainer
        titleTwo={"Deposit (After Taxes)"}
        valueTwo={formatCurrency(data?.remaining_after_deposit_after_taxes)}
        titleOne={"Remaining (After Deposit After Fees)"}
        valueOne={formatCurrency(data?.remaining_after_deposit_after_fees)}
      />
      <div className={styles.detailsContainerDiv}>
        <h3 className={styles.detailsTitle}>Discount</h3>
        <p className={styles.detailsContent}>
          {formatCurrency(data?.discount)}
        </p>
      </div>
    </>
  );

  const CreditCardDepositCashBalance = () => (
    <>
      <BigContainer
        titleOne={"Mode Of Payment"}
        valueOne={data?.mode_of_payment}
        titleTwo={"Deposit"}
        valueTwo={formatCurrency(data?.deposit)}
      />
      <BigContainer
        titleTwo={"Deposit (After Taxes)"}
        valueTwo={formatCurrency(data?.deposit_after_taxes)}
        titleOne={"Deposit (After Processing Fees)"}
        valueOne={formatCurrency(data?.deposit_after_processing_fees)}
      />
      <BigContainer
        titleTwo={"Order (Unpaid Amount)"}
        valueTwo={formatCurrency(data?.order_unpaid_amount)}
        titleOne={"Order (Unpaid Amount After Taxes)"}
        valueOne={formatCurrency(data?.order_unpaid_amount_after_taxes)}
      />
      <BigContainer
        titleOne={"Order (Unpaid Amount After Processing)"}
        valueOne={formatCurrency(data?.order_unpaid_amount_after_processing)}
        titleTwo={"Remaining (After Deposit)"}
        valueTwo={formatCurrency(data?.remaining_after_deposit)}
      />
      <div className={styles.detailsContainerDiv}>
        <h3 className={styles.detailsTitle}>Discount</h3>
        <p className={styles.detailsContent}>
          {formatCurrency(data?.discount)}
        </p>
      </div>
    </>
  );

  const onOrderComponentShow = (type) => {
    switch (type) {
      case "Cash":
        return <Cash />;
      case "Credit Card":
        return <CreditCard />;
      case "Cash Deposit / Credit Card Balance":
        return <CashDepositCreditCardBalance />;
      case "Credit Card Deposit / Cash Balance":
        return <CreditCardDepositCashBalance />;

      default:
        break;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className={styles.detailsContainer}>
        <div className={styles.detailsContainerDiv}>
          <h3 className={styles.detailsTitle}>Delivery Status</h3>
          <p className={styles.detailsContent}>
            {data?.order_delivery?.delivery_status}
          </p>
        </div>
        <div className={styles.detailsContainerDiv}>
          <h3 className={styles.detailsTitle}>Payment Status</h3>
          <p className={styles.detailsContent}>
            {data?.payment_status ? data?.payment_status : data?.status}
          </p>
        </div>
      </div>
      <div className={styles.detailsContainer}>
        <div className={styles.detailsContainerDiv}>
          <h3 className={styles.detailsTitle}>Purchase / Rental</h3>
          <p className={styles.detailsContent}>{data?.order_type}</p>
        </div>
        <div className={styles.detailsContainerDiv}>
          <h3 className={styles.detailsTitle}>Type Of Order</h3>
          <p className={styles.detailsContent}>{data?.customer_type}</p>
        </div>
      </div>
      {(data?.rental_start_date || data?.rental_end_date) && (
        <div className={styles.detailsContainer}>
          <div className={styles.detailsContainerDiv}>
            <h3 className={styles.detailsTitle}>Rental Start Date</h3>
            <p className={styles.detailsContent}>
              {formatDateToAmerican(data?.rental_start_date)}
            </p>
          </div>
          <div className={styles.detailsContainerDiv}>
            <h3 className={styles.detailsTitle}>Rental End Date</h3>
            <p className={styles.detailsContent}>
              {formatDateToAmerican(data?.rental_end_date)}
            </p>
          </div>
        </div>
      )}
      <div className={styles.detailsContainer}>
        <div className={styles.detailsContainerDiv}>
          <h3 className={styles.detailsTitle}>Estimated Delivery Date</h3>
          <p className={styles.detailsContent}>
            {data?.order_delivery?.estimated_delivery_date}
          </p>
        </div>
        <div className={styles.detailsContainerDiv}>
          <h3 className={styles.detailsTitle}>Mode Of Payment</h3>
          <p className={styles.detailsContent}>{data?.mode_of_payment}</p>
        </div>
      </div>
      <div>
        <div>
          <h3 className={styles.detailsTitle}>Products Ordered</h3>
          <div className="flex flex-col gap-2">
            {data?.order_line_items?.map((item) => (
              <p className={`!w-[96%] ${styles.detailsContent}`} key={item?.id}>
                {item?.line_item_quantity} X{" "}
                {item?.item_type === "product" ? item?.product_name : "fees"} -{" "}
                {formatCurrency(item?.unit_price_override)}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.detailsContainerDiv}>
        <h3 className={styles.detailsTitle}>Total</h3>
        <p className={styles.detailsContent}>
          {formatCurrency(calculateTotal())}
        </p>
      </div>
      {onOrderComponentShow(data?.mode_of_payment)}
      {data?.technician && (
        <div className={styles.detailsContainerDiv}>
          <h3 className={styles.detailsTitle}>Assigned To</h3>
          <p className={styles.detailsContent}>
            {data?.technician?.first_name + " " + data?.technician?.last_name}
          </p>
        </div>
      )}
      <div>
        <div>
          <div>
            <h3 className={styles.detailsTitle}>Delivery Note</h3>
            <p className={`!w-[96%] ${styles.detailsContent}`}>
              {data?.order_delivery?.delivery_notes}
            </p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <h3 className={styles.detailsTitle}>Special Note</h3>
            <p className={`!w-[96%] ${styles.detailsContent}`}>
              {data?.order_delivery?.follow_up_special_notes}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
