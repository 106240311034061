import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiUrl } from "../../utils/apiURL";

export const orderLineApi = createApi({
  reducerPath: "orderLineApi",
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({

    baseUrl: apiUrl,

  }),
  endpoints: (builder) => ({
    getAllOrderLines: builder.query({ query: () => `line-items` }),
    getOrderLine: builder.query({ query: (id) => `line-items/${id}` }),
  }),
});

export const { useGetAllOrderLinesQuery, useGetOrderLineQuery } = orderLineApi;
