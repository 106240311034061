import { createSlice } from "@reduxjs/toolkit";

import {
  addTreeConditions,
  deleteTreeCondition,
  editTreeConditions,
} from "./treeConditionsActions";

const initialState = {
  addLoading: false,
  updateLoading: false,
  deleteLoading: false,
  refetchState: false,
};

const treeConditionSlice = createSlice({
  name: "treeConditions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTreeConditions.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addTreeConditions.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addTreeConditions.rejected, (state) => {
        state.addLoading = false;
      })
      .addCase(editTreeConditions.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(editTreeConditions.fulfilled, (state) => {
        state.updateLoading = false;
        state.refetchState = true;
      })
      .addCase(editTreeConditions.rejected, (state) => {
        state.updateLoading = false;
      })
      .addCase(deleteTreeCondition.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteTreeCondition.fulfilled, (state) => {
        state.deleteLoading = false;
        state.refetchState = true;
      })
      .addCase(deleteTreeCondition.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export default treeConditionSlice.reducer;
