import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";

import { formatDateToAmericanDate } from "../../utils/convertToAmericanDate";
import { updateOrder } from "../../features/order/orderActions";
import {
  addDelivery,
  updateDelivery,
} from "../../features/delivery/deliveryActions";
import { getFormErrorMessage } from "./../../utils/getFormErrorMessage";
import { formatCurrency } from "../../utils/formatCurrency";

const OrderEdit = ({ data: orderData, refetch, toast, setState }) => {
  const dispatch = useDispatch();
  const { updateLoading } = useSelector((state) => state.orders);
  const { updateLoading: updateDeliveryLoading } = useSelector(
    (state) => state.delivery
  );

  const typeOfOrderOptions = [
    { name: "Walk In", code: "Walk In" },
    { name: "Phone In", code: "Phone In" },
    { name: "Website", code: "Website" },
    { name: "Home Show", code: "Home Show" },
  ];

  const deliveryStatusOptions = [
    {
      name: "Need to Deliver (Tropicals)",
      code: "Need to Deliver (Tropicals)",
    },
    { name: "Need to Deliver", code: "Need to Deliver" },
    { name: "Took Trees", code: "Took Trees" },
    { name: "Picking Up", code: "Picking Up" },
    { name: "Delivery Schedule", code: "Delivery Schedule" },
  ];

  const modeOfPaymentOptions = [
    { name: "Cash", code: "Cash" },
    { name: "Credit Card", code: "Credit Card" },
    { name: "Check/Zelle", code: "Check" },
    {
      name: "Cash Deposit / Credit Card Balance",
      code: "Cash Deposit / Credit Card Balance",
    },
    {
      name: "Credit Card Deposit / Cash Balance",
      code: "Credit Card Deposit / Cash Balance",
    },
  ];

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm();

  const rental = watch("purchase");
  const modeOfPayment = watch("modeOfPayment");
  const estimatedDeliveryDate = watch("estimatedDeliveryDate");
  const paymentStatus = watch("paymentStatus");

  useEffect(() => {
    if (orderData) {
      reset({
        purchase:
          {
            name: orderData?.order_type,
            code: orderData?.order_type,
          } || null,
        orderDate: orderData?.order_date ? orderData?.order_date : "",
        rentalStartDate: orderData?.rental_start_date
          ? new Date(orderData?.rental_start_date)
          : "",
        rentalEndDate: orderData?.rental_end_date
          ? new Date(orderData?.rental_end_date)
          : "",
        typeOfOrder:
          {
            name: orderData?.customer_type,
            code: orderData?.customer_type,
          } || null,
        deliveryStatus: orderData?.order_delivery
          ? {
              name: orderData?.order_delivery?.delivery_status,
              code: orderData?.order_delivery?.delivery_status,
            }
          : {
              name: "Took Trees",
              code: "Took Trees",
            },
        estimatedDeliveryDate: orderData?.order_delivery
          ?.estimated_delivery_date
          ? new Date(orderData?.order_delivery?.estimated_delivery_date)
          : "",
        deliveryNote: orderData?.order_delivery?.delivery_notes,
        modeOfPayment:
          {
            name: orderData?.mode_of_payment,
            code: orderData?.mode_of_payment,
          } || null,
        paymentStatus:
          {
            name: orderData?.status,
            code: orderData?.status,
          } || null,
        deposit: orderData?.deposit || 0.0,
        discount: orderData?.discount || 0,
        customerNote: orderData?.follow_up_special_notes || "",
        orderNote: orderData?.order_notes || "",
      });
    }
  }, [orderData, reset]);

  console.log({ orderData });

  useEffect(() => {
    if (estimatedDeliveryDate) {
      setValue("deliveryStatus", {
        name: "Delivery Schedule",
        code: "Delivery Schedule",
      });
    }
  }, [estimatedDeliveryDate, setValue]);

  const onSubmit = (data) => {
    const {
      purchase,
      typeOfOrder,
      modeOfPayment,
      deposit,
      discount,
      customerNote,
      orderNote,
      estimatedDeliveryDate,
      deliveryStatus,
      deliveryNote,
      rentalStartDate,
      rentalEndDate,
    } = data;

    const formattedEstimatedDeliveryDate =
      estimatedDeliveryDate !== ""
        ? formatDateToAmericanDate(new Date(estimatedDeliveryDate))
        : "";

    const obj = {
      id: orderData?.id,
      customer_type: typeOfOrder.name,
      mode_of_payment: modeOfPayment.code,
      order_notes: orderNote,
      follow_up_special_notes: customerNote,
      order_type: purchase.name,
    };

    if (
      modeOfPayment.name === "Cash Deposit / Credit Card Balance" ||
      modeOfPayment.name === "Credit Card Deposit / Cash Balance"
    ) {
      obj.deposit = deposit;
    }

    console.log({ purchase });

    if (
      purchase.name === "Palm Trees Rental" ||
      purchase.name === "Tiki Bar Rental"
    ) {
      obj.rental_start_date = rentalStartDate;
      obj.rental_end_date = rentalEndDate;
    }

    if (deposit === "") {
      obj.deposit = 0;
    }

    if (discount === "") {
      obj.discount = 0;
    }

    const deliveryObj = {
      estimated_delivery_date: formattedEstimatedDeliveryDate,
      delivery_status: deliveryStatus.name || "Took Trees",
      delivery_notes: deliveryNote || "",
    };

    if (orderData?.payment_status !== "Paid in Full") {
      dispatch(updateOrder(obj)).then((res) => {
        console.log({ obj });
        if (res?.payload?.data?.status === "Success") {
          if (orderData?.order_delivery_id) {
            dispatch(
              updateDelivery({
                ...deliveryObj,
                order_id: orderData?.id,
                id: orderData?.order_delivery_id,
              })
            ).then((res) => {
              if (res?.payload?.data?.status === "Success") {
                setState("orderDetails");
                refetch();
                toast.current.show({
                  severity: "success",
                  summary: "Form Submitted",
                  detail: res.payload.data.message,
                  life: 3000,
                });
              }
            });
          } else {
            dispatch(
              addDelivery({ ...deliveryObj, order_id: orderData?.id })
            ).then((res) => {
              if (res?.payload?.data?.status === "Success") {
                reset();
                refetch();
                setState("orderDetails");
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Error",
                  detail: res.payload,
                  life: 3000,
                });
              }
            });
          }
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.payload,
            life: 3000,
          });
        }
      });
    } else {
      if (orderData?.order_delivery_id) {
        dispatch(
          updateDelivery({
            ...deliveryObj,
            order_id: orderData?.id,
            id: orderData?.order_delivery_id,
          })
        ).then((res) => {
          if (res?.payload?.data?.status === "Success") {
            setState("orderDetails");
            refetch();
            toast.current.show({
              severity: "success",
              summary: "Form Submitted",
              detail: res.payload.data.message,
              life: 3000,
            });
          }
        });
      } else {
        dispatch(addDelivery({ ...deliveryObj, order_id: orderData?.id })).then(
          (res) => {
            if (res?.payload?.data?.status === "Success") {
              reset();
              refetch();
              setState("orderDetails");
            } else {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: res.payload,
                life: 3000,
              });
            }
          }
        );
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="my-8">
        <>
          <Controller
            name="purchase"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Purchase / Rental / Service
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value?.name}
                    readOnly
                    // optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          {(rental?.name === "Palm Trees Rental" ||
            rental?.name === "Tiki Bar Rental") && (
            <>
              <Controller
                name="rentalStartDate"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="flex flex-col">
                    <label
                      htmlFor={field.name}
                      className={`${classNames({
                        "p-error": errors.value,
                      })} text-base text-bold`}
                    >
                      Rental Start Date
                    </label>
                    <span>
                      <Calendar
                        id={field.name}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        dateFormat="mm/dd/yy"
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} w-full rounded-xl`}
                        inputClassName="rounded-xl"
                      />
                    </span>
                    {getFormErrorMessage(field.name, errors)}
                  </div>
                )}
              />
              <Controller
                name="rentalEndDate"
                control={control}
                render={({ field, fieldState }) => (
                  <div className="flex flex-col">
                    <label
                      htmlFor={field.name}
                      className={`${classNames({
                        "p-error": errors.value,
                      })} text-base text-bold`}
                    >
                      Rental End Date
                    </label>
                    <span>
                      <Calendar
                        id={field.name}
                        value={field.value}
                        onChange={(e) => field.onChange(e.value)}
                        dateFormat="mm/dd/yy"
                        className={`${classNames({
                          "p-invalid": fieldState.error,
                        })} w-full rounded-xl`}
                        inputClassName="rounded-xl"
                      />
                    </span>
                    {getFormErrorMessage(field.name, errors)}
                  </div>
                )}
              />
            </>
          )}
          <Controller
            name="orderDate"
            control={control}
            // rules={{
            //   required: "Order Date is required.",
            // }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Order Date
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value}
                    // onChange={(e) => field.onChange(e.value)}
                    readOnly={true}
                    // dateFormat="mm/dd/yy"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    // inputClassName="rounded-xl"
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <Controller
            name="typeOfOrder"
            control={control}
            rules={{
              required: "Type of Order is required",
            }}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Type of Order
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={typeOfOrderOptions}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <div className="flex flex-col">
            <p className="text-base text-bold">Products Ordered:</p>
            <Button
              label="Order Line"
              // onClick={(e) => onShowAddOrderLine(e)}
              onClick={(e) => e.preventDefault()}
              className="rounded-xl"
            />
          </div>
          <div className="flex flex-col">
            {orderData?.order_line_items.map((item) => {
              return (
                <div
                  className="flex items-center justify-between rounded-xl bg-[#FFFFFF] p-2 my-2 border-2 border-green-900"
                  key={item.id}
                >
                  <p>
                    {item.line_item_quantity} X{" "}
                    {item.item_type === "fees"
                      ? item.item_type
                      : item.product_name}{" "}
                    - {formatCurrency(item?.unit_price_override)}
                  </p>
                  <div></div>
                </div>
              );
            })}
          </div>
          <Controller
            name="modeOfPayment"
            rules={{
              required: "Mode Of Payment is required.",
            }}
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Mode of Payment
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={modeOfPaymentOptions}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    disabled={paymentStatus?.name === "Paid in Full"}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <Controller
            name="paymentStatus"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Payment status
                </label>
                <span>
                  <InputText
                    id={field.name}
                    value={field.value?.name}
                    readOnly={true}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          {(modeOfPayment?.name === "Cash Deposit / Credit Card Balance" ||
            modeOfPayment?.name === "Credit Card Deposit / Cash Balance") && (
            <Controller
              name="deposit"
              control={control}
              render={({ field, fieldState }) => (
                <div className="flex flex-col">
                  <label
                    htmlFor={field.name}
                    className={`${classNames({
                      "p-error": errors.value,
                    })} text-base text-bold`}
                  >
                    Deposit
                  </label>
                  <span>
                    <InputNumber
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      readOnly={orderData?.deposit > 0 ? true : false}
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      inputId="currency-us"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                    />
                  </span>
                  {getFormErrorMessage(field.name, errors)}
                </div>
              )}
            />
          )}
          <Controller
            name="discount"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Discount
                </label>
                <span>
                  <InputNumber
                    readOnly
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    id={field.name}
                    value={field.value}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    onChange={(e) => field.onChange(e.value)}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <Controller
            name="customerNote"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Notes Customer Sees
                </label>
                <span>
                  <InputTextarea
                    id={field.name}
                    {...field}
                    rows={4}
                    cols={30}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    autoResize
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <Controller
            name="orderNote"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Order Followup Notes
                </label>
                <span>
                  <InputTextarea
                    id={field.name}
                    {...field}
                    rows={4}
                    cols={30}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    autoResize
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <Controller
            name="estimatedDeliveryDate"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Estimated Delivery / Pickup Date
                </label>
                <span>
                  <Calendar
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    dateFormat="mm/dd/yy"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    inputClassName="rounded-xl"
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <Controller
            name="deliveryStatus"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Delivery status
                </label>
                <span>
                  <Dropdown
                    id={field.name}
                    value={field.value}
                    onChange={(e) => field.onChange(e.value)}
                    options={deliveryStatusOptions}
                    optionLabel="name"
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          <Controller
            name="deliveryNote"
            control={control}
            render={({ field, fieldState }) => (
              <div className="flex flex-col">
                <label
                  htmlFor={field.name}
                  className={`${classNames({
                    "p-error": errors.value,
                  })} text-base text-bold`}
                >
                  Delivery Notes
                </label>
                <span>
                  <InputTextarea
                    id={field.name}
                    {...field}
                    rows={4}
                    cols={30}
                    className={`${classNames({
                      "p-invalid": fieldState.error,
                    })} w-full rounded-xl`}
                    autoResize
                  />
                </span>
                {getFormErrorMessage(field.name, errors)}
              </div>
            )}
          />
          {/* {orderData?.payment_status !== "Paid in Full" && ( */}
          <div className="flex items-center justify-center">
            <Button
              label={"Submit"}
              className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
              type="submit"
              icon={"pi pi-pencil"}
              loading={updateLoading || updateDeliveryLoading}
            />
          </div>
          {/* )} */}
        </>
      </form>
    </>
  );
};

export default OrderEdit;
