import { createSlice } from "@reduxjs/toolkit";
import { addTask, updateTask } from "./tasksActions";

const initialState = {
  addLoading: false,
  updateLoading: false,
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTask.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addTask.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addTask.rejected, (state) => {
        state.addLoading = false;
      })
      .addCase(updateTask.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateTask.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateTask.rejected, (state) => {
        state.updateLoading = false;
      });
  },
});

export default taskSlice.reducer;
