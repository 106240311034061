import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useDispatch, useSelector } from "react-redux";

import Header from "../Header";
import SendEmailSms from "./SendEmailSms";

import "./Operation.css";

import { useGetCustomersContactAgainQuery } from "../../services/customer/customerServices";
import { useGetAllProductsQuery } from "../../services/product/productServices";
import { getFilterCustomerByProduct } from "../../features/customer/customerActions";
import { getFormErrorMessage } from "./../../utils/getFormErrorMessage";

const CustomerTableContentCommu = () => {
  const [visible, setVisible] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [data, setData] = useState([]);

  const { filterCustomerLoading } = useSelector((state) => state.customer);

  const dispatch = useDispatch();

  const toast = useRef(null);

  const {
    data: filteredCustomers,
    isLoading: filteredCustomersLoading,
    refetch,
  } = useGetCustomersContactAgainQuery("filteredC", {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    setData(filteredCustomers?.data);
  }, [filteredCustomers]);

  const { data: filteredProducts, isLoading } = useGetAllProductsQuery(
    "productsDetails",
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const isAnyLoading = filteredCustomersLoading;

  const defaultValues = {
    contactIds: [],
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    const { product_id } = data;
    dispatch(getFilterCustomerByProduct({ id: product_id.code })).then(
      (res) => {
        if (res?.payload?.status === "Success") {
          setData(res?.payload?.customers);
          // reset();
        } else {
          // reset();
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: res.payload,
            life: 3000,
          });
        }
      }
    );
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
  };

  const header = () => {
    return (
      <div className="flex items-center w-full">
        <div className="flex items-center justify-between w-full gap-2 my-auto">
          <div className="w-3/12 p-2 border-l-2 border-black">
            <div className="flex items-center w-full">
              <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  className="w-full rounded-xl"
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  placeholder="Search"
                />
              </span>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row gap-2">
              <Controller
                name="product_id"
                control={control}
                rules={{ required: "Product is required." }}
                render={({ field, fieldState }) => (
                  <>
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      filter
                      onChange={(e) => {
                        field.onChange(e.value);
                      }}
                      showClear
                      options={
                        filteredProducts
                          ? filteredProducts?.data.map((product) => ({
                              name: product.product_name,
                              code: product.id,
                            }))
                          : []
                      }
                      loading={isLoading}
                      optionLabel="name"
                      className={`${classNames({
                        "p-invalid": fieldState.error,
                      })} w-full rounded-xl`}
                      placeholder="Select a Product"
                    />
                    {getFormErrorMessage(field.name, errors)}
                    <Button
                      type="submit"
                      icon={"pi pi-check"}
                      className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
                      loading={filterCustomerLoading}
                    />
                    <Button
                      type="button"
                      icon={"pi pi-times"}
                      className="bg-red-500 border-none text-white-500 rounded-xl"
                      onClick={() => {
                        setData(filteredCustomers?.data);
                        reset();
                      }}
                    />
                  </>
                )}
              />
            </div>
          </form>
        </div>
      </div>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return <div className="py-4 ">{rowData.full_name}</div>;
  };

  const emailBodyTemplate = (rowData) => {
    return <div className="py-4 ">{rowData.email}</div>;
  };

  const phoneBodyTemplate = (rowData) => {
    return <div className="py-4 ">{rowData.phone}</div>;
  };

  const prevTreatmentBodyTemplate = (rowData) => {
    return (
      <div className="py-4 ">
        {rowData?.pre_treatment_operations?.treatment?.title}
      </div>
    );
  };

  const ids = selectedProducts && selectedProducts.map((item) => item.id);

  return (
    <div className={`h-screen py-4 pr-4`}>
      <Toast ref={toast} />
      <SendEmailSms
        visible={visible}
        setVisible={setVisible}
        refetch={refetch}
        toast={toast}
        selectedIds={ids} // Passing ids as a prop
        //onSubmitParent={onSubmitParent} // Pass onSubmitParent function as prop
      />

      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Customer Communication"} />
          <div className="w-full h-full px-6 m-2 rounded-2xl">
            <div className="p-2 rounded-xl filtered-customer-table">
              <DataTable
                value={data}
                selectionMode={"checkbox"}
                loading={isAnyLoading}
                selection={selectedProducts}
                onSelectionChange={(e) => setSelectedProducts(e.value)}
                dataKey="id"
                tableStyle={{ minWidth: "100%" }}
                header={header}
                paginator
                rows={25}
                rowsPerPageOptions={[10, 25, 50]}
                scrollable
                globalFilter={globalFilterValue}
                scrollHeight="50vh"
              >
                <Column
                  selectionMode="multiple"
                  headerStyle={{ width: "3rem" }}
                />
                <Column
                  field="full_name"
                  header="Full Name"
                  body={nameBodyTemplate}
                />
                <Column
                  field="email"
                  header="E-mail"
                  body={emailBodyTemplate}
                />
                <Column field="phone" header="Phone" body={phoneBodyTemplate} />
                <Column
                  field="pre_treatment_operations.treatment.title"
                  header="Previous Treatment"
                  body={prevTreatmentBodyTemplate}
                />
                {/*<Column header="Orders" body={ordersBodyTemplate} /> */}
              </DataTable>
            </div>
            <div className="flex items-center justify-center mt-4 ">
              <Button
                type="submit"
                label="Email/Sms"
                className="rounded-xl bg-[#C4E1D8] text-[#3A5D4C] border-[#3A5D4C]"
                onClick={() => setVisible(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerTableContentCommu;
