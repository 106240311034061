import { useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { useDispatch, useSelector } from "react-redux";

import {
  useGetCommunicationOfCustomersQuery,
  useGetCustomerQuery,
  useGetOldCommunicationOfCustomersQuery,
} from "../../services/customer/customerServices";

import { deleteCustomer } from "../../features/customer/customerActions";

import EditCustomerForm from "./EditCustomerForm";
import AddTaskForm from "./AddTaskForm";
import ViewCommunication from "./ViewCommunication";
import CommunicateCustomer from "./CommunicateCustomer";
import AddOrderForm from "./AddOrderForm";
import CustomerDetails from "./CustomerDetails";

const CustomerDetailsDialog = ({
  detailsVisible,
  setDetailsVisible,
  customerId,
  setCustomerId,
  toast,
  refetch: refetchCustomers,
}) => {
  const [state, setState] = useState("CustomerDetails");
  const [deletedCustomerVisible, setDeletedCustomerVisible] = useState(false);

  const dispatch = useDispatch();

  const { deleteLoading } = useSelector((state) => state.customer);

  const { data, isLoading, error, refetch } = useGetCustomerQuery(customerId, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: communicationsDataOfCustomer,
    isLoading: communicationsOfCustomersLoading,
    error: communicationsOfCustomersError,
  } = useGetCommunicationOfCustomersQuery(customerId, {
    refetchOnMountOrArgChange: true,
  });

  const {
    data: oldCommunicationsDataOfCustomer,
    isLoading: oldCommunicationsOfCustomersLoading,
    error: oldCommunicationsOfCustomersError,
  } = useGetOldCommunicationOfCustomersQuery(customerId, {
    refetchOnMountOrArgChange: true,
  });

  const onDeleteHandler = () => {
    dispatch(deleteCustomer({ id: customerId })).then((res) => {
      if (res.payload.status === 200) {
        refetchCustomers();
        setDeletedCustomerVisible(false);
        setCustomerId(null);
        setDetailsVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setDeletedCustomerVisible(false)}
        className="p-button-text"
        text
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={() => onDeleteHandler()}
        text
        loading={deleteLoading}
      />
    </div>
  );

  const dialogHeader = (
    <div className="flex items-center justify-between w-full my-8">
      <div className="w-1/4 text-lg font-bold">
        <p>{data?.data?.full_name}</p>
      </div>
      <div className="flex flex-wrap items-center justify-end w-3/4">
        <Button
          icon="pi pi-list"
          size="small"
          text
          rounded
          onClick={() => setState("CustomerDetails")}
        />
        <Button
          severity="info"
          size="small"
          text
          rounded
          label="Communications"
          icon="pi pi-comments"
          onClick={() => setState("ViewCommunications")}
        />
        <Button
          severity="info"
          size="small"
          text
          rounded
          label="Communicate"
          icon="pi pi-phone"
          onClick={() => setState("Communicate")}
        />
        <Button
          icon="pi pi-plus"
          severity="info"
          size="small"
          text
          rounded
          label="Add Order"
          onClick={() => setState("AddOrder")}
        />
        <Button
          icon="pi pi-plus"
          severity="info"
          size="small"
          text
          rounded
          label="Add Task"
          onClick={() => setState("AddTask")}
        />
        <Button
          icon="pi pi-pencil"
          size="small"
          text
          rounded
          onClick={() => setState("CustomerEdit")}
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          size="small"
          text
          rounded
          onClick={() => setDeletedCustomerVisible(true)}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      header={"Customer"}
      visible={detailsVisible}
      onHide={() => {
        setCustomerId(null);
        setDetailsVisible(false);
        setState("CustomerDetails");
      }}
      style={{ width: "80vw" }}
    >
      <Dialog
        header="Delete"
        visible={deletedCustomerVisible}
        style={{ width: "50vw" }}
        onHide={() => setDeletedCustomerVisible(false)}
        footer={footerContent}
      >
        <p className="m-0 text-center">
          Are you sure to delete this treatment?
        </p>
      </Dialog>
      {isLoading ? (
        <div className="flex items-center justify-center w-full h-full">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
        </div>
      ) : (
        <>
          {dialogHeader}
          {state === "CustomerDetails" && <CustomerDetails data={data?.data} />}
          {state === "CustomerEdit" && (
            <EditCustomerForm
              data={data}
              isLoading={isLoading}
              error={error}
              refetch={refetch}
              toast={toast}
              setState={setState}
            />
          )}
          {state === "AddOrder" && (
            <AddOrderForm toast={toast} setState={setState} data={data} />
          )}
          {state === "AddTask" && (
            <AddTaskForm
              data={data}
              isLoading={isLoading}
              error={error}
              refetch={refetch}
            />
          )}
          {state === "ViewCommunications" && (
            <ViewCommunication
              communicationsDataOfCustomer={communicationsDataOfCustomer}
              communicationsOfCustomersError={communicationsOfCustomersError}
              communicationsOfCustomersLoading={
                communicationsOfCustomersLoading
              }
              oldCommunicationsDataOfCustomer={oldCommunicationsDataOfCustomer}
              oldCommunicationsOfCustomersError={
                oldCommunicationsOfCustomersError
              }
              oldCommunicationsOfCustomersLoading={
                oldCommunicationsOfCustomersLoading
              }
            />
          )}
          {state === "Communicate" && (
            <CommunicateCustomer refetch={refetch} toast={toast} data={data} />
          )}
        </>
      )}
    </Dialog>
  );
};

export default CustomerDetailsDialog;
