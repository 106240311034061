import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { Dialog } from "primereact/dialog";

import AddDriver from "./AddDriver";
import EditDriver from "./EditDriver";

import { useGetAllTechniciansQuery } from "../../services/technician/technicianServices";
import { deleteTechnician } from "../../features/technician/technicanActions";

const DriverContent = () => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deletedTechnicianId, setDeletedTechnicianId] = useState(null);
  const [editVisible, setEditVisible] = useState(false);
  const [editedTechnicianId, setEditedTechnicianId] = useState(null);
  const {
    data: techniciansData,
    isLoading,
    error,
    refetch,
  } = useGetAllTechniciansQuery("TechniciansDetails", {
    refetchOnMountOrArgChange: true,
  });

  const toast = useRef(null);
  const dispatch = useDispatch();

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;

    setGlobalFilterValue(value);
  };

  const header = () => {
    return (
      <div className="flex items-center justify-between bg-none">
        <div className="flex">
          <span className="mr-2 p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="p-inputtext-sm rounded-xl"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Technician Search"
            />
          </span>
        </div>
        <div>
          <Button
            icon="pi pi-plus"
            label="Add Technician"
            className="bg-[#c4e1d8] text-[#0D6047] border-none rounded-xl"
            size="small"
            onClick={() => setVisible(true)}
          />
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <p>{error?.data?.message}</p>
      </div>
    );
  }

  const firstNameBodyTemplate = (rowData) => {
    return <div className="py-4">{rowData.first_name}</div>;
  };

  const lastNameBodyTemplate = (rowData) => {
    return <div className="py-4">{rowData.last_name}</div>;
  };

  const usernameBodyTemplate = (rowData) => {
    return <div className="py-4">{rowData.username}</div>;
  };

  const emailBodyTemplate = (rowData) => {
    return <div className="py-4">{rowData.email}</div>;
  };

  const phoneBodyTemplate = (rowData) => {
    return <div className="py-4">{rowData.phone}</div>;
  };

  const addressBodyTemplate = (rowData) => {
    return <div className="py-4">{rowData.full_address}</div>;
  };

  const activeBodyTemplate = (rowData) => {
    return (
      <div className="py-4">
        <i
          className={classNames("pi", {
            "true-icon pi-check-circle text-blue-600": rowData.is_active,
            "false-icon pi-times-circle text-red-600": !rowData.is_active,
          })}
        ></i>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    const { id } = rowData;
    return (
      <div className="flex items-center justify-between m-2">
        <Button
          icon="pi pi-pencil"
          rounded
          text
          size="small"
          onClick={() => onEdit(id)}
        />
        <Button
          icon="pi pi-trash"
          rounded
          text
          severity="danger"
          size="small"
          onClick={() => onDelete(id)}
        />
      </div>
    );
  };

  const onDelete = (id) => {
    setDeletedTechnicianId(id);
    setDeleteVisible(true);
  };

  const onEdit = (id) => {
    setEditedTechnicianId(id);
    setEditVisible(true);
  };

  const onDeleteHandler = () => {
    dispatch(deleteTechnician({ id: deletedTechnicianId })).then((res) => {
      if (res.payload.status === 200) {
        refetch();
        setDeleteVisible(false);
        toast.current.show({
          severity: "success",
          summary: "Form Submitted",
          detail: res.payload.data.message,
          life: 3000,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: res.payload,
          life: 3000,
        });
      }
    });
  };

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => setDeleteVisible(false)}
        className="p-button-text"
        text
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        severity="danger"
        onClick={() => onDeleteHandler()}
        text
      />
    </div>
  );

  return (
    <div>
      <Toast ref={toast} />
      <AddDriver
        visible={visible}
        setVisible={setVisible}
        toast={toast}
        refetch={refetch}
      />
      {editedTechnicianId && (
        <EditDriver
          visible={editVisible}
          setVisible={setEditVisible}
          toast={toast}
          refetch={refetch}
          editedTechnicianId={editedTechnicianId}
        />
      )}
      <Dialog
        header="Delete"
        visible={deleteVisible}
        style={{ width: "50vw" }}
        onHide={() => setDeleteVisible(false)}
        footer={footerContent}
      >
        <p className="m-0 text-center">
          Are you sure to remove this technicians?
        </p>
      </Dialog>
      <DataTable
        value={techniciansData.data}
        dataKey="id"
        scrollable
        scrollHeight="68vh"
        header={header}
        paginator
        globalFilter={globalFilterValue}
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
      >
        <Column
          field="first_name"
          header="First Name"
          body={firstNameBodyTemplate}
        />
        <Column
          field="last_name"
          header="Last name"
          body={lastNameBodyTemplate}
        />
        <Column
          field="username"
          header="Username"
          body={usernameBodyTemplate}
        />
        <Column field="email" header="E-mail" body={emailBodyTemplate} />
        <Column field="phone" header="Phone" body={phoneBodyTemplate} />
        <Column
          field="full_address"
          header="Address"
          body={addressBodyTemplate}
        />
        {/* <Column
          field="role"
          header="Role"
          style={{ minWidth: "20rem" }}
          body={roleBodyTemplate}
        /> */}
        <Column field="is_active" header="Active" body={activeBodyTemplate} />
        <Column body={actionBodyTemplate} header="Actions" />
      </DataTable>
    </div>
  );
};

export default DriverContent;
