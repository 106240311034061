import { useParams } from "react-router-dom";

import styles from "./orderPdf.module.css";

import { useGetOrderQuery } from "../../services/order/orderServices";
import Header from "../Header";
import OrderPdfTemplate from "./OrderPdfTemplate";

const OrderPdf = () => {
  const { id } = useParams();
  const { data, isLoading, error } = useGetOrderQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Create Receipt"} />
          <div className={`w-full h-full rounded-2xl m-2 px-2`}>
            {isLoading ? (
              <div className="flex items-center justify-center w-full h-full">
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "2rem" }}
                ></i>
              </div>
            ) : (
              <OrderPdfTemplate data={data?.data} id={id} />
            )}
            {error && (
              <div className="flex items-center justify-center w-full h-full">
                <i
                  className="text-red-500 pi pi-info-circle"
                  style={{ fontSize: "2rem" }}
                ></i>
                <p>{error?.message}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPdf;
