import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import reducer from "./reducer";

import { orderApi } from "../services/order/orderServices";
import { adminApi } from "../services/admin/adminServices";
import { tasksApi } from "../services/tasks/tasksServices";
import { productApi } from "../services/product/productServices";
import { customerApi } from "../services/customer/customerServices";
import { treatmentApi } from "../services/treatment/treatmentServices";
import { operationApi } from "../services/operation/operationServices";
import { orderLineApi } from "../services/orderLine/orderLineServices";
import { techniciansApi } from "../services/technician/technicianServices";
import { treeConditionsApi } from "../services/TreeConditions/treeConditionsServices";
import { operationForTechnicianApi } from "../services/operation/operationForTechnician";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "orders",
    "delivery",
    "tasks",
    "products",
    "customerApi",
    "orderApi",
    "adminApi",
    "tasksApi",
    "treeConditionsApi",
    "operationForTechnicianApi",
    "techniciansApi",
    "orderLineApi",
    "treatmentApi",
    "operationApi",
    "productApi",
  ],
};

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      orderApi.middleware,
      adminApi.middleware,
      tasksApi.middleware,
      productApi.middleware,
      customerApi.middleware,
      treatmentApi.middleware,
      operationApi.middleware,
      orderLineApi.middleware,
      techniciansApi.middleware,
      treeConditionsApi.middleware,
      operationForTechnicianApi.middleware,
    ]),
});

export const persistor = persistStore(store);
