import Header from "../Header";
import TasksTable from "./TasksTable";
import TasksTableAdmin from "./TasksTableAdmin";
import styles from "./Tasks.module.css";
import { TabPanel, TabView } from "primereact/tabview";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

const Tasks = () => {
  const { token } = useSelector((state) => state.auth);
  const decodeToken = jwtDecode(token);
  return (
    <div className={`${styles.fontFamily} h-screen py-4 pr-4`}>
      <div className="p-4 rounded-3xl h-full bg-[#ffeded]">
        <div className="flex flex-col justify-between h-full">
          <Header title={"Tasks"} />
          <div className={`w-full h-full rounded-2xl m-2 px-2 overflow-auto`}>
            {decodeToken?.role === "superadmin" ||
            decodeToken?.role === "admin" ? (
              <TabView>
                <TabPanel header="to-do">
                  <TasksTableAdmin status={"to-do"} />
                </TabPanel>
                <TabPanel header="in-progress">
                  <TasksTableAdmin status={"in-progress"} />
                </TabPanel>
                <TabPanel header="done">
                  <TasksTableAdmin status={"done"} />
                </TabPanel>
              </TabView>
            ) : (
              <TabView>
                <TabPanel header="Created By">
                  <TabView>
                    <TabPanel header="To-Do">
                      <TasksTable
                        adminId={decodeToken.id}
                        status={"to-do"}
                        typeTasks={"created-tasks"}
                      />
                    </TabPanel>
                    <TabPanel header="In Progress">
                      <TasksTable
                        adminId={decodeToken.id}
                        status={"in-progress"}
                        typeTasks={"created-tasks"}
                      />
                    </TabPanel>
                    <TabPanel header="Done">
                      <TasksTable
                        adminId={decodeToken.id}
                        status={"done"}
                        typeTasks={"created-tasks"}
                      />
                    </TabPanel>
                  </TabView>
                </TabPanel>
                <TabPanel header="Assigned to">
                  <TabView>
                    <TabPanel header="To-Do">
                      <TasksTable
                        adminId={decodeToken.id}
                        status={"to-do"}
                        typeTasks={"assigned-tasks"}
                      />
                    </TabPanel>
                    <TabPanel header="In Progress">
                      <TasksTable
                        adminId={decodeToken.id}
                        status={"in-progress"}
                        typeTasks={"assigned-tasks"}
                      />
                    </TabPanel>
                    <TabPanel header="Done">
                      <TasksTable
                        adminId={decodeToken.id}
                        status={"done"}
                        typeTasks={"assigned-tasks"}
                      />
                    </TabPanel>
                  </TabView>
                </TabPanel>
              </TabView>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tasks;
